import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { API_DESIGNS } from "../context/ApiEndPoints";
import useFetchList from "../hooks/useFetchList";
import DesignComments from "../pages/Designs/DesignComments";
import { dateStamp } from "../scripts/Scripts";
import PopupDialog from "./PopupDialog";
import ButtonControl from "./controls/ButtonControl";

function DesignCard(props) {
  const [selectedRecord, setSelectedRecord] = useState();
  const [popupDialog, setPopupDialog] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const { records, setRecords, isLoading, fetchError } = useFetchList(
    `${API_DESIGNS}/Job/${props.quoteId}`
  );

  useEffect(() => {
    setSelectedRecord(records && { ...records[0] });

    return () => {};
  }, [records]);

  const handleSelect = (designId) => {
    const index = records.findIndex((e) => e.designId === designId);
    setSelectedRecord({ ...records[index] });
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 3 }} />
        ))}
      <Box>
        <Grid container spacing={1}>
          {selectedRecord && Object.keys(selectedRecord).length > 0 ? (
            <>
              <Grid item>
                <Grid container spacing={1}>
                  {records.map((it, i) => (
                    <Grid key={i} item xs={4} md={12}>
                      <Card
                        variant="elevation"
                        sx={{
                          height: { xs: "100%", md: "auto" },
                        }}
                      >
                        <CardHeader
                          action={
                            <Box sx={{ textAlign: "right" }}>
                              {it.isApproved && (
                                <>
                                  <Chip
                                    label="Approved"
                                    color="success"
                                    icon={<TaskAltIcon />}
                                    sx={{ mb: 0.5, borderRadius: 1 }}
                                  />
                                  <br />
                                </>
                              )}
                              <ButtonControl
                                text={it.mime}
                                variant="text"
                                size="small"
                                startIcon={<DownloadRoundedIcon />}
                                sx={{ textTransform: "lowercase" }}
                              />
                            </Box>
                          }
                          title={`DESIGN ${it.designId}`}
                          titleTypographyProps={{
                            fontSize: "large",
                          }}
                          subheader={dateStamp(it.createdDate)}
                          subheaderTypographyProps={{
                            fontSize: "small",
                          }}
                        />
                        <Divider />
                        {it.mime === "application/pdf" ? (
                          <>
                            <CardContent align="center" sx={{ p: 0 }}>
                              <embed
                                src={it.fileName}
                                type="application/pdf"
                                width="100%"
                              />
                            </CardContent>
                            <CardActionArea
                              onClick={() => handleSelect(it.designId)}
                            >
                              <Typography
                                variant="subtitle2"
                                align="center"
                                p={2}
                              >
                                CLICK TO VIEW
                              </Typography>
                            </CardActionArea>
                          </>
                        ) : (
                          <CardMedia
                            component="img"
                            height="100%"
                            image={it.fileName}
                            alt={it.fileName}
                            loading="lazy"
                          />
                        )}
                        <CardContent>
                          <DesignComments
                            records={records}
                            setRecords={setRecords}
                            selectedRecord={it}
                            submit={false}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Typography p={3}>No Design Yet!</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <PopupDialog
        popupDialog={popupDialog}
        setPopupDialog={setPopupDialog}
        maxWidth={false}
      >
        <img src={popupContent} alt={popupContent} />
      </PopupDialog>
    </>
  );
}

export default memo(DesignCard);
