import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Container, Drawer, Paper, styled } from "@mui/material";
import React, { memo } from "react";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function ContentDrawer({ contentDrawer, setContentDrawer, children }) {
  return (
    <Drawer
      anchor="right"
      open={contentDrawer}
      onClose={() => setContentDrawer(!contentDrawer)}
      PaperProps={{ sx: { bgcolor: "background.default" } }}
    >
      <DrawerHeader />
      <Paper
        component={Button}
        startIcon={<CancelIcon />}
        onClick={() => setContentDrawer(!contentDrawer)}
        sx={{ mx: 0.5, bgcolor: "divider", justifyContent: "flex-start" }}
      >
        Close
      </Paper>
      <Container
        sx={{
          p: 1,
          width: { sm: "100vw", md: "50vw" },
          height: "inherit",
          overflow: "auto",
        }}
        disableGutters
      >
        {children}
      </Container>
    </Drawer>
  );
}

export default memo(ContentDrawer);
