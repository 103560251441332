import AddIcon from "@mui/icons-material/AddCard";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
  Zoom,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FilesUploader from "../../components/FilesUploader";
import ButtonControl from "../../components/controls/ButtonControl";
import CheckboxControl from "../../components/controls/CheckboxControl";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import { API_SHOPS_SELECT, API_USERS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useFetchList from "../../hooks/useFetchList";
import useForm from "../../hooks/useForm";

function QuoteForm({
  records,
  handleEdit,
  handleFiles,
  handleItemDelete,
  setIsToggleEdit,
  setConfirmDialog,
  isSaving,
}) {
  const { user } = useAppContext();
  const [shopUsers, setShopUsers] = useState();
  const validate = (input = values) => {
    let temp = { ...errors };
    if ("title" in input)
      temp.title = input.title.length < 29 ? "" : "Tile is too long.";
    if ("userId" in input)
      temp.userId = input.userId ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors } = useForm(
    records,
    true,
    validate
  );

  const {
    records: usersSelect,
    isLoading: userIsLoading,
    fetchError: userFetchError,
  } = useFetchList(API_USERS);

  const {
    records: shopsSelect,
    isLoading: shopsLoading,
    fetchError: shopsError,
  } = useFetchList(API_SHOPS_SELECT);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) handleEdit(values);
  };

  const handleFilesSubmit = (e) => {
    e.preventDefault();
    handleFiles(values);
  };

  const handlePriceChange = (e, index) => {
    const { name, value } = e.target;
    values.quoteItems[index][name] = value;
    setValues({ ...values });
  };

  useEffect(() => {
    usersSelect &&
      setShopUsers(usersSelect.filter((el) => el.shopId === values.shopId));

    // eslint-disable-next-line
  }, [usersSelect, values.shopId]);

  return (
    records && (
      <Zoom in={true} style={{ transitionDelay: "100ms" }}>
        <Paper>
          <List>
            <ListItem
              secondaryAction={
                <>
                  <Tooltip title="Add Service" placement="bottom" arrow>
                    <IconButton
                      color="primary"
                      aria-label="add"
                      component={Link}
                      to={`/edit-${records.industry}/${records.quoteId}`}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Close" placement="bottom" arrow>
                    <IconButton
                      color="primary"
                      aria-label="close"
                      onClick={() => setIsToggleEdit(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Edit" secondary="Quote" />
            </ListItem>
          </List>
          <Divider />
          <Paper elevation={0} sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <InputControl
                label="Title"
                name="title"
                value={values.title}
                onChange={handleInputChange}
                error={errors.title}
              />
              {!values.jobCreatedDate && (
                <>
                  {records.quoteItems &&
                    records.quoteItems.map(
                      (item, i) =>
                        item.isGroup && (
                          <Stack key={i} direction="row" alignItems="center">
                            <InputControl
                              label="Advice Price"
                              name="advicePrice"
                              type="number"
                              min="0"
                              value={values.quoteItems[i].advicePrice}
                              onChange={(e) => handlePriceChange(e, i)}
                              helperText={item.serviceName}
                              required
                              InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {records.currency}
                                  </InputAdornment>
                                ),
                              }}
                              disabled={
                                user?.roles.includes("Admin") ? false : true
                              }
                            />
                            <InputControl
                              label="Price"
                              name="price"
                              type="number"
                              value={values.quoteItems[i].price}
                              onChange={(e) => handlePriceChange(e, i)}
                              helperText={item.serviceName}
                              required
                              InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {records.currency}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Tooltip title="Delete" placement="top" arrow>
                              <span>
                                <IconButton
                                  color="error"
                                  aria-label="delete"
                                  disabled={isSaving}
                                  sx={{ m: 0.5 }}
                                  onClick={() =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure to delete this record?",
                                      subTitle: "You can't undo this operation",
                                      onConfirm: () => {
                                        handleItemDelete(item);
                                      },
                                    })
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Stack>
                        )
                    )}
                  <CheckboxControl
                    label="Show Total Price"
                    name="isTotal"
                    value={values.isTotal}
                    onChange={handleInputChange}
                  />
                  <InputControl
                    label="Notes"
                    name="notes"
                    value={values.notes ? values.notes : ""}
                    onChange={handleInputChange}
                    error={errors.notes}
                    multiline
                    rows={3}
                  />
                  {shopsError ||
                    (shopsLoading && (
                      <CircularProgress size={20} color="inherit" />
                    ))}
                  {shopsSelect && user?.email === "suzie@2wrap.com" && (
                    <SelectControl
                      label="Shop"
                      name="shopId"
                      value={values.shopId ? values.shopId : 0}
                      onChange={handleInputChange}
                      options={shopsSelect}
                      error={errors.shopId}
                      textIndex={1}
                    />
                  )}
                  {userFetchError ||
                    (userIsLoading && (
                      <CircularProgress size={20} color="inherit" />
                    ))}
                  {shopUsers &&
                    ["Admin", "Shop"].some((val) =>
                      user?.roles.includes(val)
                    ) && (
                      <SelectControl
                        label="User"
                        name="userId"
                        value={values.userId}
                        onChange={handleInputChange}
                        options={shopUsers}
                        error={errors.userId}
                      />
                    )}
                </>
              )}
              <ButtonControl type="submit" text="Save" isSaving={isSaving} />
            </form>
          </Paper>
          <Divider />
          <Paper elevation={0} sx={{ p: 3 }}>
            <form onSubmit={handleFilesSubmit}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Upload Document(s)</InputLabel>
                <FilesUploader
                  inputName="quoteFiles"
                  accept="image/*,application/pdf,application/postscript"
                  values={values}
                  setValues={setValues}
                />
              </FormControl>
              <ButtonControl type="submit" text="Save" isSaving={isSaving} />
            </form>
          </Paper>
        </Paper>
      </Zoom>
    )
  );
}

export default memo(QuoteForm);
