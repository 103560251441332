import React, { createContext, useState } from "react";

export const ComponentContext = createContext({});

export function ComponentProvider({ children }) {
  const [confirmDialog, setConfirmDialog] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    severity: "",
  });
  const [scrhObjs, setScrhObjs] = useState([]);

  return (
    <ComponentContext.Provider
      value={{
        notify,
        setNotify,
        confirmDialog,
        setConfirmDialog,
        scrhObjs,
        setScrhObjs,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
}
