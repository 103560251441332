import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { scheduleStamp } from "../scripts/Scripts";

export default function useScheduler(
  records,
  startDate,
  days,
  interval,
  tformat,
  openInDrawer,
  addOrEdit,
  handleDelete,
  setConfirmDialog,
  setNotify,
  quoteId
) {
  // these are labels for the days of the week
  const dayStr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const fDayStr = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const fMonthStr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // these are the days of the week for each month, in order
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  const TblDate = () => {
    startDate.setHours(0, 0, 0, 0);
    const year = startDate.getFullYear();
    const month = startDate.getMonth();
    let d_title;
    if (days === 30) {
      d_title = fMonthStr[month] + " " + year;
    } else if (days === 7) {
      const sDate = new Date(startDate);
      sDate.setDate(sDate.getDate() - sDate.getDay());
      const endDate = new Date(sDate);
      endDate.setDate(endDate.getDate() + 6);
      d_title =
        sDate.getDate() +
        " " +
        fMonthStr[sDate.getMonth()] +
        " " +
        sDate.getFullYear() +
        " - " +
        endDate.getDate() +
        " " +
        fMonthStr[endDate.getMonth()] +
        " " +
        sDate.getFullYear();
    } else {
      d_title =
        fDayStr[startDate.getDay()] +
        ", " +
        startDate.getDate() +
        " " +
        fMonthStr[startDate.getMonth()] +
        " " +
        year;
    }
    return d_title;
  };

  const TblContainer = (props) => (
    <Table stickyHeader sx={{ width: "100%", tableLayout: "fixed" }}>
      {props.children}
    </Table>
  );

  const TblBody = () => {
    startDate.setHours(0, 0, 0, 0);
    const n = new Date();
    n.setHours(0, 0, 0, 0);
    let start_str = 0;
    const year = startDate.getFullYear();
    const month = startDate.getMonth();

    if (days === 30) {
      // get first day of month
      const firstDay = new Date(year, month, 1);
      firstDay.setHours(0, 0, 0, 0);
      const startingDay = firstDay.getDay();
      // find number of days in month
      let monthLength = daysInMonth[month];

      // compensate for leap year
      if (month === 1) {
        // February only!
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
          monthLength = 29;
        }
      }

      // do the header
      const thead = [];
      for (let a = 0; a <= 6; a++) {
        thead[a] = dayStr[a];
      }

      // fill in the days
      let day = 1;
      // this loop is for is weeks (rows)
      const trows = [];
      for (let i = 0; i < 9; i++) {
        // this loop is for weekdays (cells)
        let tcells = [];
        for (let j = 0; j <= 6; j++) {
          tcells[j] = { id: j };
          if (day <= monthLength && (i > 0 || j >= startingDay)) {
            tcells[j] = {
              ...tcells[j],
              xid: ds(firstDay),
              date: day,
              today:
                n.toDateString() === firstDay.toDateString() ? true : false,
            };
            firstDay.setDate(firstDay.getDate() + 1);
            day++;
          }
        }
        trows[i] = tcells;
        // stop making rows if we've run out of days
        if (day > monthLength) break;
      }

      return (
        <>
          <TableHead>
            <TableRow>
              {thead.map((day, i) => (
                <TableCell
                  key={i}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                  }}
                >
                  {day}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderCollapse: "collapse" }}>
            {trows.map((tr, i) => (
              <TableRow key={i}>
                {tr.map((td, i) => (
                  <TableCell
                    key={i}
                    data-xid={td.xid}
                    sx={{
                      p: 0,
                      borderRight: 1,
                      borderRightColor: "divider",
                    }}
                  >
                    {td.xid && (
                      <Box
                        className="gs-td-data"
                        sx={{
                          minHeight: 79,
                          display: "flex",
                          color: td.today ? "error.main" : "inherit",
                          border: td.today && 1,
                          borderColor: td.today && "error.main",
                        }}
                      >
                        <Typography
                          className="gs-date-num"
                          sx={{ px: 1.5, py: 1 }}
                        >
                          {td.date}
                        </Typography>
                        {records
                          .filter((e) => getTime(e.startTime) === td.xid)
                          .map((data, d) => (
                            <Card
                              key={d}
                              id={data.scheduleId}
                              className="gs-data"
                              data-hid={
                                getTime(data.endTime) - getTime(data.startTime)
                              }
                              draggable
                              sx={{ p: 0, position: "relative" }}
                            >
                              <CardHeader
                                sx={{ p: 1, backgroundColor: "divider" }}
                                action={
                                  <>
                                    <IconButton
                                      color="primary"
                                      size="small"
                                      onClick={() => openInDrawer("edit", data)}
                                      aria-label="edit"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        setConfirmDialog({
                                          isOpen: true,
                                          title:
                                            "Are you sure to delete this record?",
                                          subTitle:
                                            "You can't undo this operation",
                                          onConfirm: () => {
                                            handleDelete(data.scheduleId);
                                          },
                                        })
                                      }
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </>
                                }
                                subheader={data.scheduleId}
                              />
                              <CardContent sx={{ p: 1 }}>
                                <Typography
                                  className="time"
                                  variant="body2"
                                  color="text.secondary"
                                  noWrap
                                >
                                  {`${ts(data.startTime, tformat)} - ${ts(
                                    data.endTime,
                                    tformat
                                  )}`}
                                </Typography>
                              </CardContent>
                              <span></span>
                            </Card>
                          ))}
                      </Box>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </>
      );
    } else {
      const tr = [];
      const tcells = [];
      const sDate = new Date(startDate);

      // set date title
      if (days === 7) {
        sDate.setDate(sDate.getDate() - sDate.getDay());
        const endDate = new Date(sDate);
        endDate.setDate(endDate.getDate() + 6);
      }

      // table header
      const thead = [];
      for (let i = 0; i < days; i++) {
        const currentDate = new Date(sDate);
        currentDate.setDate(currentDate.getDate() + i);

        thead[i] = {
          date:
            dayStr[currentDate.getDay()] +
            ", " +
            currentDate.getDate() +
            " " +
            fMonthStr[currentDate.getMonth()],
          today: n.getTime() === currentDate.getTime() ? true : false,
        };
        // table body cells
        let now_str = start_str;
        if (tr[now_str] === undefined || tr[now_str] == null) {
          // actvie hours
          const timeStamp = ts(currentDate);
          // table row creation
          tcells[now_str] = [];
          tr[now_str] = {
            id: now_str,
            tt: timeStamp,
            text: ts(currentDate, tformat),
          };
        }
        tcells[now_str][i] = {
          xid: ds(currentDate),
          height: "70vh",
        };
        tr[now_str] = { ...tr[now_str], cell: tcells[now_str] };
        currentDate.setTime(currentDate.getTime() + interval * 60000);
      }

      return (
        <>
          <TableHead>
            <TableRow>
              <>
                {thead.map((day, i) => (
                  <TableCell
                    key={i}
                    sx={{
                      width: 1 / 7,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                      color: day.today ? "error.main" : "inherit",
                    }}
                  >
                    {day.date}
                  </TableCell>
                ))}
              </>
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderCollapse: "collapse" }}>
            {tr.map((row, i) => (
              <TableRow key={i}>
                {row.cell.map((cell, c) => (
                  <TableCell
                    key={c}
                    data-xid={cell.xid}
                    sx={{
                      p: 0,
                      borderRight: 1,
                      borderRightColor: "divider",
                    }}
                  >
                    <Box className="gs-td-data" sx={{ height: cell.height }}>
                      {records
                        .filter((e) => ds(e.startTime) === cell.xid)
                        .map((data, d) => (
                          <Card
                            key={d}
                            id={data.scheduleId}
                            elevation={2}
                            className="gs-data"
                            draggable
                            sx={{
                              mb: 0.3,
                              width: `calc(99.5% * ${
                                getDays(data.endTime, data.startTime) + 1
                              })`,
                              color:
                                data.quoteId === parseInt(quoteId)
                                  ? "primary.contrastText"
                                  : "inherit",
                              bgcolor:
                                data.quoteId === parseInt(quoteId)
                                  ? "primary.light"
                                  : "default",
                              position: "relative",
                              "&:hover": { zIndex: 1 },
                            }}
                          >
                            <CardHeader
                              sx={{
                                p: 1.5,
                                bgcolor: data.isCompleted
                                  ? "success.main"
                                  : "divider",
                              }}
                              subheader={`ID: ${data.quoteId} - ${data.quoteTitle}`}
                              subheaderTypographyProps={{
                                color: "inherit",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            />
                            <CardContent
                              sx={{
                                p: 1.5,
                                height: 99,
                              }}
                            >
                              {new Date(data.startTime).toDateString() ===
                              new Date(data.endTime).toDateString() ? (
                                <Typography
                                  className="time"
                                  variant="subtitle1"
                                  noWrap
                                >
                                  {`${ts(data.startTime, tformat)} - ${ts(
                                    data.endTime,
                                    tformat
                                  )}`}
                                </Typography>
                              ) : (
                                <Typography
                                  className="time"
                                  variant="subtitle2"
                                  noWrap
                                >
                                  {`${ts(
                                    data.startTime,
                                    tformat
                                  )} -|- ${scheduleStamp(data.endTime)}`}
                                </Typography>
                              )}
                              <Typography
                                sx={{ textTransform: "capitalize" }}
                                noWrap
                              >
                                {data.quoteItems.join(", ")}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ lineClamp: 2, overflow: "hidden" }}
                                gutterBottom
                              >
                                {data.remarks}
                              </Typography>
                            </CardContent>
                            <Divider variant="middle" sx={{ mx: 1 }} />
                            <CardActions sx={{ py: 0.3 }}>
                              <Tooltip title="Edit" placement="top" arrow>
                                <IconButton
                                  color="inherit"
                                  onClick={() => openInDrawer("edit", data)}
                                  aria-label="edit"
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Go To Quote"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  color="inherit"
                                  component={Link}
                                  to={`/job-details/${data.quoteId}`}
                                >
                                  <RequestQuoteIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton
                                  color="inherit"
                                  onClick={() =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure to delete this record?",
                                      subTitle: "You can't undo this operation",
                                      onConfirm: () => {
                                        handleDelete(data.scheduleId);
                                      },
                                    })
                                  }
                                  aria-label="delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              {data.isCompleted && (
                                <Tooltip
                                  title="Completed"
                                  placement="top"
                                  arrow
                                >
                                  <TaskAltIcon color="success" />
                                </Tooltip>
                              )}
                            </CardActions>
                          </Card>
                        ))}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </>
      );
    }
  };

  const EventListeners = () => {
    const cols = document.querySelectorAll(".gs-td-data");
    [].forEach.call(cols, function (col) {
      col.addEventListener("dragstart", handleDragStart, false);
      col.addEventListener("dragenter", handleDragEnter, false);
      col.addEventListener("dragover", handleDragOver, false);
      col.addEventListener("dragleave", handleDragLeave, false);
      col.addEventListener("drop", handleDrop, false);
      col.addEventListener("dragend", handleDragEnd, false);
    });
  };

  //--- functions drag/drop
  function handleDragStart(e) {
    this.style.opacity = "0.5";
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.id);
    return false;
  }

  function handleDragOver(e) {
    if (e.preventDefault) {
      e.preventDefault(); // Necessary. Allows us to drop.
    }
    e.dataTransfer.dropEffect = "move"; // See the section on the DataTransfer object.
    return false;
  }

  function handleDragEnter(e) {
    // this / e.target is the current hover target.
    this.classList.add("over");
  }

  function handleDragLeave(e) {
    this.classList.remove("over"); // this / e.target is previous target element.
  }

  function handleDrop(e) {
    e.preventDefault();
    var data = e.dataTransfer.getData("text/html");
    if (e.target.classList.contains("gs-td-data", "over") && data.length > 0) {
      const tStamp = e.target.parentNode.dataset.xid;
      const nowDT = ds(new Date());
      if (nowDT > tStamp) {
        setNotify({
          isOpen: true,
          severity: "error",
          message: "You are trying to change history :)",
        });
      } else {
        e.target.appendChild(document.getElementById(data));
        dataUpdate(document.getElementById(data), tStamp);
      }
    }
    return false;
  }

  function handleDragEnd(e) {
    var cols = document.querySelectorAll(".gs-td-data");
    [].forEach.call(cols, function (col) {
      col.classList.remove("over");
      col.style.opacity = "1";
    });
  }

  function dataUpdate(data, tStamp) {
    if (tStamp) {
      const record = records.find((el) => el.scheduleId === parseInt(data.id));
      const exDate = new Date(record.startTime);
      const nwDate = new Date(tStamp);
      const diffDays = getDays(exDate, nwDate);
      const endDate = new Date(record.endTime);

      record.endTime = endDate.setDate(
        endDate.getDate() + (exDate < nwDate ? diffDays : -diffDays)
      );
      record.startTime = exDate.setDate(
        exDate.getDate() + (exDate < nwDate ? diffDays : -diffDays)
      );

      addOrEdit(record);
    }
  }

  //format timestamp (hours:minutes AM/PM)
  const ts = (date, hours = 0) => {
    const dt = new Date(date);
    let h = dt.getHours();
    let m = dt.getMinutes();
    if (hours === 24) {
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      return h + ":" + m;
    } else if (hours === 12) {
      let a = "AM";
      if (h >= 12) {
        h = h - 12;
        a = "PM";
      }
      if (h === 0) {
        h = 12;
      }
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      return h + ":" + m + " " + a;
    } else {
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      return h + "" + m;
    }
  };

  //format datestamp (Y-m-d)
  const ds = (date) => {
    const dt = new Date(date);
    const y = dt.getFullYear();
    let m = dt.getMonth() + 1;
    let d = dt.getDate();
    if (m < 10) m = "0" + m;

    if (d < 10) d = "0" + d;

    return y + "-" + m + "-" + d;
  };

  const getTime = (dateTime) => {
    return Math.floor(new Date(dateTime).getTime() / 1000);
  };

  const getDays = (endTime, startTime) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const sDate = new Date(startTime);
    const eDate = new Date(endTime);
    const dayNum = sDate.getDay();
    let totalDays = Math.round(Math.abs((eDate - sDate) / oneDay));
    if (totalDays + dayNum > 6) totalDays = 6 - dayNum;
    return totalDays;
  };

  return {
    TblDate,
    TblContainer,
    TblBody,
    EventListeners,
  };
}
