import CheckIcon from "@mui/icons-material/Check";
import MicIcon from "@mui/icons-material/Mic";
import TextsmsIcon from "@mui/icons-material/Textsms";
import ThreePIcon from "@mui/icons-material/ThreeP";
import {
  CircularProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_MSGS } from "../context/ApiEndPoints";
import useAppContext from "../hooks/useAppContext";
import useComponentContext from "../hooks/useComponentContext";
import ApiFetch from "./ApiFetch";

function MsgsHub() {
  const { user, msgAlerts, setMsgAlerts, msgAlertCount, setMsgAlertCount } =
    useAppContext();
  const { setNotify } = useComponentContext();
  const [isSaving, setIsSaving] = useState(false);
  let navigate = useNavigate();

  const handleDismiss = async (id, dismiss, url) => {
    if (!dismiss) {
      setIsSaving(true);

      // PUT request using fetch
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
      };
      const results = await ApiFetch(
        `${API_MSGS}/Dismiss/${id}`,
        requestOptions,
        setNotify
      );

      if (results) {
        const index = msgAlerts.findIndex((e) => e.msgId === id);
        msgAlerts[index] = results;
        setMsgAlerts(msgAlerts);
        setMsgAlertCount(msgAlertCount - 1);
      }
      setIsSaving(false);
    }
    if (!isSaving) navigate(url);
  };

  return (
    <Paper elevation={0} square>
      <List
        disablePadding
        sx={{ maxHeight: "40vh", overflowY: "auto" }}
        subheader={
          msgAlerts?.length === 0 && <ListSubheader>No records.</ListSubheader>
        }
      >
        {!msgAlerts ? (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ) : (
          msgAlerts.map((it, i) => (
            <ListItemButton
              key={i}
              sx={{ borderBottom: 1, borderColor: "divider" }}
              onClick={() =>
                handleDismiss(
                  it.msgId,
                  it.dismiss,
                  `/msgs/${
                    it.customerId === "00000000-0000-0000-0000-000000000000"
                      ? it.customerNumber
                      : it.customerId
                  }`
                )
              }
              disabled={isSaving}
            >
              <ListItemIcon>
                {it.customerId === "00000000-0000-0000-0000-000000000000" ? (
                  <TextsmsIcon color={it.dismiss ? "default" : "error"} />
                ) : (
                  <ThreePIcon color={it.dismiss ? "default" : "error"} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    display="inline-block"
                    color={it.dismiss ? "inherit" : "error"}
                    sx={{ pr: 3, cursor: "pointer", whiteSpace: "pre-line" }}
                  >
                    {it.msgBody.includes("/Recordings/") ? (
                      <>
                        Voice message{" "}
                        <MicIcon
                          fontSize="inherit"
                          sx={{ verticalAlign: "text-top" }}
                        />
                      </>
                    ) : it.msgBody.length > 35 ? (
                      it.msgBody.substring(0, 35) + "..."
                    ) : (
                      it.msgBody
                    )}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2">
                    {it.customerNumber} - - {dayjs(it.createdDate).format("LT")}
                    {it.dismiss && (
                      <CheckIcon
                        fontSize="small"
                        sx={{ ml: 2, verticalAlign: "text-bottom" }}
                      />
                    )}
                  </Typography>
                }
              />
            </ListItemButton>
          ))
        )}
      </List>
    </Paper>
  );
}

export default memo(MsgsHub);
