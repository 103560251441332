import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAppContext from "./useAppContext";

export default function useFetchList(url) {
  const { user } = useAppContext();

  const [records, setRecords] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const abortCtrl = new AbortController();

    // GET request using fetch with error handling
    const fetchRequest = async () => {
      try {
        const requestOptions = {
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": "application/json",
          },
        };
        const response = await fetch(url, requestOptions, {
          signal: abortCtrl.signal,
        });
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        if (Array.isArray(json)) {
          json.forEach((x) => {
            if (x.createdDate) {
              x.createdDate = new Date(x.createdDate).toLocaleString("lookup", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              });
            }

            if (x.modifiedDate) {
              x.modifiedDate = new Date(x.modifiedDate).toLocaleString(
                "lookup",
                {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              );
            }
          });
        } else {
          if (json?.createdDate)
            json.createdDate = new Date(json.createdDate).toLocaleString(
              "lookup",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }
            );

          if (json?.modifiedDate)
            json.modifiedDate = new Date(json.modifiedDate).toLocaleString(
              "lookup",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }
            );
        }

        setRecords(json);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsLoading(false);
      }
    };
    (async () => await fetchRequest())();

    return () => abortCtrl.abort();
  }, [url, user.token]);

  return { records, setRecords, isLoading, fetchError };
}
