import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FaceIcon from "@mui/icons-material/Face";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import GroupIcon from "@mui/icons-material/Group";
import HandymanIcon from "@mui/icons-material/Handyman";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaletteIcon from "@mui/icons-material/Palette";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  Avatar,
  Badge,
  Chip,
  Icon,
  ListItemButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import useAppContext from "../hooks/useAppContext";
import { stringAvatar } from "../scripts/Scripts";
import AlertsHub from "./AlertsHub";
import MsgsHub from "./MsgsHub";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Appbar({ connection, children }) {
  const { user, setUser, hubCon, setHubCon, alertCount, msgAlertCount } =
    useAppContext();

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("Dashboard");
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorElAlerts, setAnchorElAlerts] = useState(null);

  const handleCloseAlertsMenu = () => {
    setAnchorElAlerts(null);
  };

  const handleOpenAlertsMenu = (event) => {
    setAnchorElAlerts(event.currentTarget);
  };

  const [anchorElMsgs, setAnchorElMsgs] = useState(null);

  const handleCloseMsgsMenu = () => {
    setAnchorElMsgs(null);
  };

  const handleOpenMsgsMenu = (event) => {
    setAnchorElMsgs(event.currentTarget);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setPageTitle(event.currentTarget.dataset.title);
  };

  const handlelogout = () => {
    localStorage.removeItem("X-Access-Token");
    setUser();
    try {
      (async () => await connection?.stop())();
      setHubCon();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 4, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Icon
            className="wi-logo-2wrap"
            sx={{
              height: 30,
              lineHeight: 0.25,
              fontSize: 128,
              ...(open && { display: "none" }),
            }}
          />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            color="gray"
            sx={{ mx: 2, ...(open && { display: "none" }) }}
          />
          <Typography
            noWrap
            color="inherit"
            sx={{ flexGrow: 1, display: { xs: "none", md: "inherit" } }}
          >
            {pageTitle}
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            {user?.email === "suzie@2wrap.com" && (
              <>
                <Tooltip title="Messages">
                  <IconButton
                    color={msgAlertCount ? "error" : "inherit"}
                    onClick={handleOpenMsgsMenu}
                  >
                    <Badge badgeContent={msgAlertCount} color="error">
                      <MessageIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Menu
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      p: 0,
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  id="msgs-appbar"
                  anchorEl={anchorElMsgs}
                  keepMounted
                  open={Boolean(anchorElMsgs)}
                  onClose={handleCloseMsgsMenu}
                >
                  <MenuItem sx={{ p: 0 }}>
                    <MsgsHub />
                  </MenuItem>
                </Menu>
              </>
            )}
            <Tooltip title="Notifications">
              <IconButton
                color={alertCount ? "error" : "inherit"}
                onClick={handleOpenAlertsMenu}
              >
                <Badge badgeContent={alertCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              PaperProps={{
                elevation: 0,
                sx: {
                  p: 0,
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              id="alerts-appbar"
              anchorEl={anchorElAlerts}
              keepMounted
              open={Boolean(anchorElAlerts)}
              onClose={handleCloseAlertsMenu}
            >
              <MenuItem sx={{ p: 0 }}>
                <AlertsHub header={false} />
              </MenuItem>
            </Menu>
            <Tooltip title="Account">
              <IconButton onClick={handleOpenUserMenu} sx={{ ml: 1, p: 0 }}>
                <Badge
                  badgeContent={hubCon ? "" : "!"}
                  color={hubCon ? "success" : "error"}
                  overlap="circular"
                >
                  {user.imageName ? (
                    <Avatar src={user.imageName} alt={user.firstName} />
                  ) : (
                    <Avatar {...stringAvatar(user.firstName)} />
                  )}
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              keepMounted
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="role" disabled>
                <Chip
                  size="large"
                  label={`${user?.email} - ${user?.roles}`}
                  color="primary"
                  sx={{ borderRadius: 1 }}
                />
              </MenuItem>
              <MenuItem key="profile" component={Link} to="/profile">
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </MenuItem>
              <MenuItem key="account" component={Link} to="/account" divider>
                <ListItemIcon>
                  <FingerprintIcon />
                </ListItemIcon>
                <ListItemText primary="Account" />
              </MenuItem>
              <MenuItem key="logout" onClick={handlelogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Icon
            className="wi-logo-2wrap"
            sx={{ mr: 1, height: 30, lineHeight: 0.25, fontSize: 128 }}
          />
          <IconButton
            onClick={handleDrawerClose}
            color="inherit"
            title="drawer-close-toggle"
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List disablePadding>
          <ListItem key="dashboard" disablePadding>
            <ListItemButton
              component={Link}
              to="/dashboard"
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
              data-title="Dashboard"
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="jobs" disablePadding>
            <ListItemButton
              component={Link}
              to="/jobs"
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
              data-title="Jobs"
            >
              <ListItemIcon>
                <HandymanIcon />
              </ListItemIcon>
              <ListItemText primary="Jobs" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem key="scheduler" disablePadding>
            <ListItemButton
              component={Link}
              to="/scheduler"
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
              data-title="Scheduler"
            >
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText
                primary="Scheduler"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="customers" disablePadding>
            <ListItemButton
              component={Link}
              to="/customers"
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
              data-title="Customers"
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText
                primary="Customers"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="wrap Colors" disablePadding>
            <ListItemButton
              component={Link}
              to="/wrap-colors"
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
              data-title="Wrap Colors"
            >
              <ListItemIcon>
                <PaletteIcon />
              </ListItemIcon>
              <ListItemText
                primary="Wrap Colors"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          {user?.claims.includes("template_menu") && (
            <ListItem key="email-templates" disablePadding>
              <ListItemButton
                component={Link}
                to="/email-templates"
                selected={selectedIndex === 5}
                onClick={(event) => handleListItemClick(event, 5)}
                data-title="Email Templates"
              >
                <ListItemIcon>
                  <AppRegistrationIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Email Templates"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          )}
          {user?.email === "suzie@2wrap.com" && (
            <>
              <ListItem key="users" disablePadding>
                <ListItemButton
                  component={Link}
                  to="/users"
                  selected={selectedIndex === 6}
                  onClick={(event) => handleListItemClick(event, 6)}
                  data-title="Users"
                >
                  <ListItemIcon>
                    <AdminPanelSettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Users"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem key="convers" disablePadding>
                <ListItemButton
                  component={Link}
                  to="/msgs"
                  selected={selectedIndex === 7}
                  onClick={(event) => handleListItemClick(event, 7)}
                  data-title="Messages"
                >
                  <ListItemIcon>
                    <MessageIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Messages"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem key="configs" disablePadding>
                <ListItemButton
                  component={Link}
                  to="/configs"
                  selected={selectedIndex === 8}
                  onClick={(event) => handleListItemClick(event, 8)}
                  data-title="Configurations"
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Configurations"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

export default memo(Appbar);
