import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardMedia,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import ButtonControl from "../../components/controls/ButtonControl";
import CheckboxControl from "../../components/controls/CheckboxControl";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import {
  API_COLORFINISHES,
  API_COLORVINYLS,
  FALLBACKIMG,
} from "../../context/ApiEndPoints";
import useFetchList from "../../hooks/useFetchList";
import useForm from "../../hooks/useForm";

const objRecord = {
  name: "",
  price: 0,
  available: true,
  colorVinylId: "",
  colorFinishId: "",
  imageName: "",
  imageFile: null,
};

function ColorForm({ addOrEdit, selectedRecord, openDrawer, isSaving }) {
  const validate = (input = values) => {
    let temp = { ...errors };
    if ("name" in input)
      temp.name = input.name ? "" : "This field is required.";
    if ("colorVinylId" in input)
      temp.colorVinylId =
        input.colorVinylId.length !== 0 ? "" : "This field is required.";
    if ("colorFinishId" in input)
      temp.colorFinishId =
        input.colorFinishId.length !== 0 ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    useForm(selectedRecord ? selectedRecord : objRecord, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setValues({ ...values, imageFile, imageName: x.target.result });
      };
      reader.readAsDataURL(imageFile);
    } else {
      setValues({
        ...values,
        imageFile: null,
        imageName: FALLBACKIMG,
      });
    }
  };

  const {
    records: vinylOptions,
    isLoading: vinylIsLoading,
    fetchError: vinylFetchError,
  } = useFetchList(API_COLORVINYLS);

  const {
    records: finishOptions,
    isLoading: finishIsLoading,
    fetchError: finishFetchError,
  } = useFetchList(API_COLORFINISHES);

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <List>
          {selectedRecord ? (
            <ListItem
              secondaryAction={
                <Tooltip title="Details" placement="bottom" arrow>
                  <IconButton
                    color="primary"
                    aria-label="details"
                    onClick={() => openDrawer("details", selectedRecord)}
                  >
                    <ArticleIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Edit" secondary="Wrap Color" />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add New" secondary="Wrap Color" />
            </ListItem>
          )}
        </List>
        <Divider />
        <Paper elevation={0} sx={{ p: 3 }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems="flex-start"
            spacing={2}
          >
            <Card sx={{ maxWidth: 200 }}>
              <CardMedia
                height="100%"
                component="img"
                image={values.imageName ? values.imageName : FALLBACKIMG}
                alt={values.imageName}
                loading="lazy"
              />
              <CardActions sx={{ justifyContent: "center" }}>
                <label htmlFor="color-file">
                  <input
                    type="file"
                    id="color-file"
                    name="imageFile"
                    accept="image/*"
                    onChange={showPreview}
                    hidden
                  />
                  <ButtonControl
                    component="span"
                    text="Upload"
                    startIcon={<PhotoCamera />}
                  />
                </label>
              </CardActions>
            </Card>
            <Box>
              <InputControl
                label="Name"
                name="name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
              />
              <InputControl
                label="Price"
                name="price"
                value={values.price}
                onChange={handleInputChange}
                error={errors.price}
              />
              <CheckboxControl
                label="Available"
                name="available"
                value={values.available}
                onChange={handleInputChange}
              />
              {(vinylFetchError && finishFetchError) ||
                (vinylIsLoading && finishIsLoading && (
                  <CircularProgress size={20} color="inherit" />
                ))}
              {vinylOptions && finishOptions && (
                <>
                  <SelectControl
                    label="Select Vinyl Brand"
                    name="colorVinylId"
                    value={values.colorVinylId}
                    onChange={handleInputChange}
                    options={vinylOptions}
                    error={errors.colorVinylId}
                  />
                  <SelectControl
                    label="Select Finish"
                    name="colorFinishId"
                    value={values.colorFinishId}
                    onChange={handleInputChange}
                    options={finishOptions}
                    error={errors.colorFinishId}
                  />
                  <ButtonControl
                    type="submit"
                    text="Save"
                    isSaving={isSaving}
                  />
                </>
              )}
            </Box>
          </Stack>
        </Paper>
      </Paper>
    </form>
  );
}

export default memo(ColorForm);
