import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Avatar,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Typography,
} from "@mui/material";
import React, { memo, useRef, useState } from "react";

function FilesUploader({
  inputName,
  accept,
  values,
  setValues,
  multiple = true,
}) {
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const fileInput = useRef(null);

  const handleOndragOver = (e) => {
    e.preventDefault();
    e.currentTarget.style.backgroundColor = "gray";
  };

  const handleOndrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.style.backgroundColor = "whiteSmoke";
    //grab the image files
    handleFile(e.dataTransfer.files);
  };

  const handleFile = (inputFiles) => {
    if (inputFiles) {
      let file = inputFiles;

      for (let i = 0; i < file.length; i++) {
        if (files.every((e) => e.name !== file[i].name)) files.push(file[i]);
      }

      values[inputName] = files;
      setValues({ ...values });
      setFiles(values[inputName]);

      // Crate Image list from selected image files to upload
      // const fileArray = files.map((el) => URL.createObjectURL(el));
      setImages(values[inputName]);
      // files.map((el) => URL.revokeObjectURL(el));
      fileInput.current.value = null;
    }
  };

  const handleDel = (index) => {
    values[inputName].splice(index, 1);
    setValues({ ...values });
    setFiles(values[inputName]);

    // Crate Image list from selected image files to upload
    // const fileArray = values[inputName].map((el) => URL.createObjectURL(el));
    setImages(values[inputName]);
    // values[inputName].map((el) => URL.revokeObjectURL(el));
  };

  const renderImageList = (source) => {
    return (
      <ImageList variant="masonry" cols={3} gap={5}>
        {source.map((el, i) => (
          <ImageListItem
            key={i}
            elevation={1}
            sx={{ border: 5, borderColor: "divider", borderRadius: 1 }}
          >
            <img
              src={
                el.type === "application/pdf"
                  ? "/img/2wrap-icon-black.png"
                  : URL.createObjectURL(el)
              }
              alt={el.name}
              loading="lazy"
            />
            <ImageListItemBar
              position="top"
              actionIcon={
                <IconButton
                  color="error"
                  onClick={() => handleDel(i)}
                  aria-label="delete"
                >
                  <CancelIcon />
                </IconButton>
              }
              sx={{ background: "none" }}
            />
            {URL.revokeObjectURL(el)}
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  return (
    <Paper elevation={0}>
      <Paper
        variant="outlined"
        align="center"
        sx={{ p: 3, bgcolor: "divider", cursor: "pointer" }}
        onDragOver={handleOndragOver}
        onDrop={handleOndrop}
        onClick={() => fileInput.current.click()}
      >
        <Divider variant="middle">
          <Avatar>
            <CloudUploadIcon />
          </Avatar>
        </Divider>
        <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
          Drag and drop or click here....
        </Typography>
        <input
          ref={fileInput}
          type="file"
          id="job-file"
          name={inputName}
          accept={accept}
          onChange={(e) => handleFile(e.target.files)}
          multiple={multiple}
          hidden
        />
      </Paper>
      {values[inputName] &&
        Object.keys(values[inputName]).length > 0 &&
        images &&
        renderImageList(images)}
    </Paper>
  );
}

export default memo(FilesUploader);
