import FaceIcon from "@mui/icons-material/Face";
import PersonIcon from "@mui/icons-material/Person";
import SendIcon from "@mui/icons-material/Send";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import { API_DESIGNCOMMENTS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import { dateTimeStamp } from "../../scripts/Scripts";

function DesignComments({
  records,
  setRecords,
  selectedRecord,
  submit = true,
}) {
  const { user } = useAppContext();
  const { setNotify } = useComponentContext();

  const [values, setValues] = useState({
    designId: 0,
    isUser: true,
    comment: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    values.designId = selectedRecord.designId;
    setValues({ ...values });

    // POST request using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    const results = await ApiFetch(
      `${API_DESIGNCOMMENTS}`,
      requestOptions,
      setNotify
    );

    selectedRecord.designComments = [...selectedRecord.designComments, results];
    const index = records.findIndex(
      (e) => e.designId === selectedRecord.designId
    );
    records[index] = selectedRecord;
    setRecords([...records]);
    setValues({ ...values, comment: "" });
    setIsSaving(false);
  };

  return (
    <>
      <List disablePadding>
        {selectedRecord.designComments &&
          selectedRecord.designComments.map((it, i) => (
            <ListItem
              key={i}
              divider
              disableGutters={user.sopTitle === it.user ? true : false}
            >
              <ListItemIcon>
                {it.isUser ? (
                  <FaceIcon
                    color={user.sopTitle === it.user ? "success" : "primary"}
                  />
                ) : (
                  <PersonIcon color="primary" />
                )}
              </ListItemIcon>
              <ListItemText
                primary={<q>{it.comment}</q>}
                primaryTypographyProps={{
                  whiteSpace: "pre-line",
                  textOverflow: "ellipsis",
                }}
                // secondary={`${it.isUser ? it.user : "Customer"} -- ${
                //   it.createdDate
                // }`}
                secondary={dateTimeStamp(it.createdDate)}
              />
            </ListItem>
          ))}
      </List>
      {submit && (
        <form onSubmit={handleSubmit}>
          <Stack direction="row">
            <InputControl
              label="Type a message"
              name="comment"
              value={values.comment}
              onChange={(e) =>
                setValues({ ...values, comment: e.target.value })
              }
              multiline
              required
              sx={{ m: 0 }}
            />
            <ButtonControl
              type="submit"
              variant="outlined"
              size="large"
              endIcon={<SendIcon />}
              isSaving={isSaving}
            />
          </Stack>
          <Typography variant="body2" color="text.secondary" sx={{ m: 1 }}>
            Send message to Customer
          </Typography>
        </form>
      )}
    </>
  );
}

export default memo(DesignComments);
