import { Paper } from "@mui/material";
import Chart from "chart.js/auto";
import React, { memo, useEffect } from "react";

function GraphChart({
  id,
  data,
  type,
  title,
  subtitle,
  legend = true,
  maintainAspectRatio = false,
}) {
  let chart;
  const drawChart = function (data) {
    if (chart) {
      chart.data = data;
      chart.update();
    } else {
      chart = new Chart(document.getElementById(id), {
        type: type ? type : "line",
        options: {
          responsive: true,
          maintainAspectRatio: maintainAspectRatio,
          plugins: {
            legend: legend,
            title: {
              display: true,
              text: title,
              font: { size: 24 },
            },
            subtitle: {
              display: true,
              text: subtitle && subtitle + ":" + data.count,
              font: { size: 14 },
              padding: {
                bottom: 10,
              },
            },
          },
        },
        data: data,
      });
    }
  };

  useEffect(() => {
    drawChart(data);

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper square sx={{ p: 3, pt: 1, height: { sx: "100%", md: "43vh" } }}>
      <canvas id={id}></canvas>
    </Paper>
  );
}

export default memo(GraphChart);
