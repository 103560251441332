import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import useForm from "../../hooks/useForm";

const objRecord = {
  name: "",
};

function VinylForm({ addOrEdit, selectedRecord, openInDrawer, isSaving }) {
  const validate = (input = values) => {
    let temp = { ...errors };
    if ("name" in input)
      temp.name = input.name ? "" : "This field is required.";

    setErrors({ ...temp });

    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors, resetForm } = useForm(
    selectedRecord ? selectedRecord : objRecord,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        {selectedRecord ? (
          <List>
            <ListItem
              secondaryAction={
                <Tooltip title="Details" placement="bottom" arrow>
                  <IconButton
                    color="primary"
                    aria-label="details"
                    onClick={() => openInDrawer("details", selectedRecord)}
                  >
                    <ArticleIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Edit" secondary="Vinyl Brand" />
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add New" secondary="Vinyl Brand" />
            </ListItem>
          </List>
        )}
        <Divider />
        <Paper elevation={0} sx={{ p: 3 }}>
          <InputControl
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
          {!isSaving && <ButtonControl type="submit" text="Save" />}
          {isSaving && (
            <ButtonControl text="Saving..." isSaving={isSaving} disabled />
          )}
        </Paper>
      </Paper>
    </form>
  );
}

export default memo(VinylForm);
