import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FilesUploader from "../../components/FilesUploader";
import ServiceCard from "../../components/ServiceCard";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import RadioGroupControl from "../../components/controls/RadioGroupControl";
import SelectControl from "../../components/controls/SelectControl";
import { API_QUOTES } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";

const objRecord = {
  industry: "Advertising",
};

function EditAdvertising() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAppContext();
  const [values, setValues] = useState({ ...objRecord });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [service, setService] = useState("Service");
  const [serviceName, setServiceName] = useState("_Service");
  const [fetchError, setFetchError] = useState(false);

  const validate = (input = values) => {
    let temp = { ...errors };

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e, switchName = null) => {
    const { name, value } = e.target;
    values[name] = value;
    if (switchName) values[switchName] = true;

    setValues({ ...values });
    validate({ [name]: value });
  };

  const handleServiceChange = (e) => {
    const { value } = e.target;
    const a = value.replace(/\b\w/g, (c) => c.toUpperCase());
    setServiceName("_" + a);
    const b = a.replace(/\s/g, "");
    setService(b);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      const data = {};
      const quoteServices = {};
      const quoteItems = {};
      for (const [key, val] of Object.entries(values)) {
        if (key.startsWith("_") && val) {
          const k = key.slice(1);
          quoteServices[k] = val;
          continue;
        }

        if (/^[A-Z]/.test(key[0]) && val) {
          quoteItems[key] = typeof val === "boolean" ? "Yes" : val;
          continue;
        }

        if (/^[a-z]/.test(key[0])) {
          data[key] = val;
          continue;
        }
      }

      for (const [key] of Object.entries(data)) {
        if (key.endsWith("_") || key.startsWith("_")) {
          delete data[key];
        }
      }

      let record = data;
      record.quoteServices = JSON.stringify(quoteServices);
      record.quoteItems = JSON.stringify(quoteItems);

      // Create formData
      const formData = new FormData();
      // Add quote values to formData
      for (const [key, val] of Object.entries(record)) {
        formData.append(key, val);
      }

      // Add fleetMarketingFile values to formData
      if (values.fleetMarketingFile) {
        for (const val of Object.values(values.fleetMarketingFile)) {
          formData.append("fleetMarketingFile", val);
        }
      }

      // Add windowGraphicsFile values to formData
      if (values.windowGraphicsFile) {
        for (const val of Object.values(values.windowGraphicsFile)) {
          formData.append("windowGraphicsFile", val);
        }
      }

      // Add printServiceFile values to formData
      if (values.printServiceFile) {
        for (const val of Object.values(values.printServiceFile)) {
          formData.append("printServiceFile", val);
        }
      }

      // Add SignsFile values to formData
      if (values.SignsFile) {
        for (const val of Object.values(values.SignsFile)) {
          formData.append("SignsFile", val);
        }
      }

      // Add flagsAndBannersFile values to formData
      if (values.flagsAndBannersFile) {
        for (const val of Object.values(values.flagsAndBannersFile)) {
          formData.append("flagsAndBannersFile", val);
        }
      }

      // Add exhibitStandsFile values to formData
      if (values.exhibitStandsFile) {
        for (const val of Object.values(values.exhibitStandsFile)) {
          formData.append("exhibitStandsFile", val);
        }
      }

      // POST request using fetch
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: "Bearer " + user.token },
        body: formData,
      };

      try {
        const response = await fetch(
          `${API_QUOTES}/QuoteItems/${params.quoteId}`,
          requestOptions
        );
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (json && json.message) || response.status;
          throw Error(error);
        }

        setValues({ ...objRecord });
        setErrors({});
        navigate("/job-details/" + json.quoteId, { replace: true });
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Slide direction="right" in={true}>
          <Grid container>
            <Grid item xs={12} md={9}>
              <Grid container spacing={1} align="left">
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[
                      "FleetMarketingOption",
                      "FleetMarketingFile",
                      "fleetMarketingNumberOfVehicles",
                      "FleetMarketingVehicleBrand",
                      "FleetMarketingVehicleYear",
                      "FleetMarketingVehicleColor",
                    ]}
                    values={values}
                    setValues={setValues}
                    switchName="_FleetMarketing"
                    icon="wi-fleet-marketing"
                    title="Fleet Marketing"
                  >
                    <RadioGroupControl
                      aria-label="fleet marketing"
                      name="FleetMarketingOption"
                      onChange={(e) => handleInputChange(e, "_FleetMarketing")}
                      options={[
                        {
                          label: "Design Request",
                          value: "Design Request",
                        },
                        {
                          label: "I Have a Design",
                          value: "I Have a Design",
                        },
                      ]}
                    />
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Upload Design / Example</InputLabel>
                      <FilesUploader
                        inputName="fleetMarketingFile"
                        accept="image/*"
                        values={values}
                        setValues={setValues}
                      />
                    </FormControl>
                    <InputControl
                      type="number"
                      label="Number Of Vehicles"
                      name="FleetMarketingNumberOfVehicles"
                      onChange={(e) => handleInputChange(e, "_FleetMarketing")}
                    />
                    <InputControl
                      label="Vehicle Brand"
                      name="FleetMarketingVehicleBrand"
                      onChange={(e) => handleInputChange(e, "_FleetMarketing")}
                    />
                    <InputControl
                      label="Vehicle Type"
                      name="FleetMarketingVehicleType"
                      onChange={(e) => handleInputChange(e, "_FleetMarketing")}
                    />
                    <InputControl
                      label="Vehicle Year"
                      name="FleetMarketingVehicleYear"
                      onChange={(e) => handleInputChange(e, "_FleetMarketing")}
                    />
                    <InputControl
                      label="Vehicle Color"
                      name="FleetMarketingVehicleColor"
                      onChange={(e) => handleInputChange(e, "_FleetMarketing")}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[
                      "WindowGraphicsOption",
                      "windowGraphicsFile",
                      "WindowGraphicsQuantity",
                      "WindowGraphicsHeight",
                      "WindowGraphicsWidth",
                      "WindowGraphicsUnits",
                      "WindowGraphicsMoreInfo",
                    ]}
                    values={values}
                    setValues={setValues}
                    switchName="_WindowGraphics"
                    icon="wi-window-graphics"
                    title="Window Graphics"
                  >
                    <RadioGroupControl
                      aria-label="window graphics"
                      name="WindowGraphicsOption"
                      onChange={(e) => handleInputChange(e, "_WindowGraphics")}
                      options={[
                        {
                          label: "Design Request",
                          value: "Design Request",
                        },
                        {
                          label: "I Have a Design",
                          value: "I Have a Design",
                        },
                      ]}
                    />
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Upload Design / Example</InputLabel>
                      <FilesUploader
                        inputName="windowGraphicsFile"
                        accept="image/*"
                        values={values}
                        setValues={setValues}
                      />
                    </FormControl>
                    <InputControl
                      type="number"
                      label="Quantity"
                      name="WindowGraphicsQuantity"
                      onChange={(e) => handleInputChange(e, "_WindowGraphics")}
                    />
                    <Stack direction="row">
                      <InputControl
                        type="number"
                        label="Dimension Height"
                        name="WindowGraphicsHeight"
                        onChange={(e) =>
                          handleInputChange(e, "_WindowGraphics")
                        }
                      />
                      <InputControl
                        type="number"
                        label="Dimension Width"
                        name="WindowGraphicsWidth"
                        onChange={(e) => {
                          handleInputChange(e, "_WindowGraphics");
                          if (!values.WindowGraphicsWidth)
                            setValues({ ...values, WindowGraphicsUnits: "" });
                        }}
                      />
                      {values.WindowGraphicsWidth && (
                        <SelectControl
                          label="Units"
                          name="WindowGraphicsUnits"
                          value={
                            values.WindowGraphicsUnits
                              ? values.WindowGraphicsUnits
                              : ""
                          }
                          onChange={(e) =>
                            handleInputChange(e, "_WindowGraphics")
                          }
                          options={[
                            { label: "cm", value: "cm" },
                            { label: "Inch", value: "Inch" },
                          ]}
                        />
                      )}
                    </Stack>
                    <InputControl
                      label="Tell me more"
                      name="WindowGraphicsMoreInfo"
                      multiline
                      rows={3}
                      onChange={(e) => handleInputChange(e, "_WindowGraphics")}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={["GraphicDesignOption", "GraphicDesignMoreInfo"]}
                    values={values}
                    setValues={setValues}
                    switchName="_GraphicDesign"
                    icon="wi-graphic-design"
                    title="Graphic Design"
                  >
                    <RadioGroupControl
                      aria-label="graphic graphics"
                      name="GraphicDesignOption"
                      onChange={(e) => handleInputChange(e, "_GraphicDesign")}
                      options={[
                        {
                          label: "Logo",
                          value: "Logo",
                        },
                        {
                          label: "Brandingbook",
                          value: "Brandingbook",
                        },
                        {
                          label: "Flyer",
                          value: "Flyer",
                        },
                        {
                          label: "Fleetmarketing",
                          value: "Fleetmarketing",
                        },
                        {
                          label: "Business cards",
                          value: "Business cards",
                        },
                        {
                          label: "Signs",
                          value: "Signs",
                        },
                        {
                          label: "Others",
                          value: "Others",
                        },
                      ]}
                    />
                    <InputControl
                      label="Tell Me More"
                      name="GraphicDesignMoreInfo"
                      multiline
                      rows={3}
                      onChange={(e) => handleInputChange(e, "_GraphicDesign")}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[
                      "PrintServiceOption",
                      "printServiceFile",
                      "PrintServiceQuantity",
                      "PrintServiceHeight",
                      "PrintServiceWidth",
                      "PrintServiceUnits",
                      "PrintServiceMoreInfo",
                    ]}
                    values={values}
                    setValues={setValues}
                    switchName="_PrintService"
                    icon="wi-print-service"
                    title="Print Service"
                  >
                    <RadioGroupControl
                      aria-label="print service"
                      name="PrintServiceOption"
                      onChange={(e) => handleInputChange(e, "_PrintService")}
                      options={[
                        {
                          label: "Design Request",
                          value: "Design Request",
                        },
                        {
                          label: "I Have a Design",
                          value: "I Have a Design",
                        },
                      ]}
                    />
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Upload Design / Example</InputLabel>
                      <FilesUploader
                        inputName="printServiceFile"
                        accept="image/*"
                        values={values}
                        setValues={setValues}
                      />
                    </FormControl>
                    <InputControl
                      type="number"
                      label="Quantity"
                      name="PrintServiceQuantity"
                      onChange={(e) => handleInputChange(e, "_PrintService")}
                    />
                    <Stack direction="row">
                      <InputControl
                        type="number"
                        label="Dimension Height"
                        name="PrintServiceHeight"
                        onChange={(e) => handleInputChange(e, "_PrintService")}
                      />
                      <InputControl
                        type="number"
                        label="Dimension Width"
                        name="PrintServiceWidth"
                        onChange={(e) => {
                          handleInputChange(e, "_PrintService");
                          if (!values.PrintServiceWidth)
                            setValues({ ...values, PrintServiceUnits: "" });
                        }}
                      />
                      {values.PrintServiceWidth && (
                        <SelectControl
                          label="Units"
                          name="PrintServiceUnits"
                          value={
                            values.PrintServiceUnits
                              ? values.PrintServiceUnits
                              : ""
                          }
                          onChange={(e) =>
                            handleInputChange(e, "_PrintService")
                          }
                          options={[
                            { label: "cm", value: "cm" },
                            { label: "Inch", value: "Inch" },
                          ]}
                        />
                      )}
                    </Stack>
                    <InputControl
                      label="Tell Me More"
                      name="PrintServiceMoreInfo"
                      multiline
                      rows={3}
                      onChange={(e) => handleInputChange(e, "_PrintService")}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[
                      "SignsOption",
                      "signsFile",
                      "SignsQuantity",
                      "SignsHeight",
                      "SignsWidth",
                      "SignsUnits",
                      "SignsMoreInfo",
                    ]}
                    values={values}
                    setValues={setValues}
                    switchName="_Signs"
                    icon="wi-signs"
                    title="Signs"
                  >
                    <RadioGroupControl
                      aria-label="signs"
                      name="SignsOption"
                      onChange={(e) => handleInputChange(e, "_Signs")}
                      options={[
                        {
                          label: "Design Request",
                          value: "Design Request",
                        },
                        {
                          label: "I Have a Design",
                          value: "I Have a Design",
                        },
                      ]}
                    />
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Upload Design / Example</InputLabel>
                      <FilesUploader
                        inputName="signsFile"
                        accept="image/*"
                        values={values}
                        setValues={setValues}
                      />
                    </FormControl>
                    <InputControl
                      type="number"
                      label="Quantity"
                      name="SignsQuantity"
                      onChange={(e) => handleInputChange(e, "_Signs")}
                    />
                    <Stack direction="row">
                      <InputControl
                        type="number"
                        label="Dimension Height"
                        name="SignsHeight"
                        onChange={(e) => handleInputChange(e, "_Signs")}
                      />
                      <InputControl
                        type="number"
                        label="Dimension Width"
                        name="SignsWidth"
                        onChange={(e) => {
                          handleInputChange(e, "_Signs");
                          if (!values.SignsWidth)
                            setValues({ ...values, SignsUnits: "" });
                        }}
                      />
                      {values.SignsWidth && (
                        <SelectControl
                          label="Units"
                          name="SignsUnits"
                          value={values.SignsUnits ? values.SignsUnits : ""}
                          onChange={(e) => handleInputChange(e, "_Signs")}
                          options={[
                            { label: "cm", value: "cm" },
                            { label: "Inch", value: "Inch" },
                          ]}
                        />
                      )}
                    </Stack>
                    <InputControl
                      label="Tell Me More"
                      name="SignsMoreInfo"
                      multiline
                      rows={3}
                      onChange={(e) => handleInputChange(e, "_Signs")}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[
                      "FlagsAndBannersOption",
                      "flagsAndBannersFile",
                      "FlagsAndBannersQuantity",
                      "FlagsAndBannersHeight",
                      "FlagsAndBannersWidth",
                      "FlagsAndBannersUnits",
                      "FlagsAndBannersMoreInfo",
                    ]}
                    values={values}
                    setValues={setValues}
                    switchName="_FlagsAndBanners"
                    icon="wi-flags-banners"
                    title="Flags &amp; Banners"
                  >
                    <RadioGroupControl
                      aria-label="flags and banners"
                      name="FlagsAndBannersOption"
                      onChange={(e) => handleInputChange(e, "_FlagsAndBanners")}
                      options={[
                        {
                          label: "Design Request",
                          value: "Design Request",
                        },
                        {
                          label: "I Have a Design",
                          value: "I Have a Design",
                        },
                      ]}
                    />
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Upload Design / Example</InputLabel>
                      <FilesUploader
                        inputName="flagsAndBannersFile"
                        accept="image/*"
                        values={values}
                        setValues={setValues}
                      />
                    </FormControl>
                    <InputControl
                      type="number"
                      label="Quantity"
                      name="FlagsAndBannersQuantity"
                      onChange={(e) => handleInputChange(e, "_FlagsAndBanners")}
                    />
                    <Stack direction="row">
                      <InputControl
                        type="number"
                        label="Dimension Height"
                        name="FlagsAndBannersHeight"
                        onChange={(e) =>
                          handleInputChange(e, "_FlagsAndBanners")
                        }
                      />
                      <InputControl
                        type="number"
                        label="Dimension Width"
                        name="FlagsAndBannersWidth"
                        onChange={(e) => {
                          handleInputChange(e, "_FlagsAndBanners");
                          if (!values.FlagsAndBannersWidth)
                            setValues({ ...values, FlagsAndBannersUnits: "" });
                        }}
                      />
                      {values.FlagsAndBannersWidth && (
                        <SelectControl
                          label="Units"
                          name="FlagsAndBannersUnits"
                          value={
                            values.FlagsAndBannersUnits
                              ? values.FlagsAndBannersUnits
                              : ""
                          }
                          onChange={(e) =>
                            handleInputChange(e, "_FlagsAndBanners")
                          }
                          options={[
                            { label: "cm", value: "cm" },
                            { label: "Inch", value: "Inch" },
                          ]}
                        />
                      )}
                    </Stack>
                    <InputControl
                      label="Tell Me More"
                      name="FlagsAndBannersMoreInfo"
                      multiline
                      rows={3}
                      onChange={(e) => handleInputChange(e, "_FlagsAndBanners")}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[
                      "ExhibitStandsOption",
                      "exhibitStandsFile",
                      "ExhibitStandsQuantity",
                      "ExhibitStandsHeight",
                      "ExhibitStandsWidth",
                      "ExhibitStandsUnits",
                      "ExhibitStandsMoreInfo",
                    ]}
                    values={values}
                    setValues={setValues}
                    switchName="_ExhibitStands"
                    icon="wi-exhibit-stands"
                    title="Exhibit Stands"
                  >
                    <RadioGroupControl
                      aria-label="exhibit stands"
                      name="ExhibitStandsOption"
                      onChange={(e) => handleInputChange(e, "_ExhibitStands")}
                      options={[
                        {
                          label: "Design Request",
                          value: "Design Request",
                        },
                        {
                          label: "I Have a Design",
                          value: "I Have a Design",
                        },
                      ]}
                    />
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Upload Design / Example</InputLabel>
                      <FilesUploader
                        inputName="exhibitStands"
                        accept="image/*"
                        values={values}
                        setValues={setValues}
                      />
                    </FormControl>
                    <InputControl
                      type="number"
                      label="Quantity"
                      name="ExhibitStandsQuantity"
                      onChange={(e) => handleInputChange(e, "_ExhibitStands")}
                    />
                    <Stack direction="row">
                      <InputControl
                        type="number"
                        label="Dimension Height"
                        name="ExhibitStandsHeight"
                        onChange={(e) => handleInputChange(e, "_ExhibitStands")}
                      />
                      <InputControl
                        type="number"
                        label="Dimension Width"
                        name="ExhibitStandsWidth"
                        onChange={(e) => {
                          handleInputChange(e, "_ExhibitStands");
                          if (!values.ExhibitStandsWidth)
                            setValues({ ...values, ExhibitStandsUnits: "" });
                        }}
                      />
                      {values.ExhibitStandsWidth && (
                        <SelectControl
                          label="Units"
                          name="ExhibitStandsUnits"
                          value={
                            values.ExhibitStandsUnits
                              ? values.ExhibitStandsUnits
                              : ""
                          }
                          onChange={(e) =>
                            handleInputChange(e, "_ExhibitStands")
                          }
                          options={[
                            { label: "cm", value: "cm" },
                            { label: "Inch", value: "Inch" },
                          ]}
                        />
                      )}
                    </Stack>
                    <InputControl
                      label="Tell Me More"
                      name="ExhibitStandsMoreInfo"
                      multiline
                      rows={3}
                      onChange={(e) => handleInputChange(e, "_ExhibitStands")}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={["OtherDetails"]}
                    values={values}
                    setValues={setValues}
                    switchName="_Other"
                    icon="wi-other"
                    title="Other"
                  >
                    <InputControl
                      label="Please Specify"
                      name="OtherDetails"
                      multiline
                      rows={3}
                      onChange={(e) => handleInputChange(e, "_Other")}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[service + "Title", service + "Details"]}
                    values={values}
                    setValues={setValues}
                    switchName={serviceName.replace(/\s/g, "")}
                    icon="wi-other"
                    title={serviceName.substring(1)}
                  >
                    <InputControl
                      label="Title"
                      name={service + "Title"}
                      onChange={(e) =>
                        handleServiceChange(e, serviceName.replace(/\s/g, ""))
                      }
                    />
                    <InputControl
                      label="Description"
                      name={service + "Details"}
                      multiline
                      rows={3}
                      onChange={(e) =>
                        handleInputChange(e, serviceName.replace(/\s/g, ""))
                      }
                    />
                  </ServiceCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} textAlign="center">
              <Fab
                component={Link}
                to={`/job-details/${params.quoteId}`}
                aria-label="go back"
                sx={{ mb: 3 }}
              >
                <ArrowBackIcon />
              </Fab>
              <Typography color="text.secondary">
                Select services form left and click CONFIRM once done
              </Typography>
              <Divider variant="middle" sx={{ my: 2 }}>
                {fetchError}
              </Divider>
              {Object.keys(values).some(
                (k) => /^[A-Z]/.test(k[0]) && values[k]
              ) ? (
                <ButtonControl
                  type="submit"
                  text="Submit"
                  isSaving={isSaving}
                />
              ) : (
                <ButtonControl text="Submit" disabled />
              )}
            </Grid>
          </Grid>
        </Slide>
      </form>
    </>
  );
}

export default memo(EditAdvertising);
