import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Icon,
  Slide,
  Switch,
} from "@mui/material";
import React, { memo, useState } from "react";

function ServiceCard({
  services,
  values,
  setValues,
  switchName,
  icon,
  title,
  children,
}) {
  const [checked, setChecked] = useState(false);

  const toggleSwitch = (e) => {
    let current = e.currentTarget;
    current.getElementsByTagName("input")[0].click();
  };

  const handleSwitch = (e) => {
    e.stopPropagation();
    const { checked } = e.target;
    setChecked(checked);
    if (checked === false) {
      services.forEach((item) => {
        values[item] = typeof values[item] === "string" ? "" : false;
      });
      setValues({ ...values, [switchName]: checked });
    }
  };

  return (
    <Card elevation={1} align="left" sx={{ height: checked ? "100%" : "auto" }}>
      <CardActionArea onClick={toggleSwitch}>
        <CardHeader
          action={
            <Switch
              name={switchName}
              onClick={handleSwitch}
              color="error"
              checked={checked}
            />
          }
          avatar={<Icon className={icon} fontSize="large" />}
          title={title}
          titleTypographyProps={{ variant: "subtitle1" }}
        />
        <Divider />
      </CardActionArea>
      {checked && (
        <Slide in={checked}>
          <CardContent sx={{ px: 3 }}>{children}</CardContent>
        </Slide>
      )}
    </Card>
  );
}

export default memo(ServiceCard);
