import ErrorIcon from "@mui/icons-material/Error";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import ButtonControl from "../components/controls/ButtonControl";
import useComponentContext from "../hooks/useComponentContext";

function ConfirmDialog() {
  const { confirmDialog, setConfirmDialog } = useComponentContext();

  function NewlineText(props) {
    const text = props.text;
    const newText = text.split("\n").map((str, i) => (
      <Typography
        key={i}
        sx={{ fontSize: "large", color: "primary.dark" }}
        gutterBottom
      >
        {str}
      </Typography>
    ));

    return newText;
  }

  return (
    <Dialog open={confirmDialog.isOpen || false} fullWidth maxWidth="sm">
      <DialogTitle sx={{ p: 5, textAlign: "center" }}>
        {confirmDialog?.title && <NewlineText text={confirmDialog.title} />}
      </DialogTitle>
      <DialogContent dividers sx={{ px: 2, backgroundColor: "warning.main" }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="flex-end"
        >
          <Typography sx={{ color: "white" }}>
            {confirmDialog.subTitle}
          </Typography>
          <ErrorIcon sx={{ color: "white" }} />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 2, backgroundColor: "divider" }}>
        <ButtonControl
          text="No"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        />
        <ButtonControl
          color="error"
          text="Yes"
          onClick={confirmDialog.onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
}

export default memo(ConfirmDialog);
