import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { memo } from "react";

function RadioGroupControl(props) {
  const { label, name, value, margin, onChange, options, ...other } = props;
  return (
    <FormControl>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        key={value}
        aria-label={name}
        name={name}
        defaultValue={value}
        margin={margin || "normal"}
        onChange={onChange}
        {...other}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default memo(RadioGroupControl);
