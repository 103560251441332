export const BASEURL = "https://wrapapi.azurewebsites.net";
export const API = "https://wrapapi.azurewebsites.net/api";
// export const BASEURL = "https://localhost:44389";
// export const API = "https://localhost:44389/api";
export const API_ALERTS = `${API}/Alerts`;
export const API_ROLES = `${API}/AppRoles`;
export const API_CLAIMS = `${API}/AppClaims`;
export const API_USERS = `${API}/AppUsers`;
export const API_USERS_ACCOUNT = `${API}/AppUsers/Account`;
export const API_USERS_PROFILE = `${API}/AppUsers/Profile`;
export const API_AUTHS = `${API}/Auths`;
export const API_BLOGS = `${API}/Blogs`;
export const API_COLORS = `${API}/Colors`;
export const API_CUSTOMERS = `${API}/Customers`;
export const API_GROUPS = `${API}/Groups`;
export const API_COLORFINISHES = `${API}/ColorFinishes`;
export const API_COLORVINYLS = `${API}/ColorVinyls`;
export const API_DESIGNS = `${API}/Designs`;
export const API_DESIGNCOMMENTS = `${API}/DesignComments`;
export const API_EMAILTEMPLATES = `${API}/EmailTemplates`;
export const API_SMSTEMPLATES = `${API}/SmsTemplates`;
export const API_MEDIAS = `${API}/Medias`;
export const API_QUOTES = `${API}/Quotes`;
export const API_QUOTECOMMENTS = `${API}/QuoteComments`;
export const API_REQUEST = `${API}/Request`;
export const API_SHOPS = `${API}/Shops`;
export const API_SHOPS_SELECT = `${API}/Shops/Select`;
export const API_SCHEDULES = `${API}/Schedules`;
export const API_INVOICES = `${API}/Invoices`;
export const API_MSGS = `${API}/Msgs`;
export const API_HUB = `${BASEURL}/WrapHub`;
export const PORTAL = `${BASEURL}/Portal`;
export const FALLBACKIMG = "img/2wrap-icon-black.png";
