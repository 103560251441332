import {
  Autocomplete,
  Box,
  Card,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { API_COLORS } from "../context/ApiEndPoints";
import useFetchList from "../hooks/useFetchList";
import InputControl from "./controls/InputControl";

function ColorSelect(props) {
  const { label, name, values, onChange, imgSrc } = props;

  const [open, setOpen] = useState(false);
  const [colorOptions, setColorOptions] = useState([]);
  const loading = open && colorOptions.length === 0;
  const { records, isLoading, fetchError } = useFetchList(
    `${API_COLORS}/Select`
  );

  useEffect(() => {
    let active = true;

    if (!loading) return undefined;

    if (active) records && setColorOptions([...records]);

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (!open) setColorOptions([]);
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-colors"
      freeSolo
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(colorOptions, value) =>
        colorOptions.name === value.name
      }
      getOptionLabel={(colorOptions) => {
        values[imgSrc] = colorOptions.imageName;
        return `${colorOptions.name} : ${colorOptions.colorFinish} : ${colorOptions.colorVinyl}`;
      }}
      options={colorOptions}
      loading={loading}
      renderOption={(props, colorOptions) => (
        <Box
          component="li"
          sx={{ "& > div": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <Card variant="outlined" sx={{ maxWidth: 72 }}>
            <CardMedia
              height="100%"
              component="img"
              src={colorOptions.imageName}
              alt={colorOptions.imageName}
              loading="lazy"
            />
          </Card>
          {colorOptions.name} : {colorOptions.colorVinyl} :{" "}
          {colorOptions.colorFinish}
        </Box>
      )}
      renderInput={(params) => (
        <InputControl
          {...params}
          label={label}
          name={name}
          onChange={onChange}
          value={values[name]}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {fetchError ||
                  isLoading ||
                  (loading && <CircularProgress size={20} color="inherit" />)}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default memo(ColorSelect);
