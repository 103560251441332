import { CircularProgress } from "@mui/material";
import { memo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import ButtonControl from "../components/controls/ButtonControl";
import InputControl from "../components/controls/InputControl";
import SelectControl from "../components/controls/SelectControl";
import { API_CUSTOMERS, API_GROUPS } from "../context/ApiEndPoints";
import useAppContext from "../hooks/useAppContext";
import useComponentContext from "../hooks/useComponentContext";
import useFetchList from "../hooks/useFetchList";
import useForm from "../hooks/useForm";
import { countriesArr } from "../scripts/Scripts";
import ApiFetch from "./ApiFetch";

function CustomerEditCard(props) {
  const { records, setRecords, setIsToggleEdit } = props;
  const { user } = useAppContext();
  const { setNotify } = useComponentContext();
  const [isSaving, setIsSaving] = useState(false);

  const validate = (input = values) => {
    let temp = { ...errors };

    // Customer Info validation
    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("firstName" in input)
      temp.firstName = input.firstName ? "" : "This field is required.";
    if ("lastName" in input)
      temp.lastName = input.lastName ? "" : "This field is required.";
    if ("city" in input)
      temp.city = input.city ? "" : "This field is required.";
    if ("state" in input)
      temp.state = input.state ? "" : "This field is required.";
    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";
    if ("zipCode" in input)
      temp.zipCode = input.zipCode ? "" : "This field is required.";
    if ("phoneNumber" in input)
      temp.phoneNumber = input.phoneNumber ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } = useForm(
    records,
    true,
    validate
  );

  const {
    records: groupsSelect,
    isLoading: groupsLoading,
    fetchError: groupsError,
  } = useFetchList(API_GROUPS);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    values[name] = value;

    setValues({ ...values });
    validate({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    //Create formData with file
    const formData = new FormData();
    for (const [k, v] of Object.entries(values)) {
      formData.append(k, v ?? "");
    }

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: { Authorization: "Bearer " + user.token },
      body: formData,
    };
    const results = await ApiFetch(
      `${API_CUSTOMERS}/${records.customerId}`,
      requestOptions,
      setNotify,
      "Customer Updated Successfully!"
    );

    if (results) {
      setRecords(values);
      setIsToggleEdit(false);
    }
    setIsSaving(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputControl
        label="First Name"
        name="firstName"
        value={values.firstName}
        onChange={handleInputChange}
        error={errors.firstName}
      />
      <InputControl
        label="Last Name"
        name="lastName"
        value={values.lastName}
        onChange={handleInputChange}
        error={errors.lastName}
      />
      {groupsError ||
        (groupsLoading && <CircularProgress size={20} color="inherit" />)}
      {groupsSelect && (
        <SelectControl
          label="Company Group"
          name="groupId"
          value={values.groupId ?? ""}
          onChange={handleInputChange}
          options={groupsSelect}
          error={errors.groupId}
          textIndex={1}
        />
      )}
      <InputControl
        label="Company"
        name="company"
        value={values.company}
        onChange={handleInputChange}
        error={errors.company}
      />
      <PhoneInput
        value={values.phoneNumber}
        enableSearch={true}
        onChange={(phone) =>
          setValues({
            ...values,
            phoneNumber: phone === "" ? phone : "+" + phone,
          })
        }
        containerStyle={{
          marginTop: "16px",
          marginBottom: "8px",
        }}
        inputStyle={{
          width: "100%",
        }}
      />
      <InputControl
        label="Address"
        name="address"
        value={values.address}
        onChange={handleInputChange}
      />
      <InputControl
        label="City"
        name="city"
        value={values.city}
        onChange={handleInputChange}
        error={errors.city}
      />
      <InputControl
        label="State"
        name="state"
        value={values.state}
        onChange={handleInputChange}
        error={errors.state}
      />
      <SelectControl
        label="Country"
        name="country"
        value={values.country}
        onChange={handleInputChange}
        options={countriesArr}
        textIndex={0}
      />
      <InputControl
        label="Zip Code"
        name="zipCode"
        value={values.zipCode}
        onChange={handleInputChange}
        error={errors.zipCode}
      />
      <InputControl
        label="Tax No."
        name="taxNumber"
        value={values.taxNumber}
        onChange={handleInputChange}
        error={errors.taxNumber}
      />
      <SelectControl
        label="Where Did You Find Us"
        name="whereFindUs"
        value={values.whereFindUs}
        onChange={handleInputChange}
        options={[
          { value: "Social Media", label: "Social Media" },
          { value: "TV", label: "TV" },
          { value: "Friend", label: "Friend" },
          { value: "Google", label: "Google" },
          { value: "Other", label: "Other" },
        ]}
      />
      <ButtonControl type="submit" text="submit" isSaving={isSaving} />
      <ButtonControl
        text="Cancel"
        variant="outlined"
        onClick={() => setIsToggleEdit(false)}
        sx={{ ml: 0.5 }}
      />
    </form>
  );
}

export default memo(CustomerEditCard);
