import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
} from "@mui/material";
import React, { memo, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import InputControl from "../../components/controls/InputControl";
import { API_SMSTEMPLATES } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import useTable from "../../hooks/useTable";
import SmsTemplateForm from "./SmsTemplateForm";

const columns = [
  { field: "name", label: "Name", width: 3 },
  { field: "msgFor", label: "Template For", width: 3 },
  { field: "shop", label: "Shop", width: 3 },
  {
    field: "action",
    label: "Actions",
    width: 3,
    disableSorting: true,
    textCenter: true,
    isAction: true,
  },
];

function SmsTemplatesList() {
  const { user } = useAppContext();
  const { setNotify, setConfirmDialog } = useComponentContext();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isSaving, setIsSaving] = useState(false);
  const smsFor = ["User PIN", "Quote Sent", "Install Booking"];
  const { records, setRecords, isLoading, fetchError } =
    useFetchList(API_SMSTEMPLATES);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, columns, filterFn);

  const inputs = Array.from(document.querySelectorAll(".searchInput input"));
  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        var x;
        for (const { name, value } of inputs) {
          if (value) {
            x = items.filter((item) =>
              item[name]?.toString().toLowerCase().includes(value.toLowerCase())
            );
            items = x;
          }
        }
        return items;
      },
    });
  };

  const [IsForm, setIsForm] = useState(false);
  const openInDrawer = (action, record = null) => {
    setSelectedRecord(record ? { ...record } : null);
    setIsForm(action === "add" || action === "edit" ? true : false);
    setSelectedIndex(action === "add" ? -1 : selectedIndex);
  };

  const addOrEdit = async (record, resetForm) => {
    setIsSaving(true);
    if (!record.smsTemplateId) {
      // POST request using fetch
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      };
      const results = await ApiFetch(
        API_SMSTEMPLATES,
        requestOptions,
        setNotify,
        "Message Template Added Successfully!"
      );

      if (results) {
        setRecords([...records, results]);
        setSelectedIndex(records.length);
        resetForm();
      }
    } else {
      // PUT Request using fetch
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      };
      const results = await ApiFetch(
        `${API_SMSTEMPLATES}/${record.smsTemplateId}`,
        requestOptions,
        setNotify,
        "Message Template Updated Successfully!"
      );

      if (results) {
        const index = records.findIndex(
          (el) => el.smsTemplateId === record.smsTemplateId
        );
        records[index] = results;
        setSelectedRecord({ ...results });
      }
    }
    setIsSaving(false);
  };

  const handleDelete = async (smsTemplateId) => {
    setConfirmDialog({ isOpen: false });

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_SMSTEMPLATES}/${smsTemplateId}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      setSelectedIndex(-1);
      setSelectedRecord(null);
      setRecords((records) =>
        records.filter((el) => el.smsTemplateId !== smsTemplateId)
      );
    }
  };

  return (
    <>
      {IsForm ? (
        <SmsTemplateForm
          addOrEdit={addOrEdit}
          selectedRecord={selectedRecord}
          openInDrawer={openInDrawer}
          isSaving={isSaving}
          shopId={user.shopId}
        />
      ) : (
        <>
          {fetchError ||
            (isLoading && (
              <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
            ))}
          {records && (
            <>
              <Toolbar component={Paper} square disableGutters sx={{ mb: 0.1 }}>
                <Grid container alignItems="center">
                  {columns.map((column) =>
                    !column.isAction ? (
                      <Grid
                        item
                        xs={column.width}
                        key={column.field}
                        sx={{ p: 2 }}
                      >
                        {!column.disableSearch && (
                          <InputControl
                            variant="standard"
                            label={`Search ${column.label}`}
                            name={column.field}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={handleSearch}
                            className="searchInput"
                            margin="none"
                            fullWidth={false}
                          />
                        )}
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={column.width}
                        key={column.field}
                        sx={{ p: 2 }}
                        textAlign="right"
                      >
                        {user?.claims.includes("smstemplate_add") && (
                          <Tooltip title="Add New" placement="top" arrow>
                            <Fab
                              color="primary"
                              aria-label="add"
                              onClick={() => openInDrawer("add")}
                            >
                              <AddIcon />
                            </Fab>
                          </Tooltip>
                        )}
                      </Grid>
                    )
                  )}
                </Grid>
              </Toolbar>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: "69vh" }}>
                  <TblContainer>
                    {records.length === 0 && (
                      <caption>No Record Found!</caption>
                    )}
                    <TblHead />
                    <TableBody>
                      {records &&
                        recordsAfterPagingAndSorting().map((record, index) => (
                          <TableRow
                            key={record.smsTemplateId}
                            selected={selectedIndex === index}
                            onClick={() => setSelectedIndex(index)}
                            hover
                          >
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {record.name}
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {smsFor[record.msgFor - 1]}
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {record.shop}
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textAlign: "center",
                              }}
                            >
                              {user?.claims.includes("smstemplate_edit") && (
                                <Tooltip title="Edit" placement="top" arrow>
                                  <IconButton
                                    color="primary"
                                    aria-label="edit"
                                    onClick={() => openInDrawer("edit", record)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {user?.claims.includes("smstemplate_add") && (
                                <Tooltip title="Delete" placement="top" arrow>
                                  <IconButton
                                    color="error"
                                    aria-label="delete"
                                    onClick={() =>
                                      setConfirmDialog({
                                        isOpen: true,
                                        title:
                                          "Are you sure to delete this record?",
                                        subTitle:
                                          "You can't undo this operation",
                                        onConfirm: () => {
                                          handleDelete(record.smsTemplateId);
                                        },
                                      })
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </TblContainer>
                </TableContainer>
                <TblPagination />
              </Paper>
            </>
          )}
        </>
      )}
    </>
  );
}

export default memo(SmsTemplatesList);
