import LockIcon from "@mui/icons-material/Lock";
import {
  Alert,
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Icon,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonControl from "../components/controls/ButtonControl";
import InputControl from "../components/controls/InputControl";
import { API_AUTHS } from "../context/ApiEndPoints";
import useAppContext from "../hooks/useAppContext";

const inputValues = {
  email: "",
  password: "",
};

export default function Login() {
  const { user, setUser } = useAppContext();
  const [values, setValues] = useState(inputValues);
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isForgetBtn, setIsForgetBtn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  useEffect(() => {
    navigate(user ? from : "/login", { replace: true });
    return () => {};
    // eslint-disable-next-line
  }, [user, navigate, from]);

  const validate = (input = values) => {
    let temp = { ...errors };
    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("password" in input) {
      temp.password = input.password ? "" : "Password is required.";
    }

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validate({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      localStorage.removeItem("X-Access-Token");
      setIsSaving(true);
      setLoginError(null);
      const abortCtrl = new AbortController();

      // POST request using fetch
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=UTF-8" },
        body: JSON.stringify(values),
      };

      // Fetch request with error handling
      try {
        const response = await fetch(`${API_AUTHS}/Login`, requestOptions, {
          signal: abortCtrl.signal,
        });

        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());
        // check for error response
        if (!response.ok) {
          if (response.status === 401) setIsForgetBtn(true);
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        localStorage.setItem("X-Access-Token", JSON.stringify(json));
        setUser(json);
      } catch (error) {
        setLoginError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
        abortCtrl.abort();
      }
    }
  };

  return (
    <Container maxWidth="sm" disableGutters>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          p={1}
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item xs textAlign="center">
            <Paper elevation={5}>
              <Box sx={{ p: 7, bgcolor: "divider" }}>
                <Icon className="wi-icon-2wrap" sx={{ fontSize: 90 }} />
              </Box>
              <Box sx={{ p: 5, minHeight: "59vh" }}>
                <Typography variant="h5" gutterBottom>
                  LOGIN
                </Typography>
                <Divider sx={{ mb: 1 }}>
                  <Avatar>
                    <LockIcon />
                  </Avatar>
                </Divider>
                {loginError}
                <InputControl
                  label="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                />
                <InputControl
                  type="password"
                  label="Password"
                  name="password"
                  value={values.password}
                  onChange={handleInputChange}
                  error={errors.password}
                />
                {!isSaving ? (
                  <ButtonControl type="submit" text="Login" />
                ) : (
                  <ButtonControl
                    text="...Processing"
                    isSaving={isSaving}
                    disabled
                  />
                )}

                {isForgetBtn && (
                  <Divider sx={{ m: 2 }}>
                    <ButtonControl
                      component={Link}
                      to="/reset-password"
                      text="Forget Password"
                      variant="text"
                      size="medium"
                    />
                  </Divider>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
