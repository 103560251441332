import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Avatar,
  Container,
  Divider,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonControl from "../components/controls/ButtonControl";
import InputControl from "../components/controls/InputControl";
import { API_AUTHS } from "../context/ApiEndPoints";
import useAppContext from "../hooks/useAppContext";

const inputValues = {
  email: "",
  userPin: "",
  password: "",
  confirmPassword: "",
};

export default function RestPassword() {
  const { user, setUser } = useAppContext();
  const [values, setValues] = useState(inputValues);
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  useEffect(() => {
    navigate(user ? from : "/reset-password", { replace: true });
    return () => {};
    // eslint-disable-next-line
  }, [user, navigate, from]);

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if (isEmail) {
      if ("userPin" in input) {
        temp.userPin = input.userPin ? "" : "User PIN is required.";
      }
      if ("password" in input) {
        temp.password =
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/.test(
            input.password
          )
            ? ""
            : "Minimum 8 characters, at least one upper case letter, one lower case letter, one number and one special character";

        temp.password = input.password
          ? temp.password
          : "Password is required.";
      }
      if ("confirmPassword" in input) {
        temp.confirmPassword =
          input.confirmPassword === values.password
            ? ""
            : "Password did not match";

        temp.confirmPassword = input.confirmPassword
          ? temp.confirmPassword
          : "Confirm Password is required.";
      }
    }

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validate({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      localStorage.removeItem("X-Access-Token");
      setIsSaving(true);
      setLoginError(null);
      const abortCtrl = new AbortController();

      // POST request using fetch
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json; charset=UTF-8" },
        body: JSON.stringify(values),
      };

      // Fetch request with error handling
      try {
        const response = await fetch(
          `${API_AUTHS}/ResetPassword`,
          requestOptions,
          {
            signal: abortCtrl.signal,
          }
        );

        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());
        // check for error response
        if (!response.ok) {
          if (response.status === 401) setIsEmail(true);
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        localStorage.setItem("X-Access-Token", JSON.stringify(json));
        setUser(json);
      } catch (error) {
        setLoginError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
        abortCtrl.abort();
      }
    }
  };

  return (
    <Container maxWidth="sm" disableGutters sx={{ p: 3 }}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "90vh" }}
        >
          <Grid item xs textAlign="center" sx={{ minHeight: "60vh" }}>
            <Typography variant="h5" gutterBottom>
              <Icon className="wi-icon-2wrap" sx={{ fontSize: 90 }} />
              <br />
              ACCOUNT
            </Typography>
            <Divider sx={{ mb: 1 }}>
              <Avatar>
                <AdminPanelSettingsIcon />
              </Avatar>
            </Divider>
            {loginError}
            {!isEmail ? (
              <InputControl
                label="Email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
              />
            ) : (
              <>
                <InputControl
                  label="User PIN"
                  name="userPin"
                  type="password"
                  value={values.userPin}
                  onChange={handleInputChange}
                  error={errors.userPin}
                />
                <InputControl
                  label="Password"
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleInputChange}
                  error={errors.password}
                />
                <InputControl
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  onChange={handleInputChange}
                  error={errors.confirmPassword}
                />
              </>
            )}
            {!isSaving ? (
              <ButtonControl type="submit" text="submit" />
            ) : (
              <ButtonControl
                text="...Processing"
                isSaving={isSaving}
                disabled
              />
            )}
            <Divider sx={{ m: 2 }}>
              <ButtonControl
                variant="text"
                color="error"
                size="medium"
                component={Link}
                to="/"
                startIcon={<ArrowBackIcon />}
                text="Back"
                aria-label="back to login"
              />
            </Divider>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
