import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import React, { memo } from "react";

function CheckboxControl(props) {
  const {
    label,
    name,
    value,
    onChange,
    margin,
    fullWidth = true,
    ...other
  } = props;

  const convertToDefEventPara = (name, value) => ({
    target: { name, value },
  });

  return (
    <FormControl margin={margin || "normal"} fullWidth={fullWidth} {...other}>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={value}
            onChange={(e) =>
              onChange(convertToDefEventPara(name, e.target.checked))
            }
          />
        }
        label={label}
      />
    </FormControl>
  );
}

export default memo(CheckboxControl);
