import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import React, { memo } from "react";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import useForm from "../../hooks/useForm";

const objRecord = { name: "" };

function RoleForm({ addOrEdit, selectedRecord, isSaving }) {
  const validate = (input = values) => {
    let temp = { ...errors };
    if ("name" in input)
      temp.name = input.name ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors, resetForm } = useForm(
    selectedRecord ? selectedRecord.identityRole : objRecord,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        {selectedRecord ? (
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Edit" secondary="User Role" />
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add New" secondary="User Role" />
            </ListItem>
          </List>
        )}
        <Divider />
        <Paper elevation={0} sx={{ p: 3 }}>
          <InputControl
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
          <ButtonControl
            type="submit"
            text={isSaving ? "Saving..." : "Save"}
            isSaving={isSaving}
          />
        </Paper>
      </Paper>
    </form>
  );
}

export default memo(RoleForm);
