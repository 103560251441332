import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import ButtonControl from "../../components/controls/ButtonControl";
import SelectControl from "../../components/controls/SelectControl";
import { API_CUSTOMERS, API_USERS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useForm from "../../hooks/useForm";

const objRecord = {
  csvFile: null,
  userId: "",
};

export default function UploadCustomer() {
  const { user } = useAppContext();
  const { setNotify } = useComponentContext();
  const [isSaving, setIsSaving] = useState(false);

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("userId" in input)
      temp.userId = input.userId ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    useForm(objRecord, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);
      //Create formData with image file
      const formData = new FormData();
      for (const [k, v] of Object.entries(values)) {
        formData.append(k, v);
      }

      // POST request using fetch
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: formData,
      };

      const results = await ApiFetch(
        `${API_CUSTOMERS}/Upload`,
        requestOptions,
        setNotify,
        "Customers Added Successfully!"
      );

      if (results) resetForm();
    }
    setIsSaving(false);
  };

  const handleCountryUpdate = async () => {
    // GET request using fetch
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
    };

    await ApiFetch(
      `${API_CUSTOMERS}/phone-number`,
      requestOptions,
      setNotify,
      "Customers Updated Successsfully"
    );
  };

  const [usersSelect, setUsersSelect] = useState();
  const fetchUsers = async () => {
    // GET Request using fetch
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
    };
    const results = await ApiFetch(API_USERS, requestOptions, setNotify);
    if (results) setUsersSelect(results);
  };

  useEffect(() => {
    (async () => await fetchUsers())();
    return () => {};
    // eslint-disable-next-line
  }, []);

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let csvFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setValues({ ...values, csvFile });
      };
      reader.readAsDataURL(csvFile);
    } else {
      setValues({ ...values, csvFile: null });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item component={Paper} xs={12} md={6}>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <UploadFileIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Upload CSV" secondary="Customers" />
              </ListItem>
            </List>
            <Divider />
            <Paper elevation={0} sx={{ p: 3 }}>
              <input
                type="file"
                id="color-file"
                name="csvFile"
                accept=".csv"
                onChange={showPreview}
                required
              />
              {usersSelect && (
                <>
                  <SelectControl
                    label="Assign Shop"
                    name="userId"
                    value={values.userId}
                    onChange={handleInputChange}
                    options={usersSelect}
                    error={errors.userId}
                    textIndex={4}
                  />

                  <ButtonControl
                    type="submit"
                    text="Save"
                    isSaving={isSaving}
                  />
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </form>
      <Divider sx={{ my: 3 }} />
      <ButtonControl
        text="Update Countries"
        isSaving={isSaving}
        onClick={() => handleCountryUpdate()}
      />
    </>
  );
}
