import {
  Card,
  CardMedia,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
} from "@mui/material";
import React, { memo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import ApiFetch from "../../components/ApiFetch";
import ContentDrawer from "../../components/ContentDrawer";
import InputControl from "../../components/controls/InputControl";
import { API_COLORS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import useTable from "../../hooks/useTable";
import ColorDetails from "./ColorDetails";
import ColorForm from "./ColorForm";

const columns = [
  { field: "colorId", label: "ID", width: 1 },
  {
    field: "ImageName",
    label: "Image",
    width: 1,
    disableSearch: true,
    disableSorting: true,
  },
  { field: "name", label: "Name", width: 2 },
  { field: "price", label: "Price", width: 1 },
  { field: "available", label: "Available", width: 1, disableSearch: true },
  { field: "colorFinish", label: "Finish", width: 1 },
  { field: "colorVinyl", label: "Vinyl Brand", width: 1 },
  { field: "createdDate", label: "Created On", width: 1 },
  { field: "modifiedDate", label: "Modified On", width: 1 },
  {
    field: "action",
    label: "Actions",
    width: 2,
    disableSorting: true,
    textCenter: true,
    isAction: true,
  },
];

function ColorsList() {
  const { user } = useAppContext();
  const { setNotify, setConfirmDialog } = useComponentContext();
  const [contentDrawer, setContentDrawer] = useState(false);
  const [IsForm, setIsForm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isSaving, setIsSaving] = useState(false);
  const { records, setRecords, isLoading, fetchError } =
    useFetchList(API_COLORS);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, columns, filterFn);

  const inputs = Array.from(document.querySelectorAll(".srchInput input"));
  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        var x;
        for (const { name, value } of inputs) {
          if (value) {
            x = items.filter((item) =>
              item[name]?.toString().toLowerCase().includes(value.toLowerCase())
            );
            items = x;
          }
        }
        return items;
      },
    });
  };

  const openDrawer = (action, record = null) => {
    setSelectedRecord(record ? { ...record } : null);
    setIsForm(action === "add" || action === "edit" ? true : false);
    setSelectedIndex(action === "add" ? -1 : selectedIndex);
    setContentDrawer(true);
  };

  const addOrEdit = async (record, resetForm) => {
    setIsSaving(true);

    //Create formData with image file
    const formData = new FormData();
    for (const [k, v] of Object.entries(record)) {
      formData.append(k, v);
    }

    if (!record.colorId) {
      // POST request using fetch
      const requestOptions = {
        method: "POST",
        headers: { Authorization: "Bearer " + user.token },
        body: formData,
      };
      const results = await ApiFetch(
        API_COLORS,
        requestOptions,
        setNotify,
        "Color added successfully!"
      );

      if (results) {
        setRecords([...records, results]);
        setSelectedIndex(records.length);
        resetForm();
        document.getElementById("color-file").value = null;
      }
    } else {
      // PUT Request using fetch
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: "Bearer " + user.token },
        body: formData,
      };
      const results = await ApiFetch(
        `${API_COLORS}/${record.colorId}`,
        requestOptions,
        setNotify,
        "Color updated successfully!"
      );

      if (results) {
        const index = records.findIndex((e) => e.colorId === record.colorId);
        records[index] = results;
        setSelectedRecord({ ...results });
      }
    }
    setIsSaving(false);
  };

  const handleDelete = async (colorId) => {
    setConfirmDialog({ isOpen: false });

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_COLORS}/${colorId}`,
      requestOptions,
      setNotify,
      "Deleted successfully!"
    );

    if (results === "DELETE") {
      setSelectedIndex(-1);
      setSelectedRecord(null);
      setRecords((records) => records.filter((e) => e.colorId !== colorId));
    }
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <>
          <Toolbar component={Paper} square disableGutters sx={{ mb: 0.1 }}>
            <Grid container alignItems="center">
              {columns.map((column) =>
                !column.isAction ? (
                  <Grid item xs={column.width} key={column.field} sx={{ p: 2 }}>
                    {!column.disableSearch && (
                      <InputControl
                        variant="standard"
                        label={`Search ${column.label}`}
                        name={column.field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleSearch}
                        className="srchInput"
                        margin="none"
                        fullWidth={false}
                      />
                    )}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={column.width}
                    key={column.field}
                    sx={{ p: 2 }}
                    textAlign="right"
                  >
                    {user?.claims.includes("color_add") && (
                      <Tooltip title="Add New" placement="top" arrow>
                        <Fab
                          color="primary"
                          aria-label="add"
                          onClick={() => {
                            openDrawer("add");
                            setContentDrawer(true);
                          }}
                        >
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </Toolbar>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "69vh" }}>
              <TblContainer>
                {records.length === 0 && <caption>No Record Found!</caption>}
                <TblHead />
                <TableBody>
                  {records &&
                    recordsAfterPagingAndSorting().map((record, index) => (
                      <TableRow
                        key={index}
                        selected={selectedIndex === index}
                        onClick={() => {
                          setSelectedIndex(index);
                        }}
                        hover
                      >
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.colorId}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            py: 0,
                          }}
                        >
                          <Card sx={{ maxWidth: 64 }}>
                            <CardMedia
                              height="100%"
                              component="img"
                              image={record.imageName}
                              alt={record.imageName}
                              loading="lazy"
                            />
                          </Card>
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.price
                            ? record.price.toLocaleString("lookup")
                            : null}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.available ? (
                            <CheckIcon color="success" />
                          ) : (
                            <CloseIcon color="error" />
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.colorFinish}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.colorVinyl}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.createdDate}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.modifiedDate}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip title="Details" placement="top" arrow>
                            <IconButton
                              aria-label="details"
                              onClick={() => {
                                openDrawer("details", record);
                                setContentDrawer(true);
                              }}
                            >
                              <ArticleIcon />
                            </IconButton>
                          </Tooltip>
                          {user?.claims.includes("color_edit") && (
                            <Tooltip title="Edit" placement="top" arrow>
                              <IconButton
                                color="primary"
                                aria-label="edit"
                                onClick={() => {
                                  openDrawer("edit", record);
                                  setContentDrawer(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {user?.claims.includes("color_delete") && (
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="error"
                                aria-label="delete"
                                onClick={() =>
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      handleDelete(record.colorId);
                                    },
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </TblContainer>
            </TableContainer>
            <TblPagination />
          </Paper>
        </>
      )}

      {/* ContentDrawer */}
      <ContentDrawer
        contentDrawer={contentDrawer}
        setContentDrawer={setContentDrawer}
      >
        {IsForm ? (
          <ColorForm
            addOrEdit={addOrEdit}
            selectedRecord={selectedRecord}
            openDrawer={openDrawer}
            isSaving={isSaving}
          />
        ) : (
          <ColorDetails
            selectedRecord={selectedRecord}
            openDrawer={openDrawer}
          />
        )}
      </ContentDrawer>
    </>
  );
}

export default memo(ColorsList);
