import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Switch,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { API_CLAIMS } from "../../context/ApiEndPoints";
import useFetchList from "../../hooks/useFetchList";

function Claims({ selectedRecord, handleClaimChange, isSaving }) {
  const [roleClaims, setRoleClaims] = useState();
  const { records, isLoading, fetchError } = useFetchList(API_CLAIMS);

  useEffect(() => {
    const activeClaims =
      records &&
      records.map((a) =>
        selectedRecord.identityRoleClaims.some(
          (b) => a.claimType === b.type && a.claimValue === b.value
        )
      );

    setRoleClaims(activeClaims);

    return () => {};
    // eslint-disable-next-line
  }, [records]);

  const handleSwitch = (index) => {
    roleClaims[index] = !roleClaims[index];
  };

  return (
    <Paper>
      <Divider light />
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <EditIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Edit" secondary="Access Rights" />
        </ListItem>
      </List>
      <Divider />
      <Paper elevation={0} sx={{ p: 3 }}>
        {fetchError ||
          (isLoading && <CircularProgress size={20} color="inherit" />)}
        <List>
          {records &&
            records.map((item, index) => (
              <ListItem key={index} disablePadding divider>
                <ListItemIcon>
                  <Switch
                    edge="start"
                    onChange={() => {
                      handleClaimChange(selectedRecord.identityRole.name, item);
                      handleSwitch(index);
                    }}
                    checked={roleClaims ? roleClaims[index] : false}
                    disabled={isSaving}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${item.claimType} : ${item.claimValue}`}
                  sx={{ textTransform: "capitalize" }}
                />
              </ListItem>
            ))}
        </List>
      </Paper>
    </Paper>
  );
}

export default memo(Claims);
