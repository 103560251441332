import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  Slide,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ColorSelect from "../../components/ColorSelect";
import FilesUploader from "../../components/FilesUploader";
import ServiceCard from "../../components/ServiceCard";
import ButtonControl from "../../components/controls/ButtonControl";
import CheckboxControl from "../../components/controls/CheckboxControl";
import InputControl from "../../components/controls/InputControl";
import RadioGroupControl from "../../components/controls/RadioGroupControl";
import { API_QUOTES } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";

const objRecord = {
  industry: "Aeronautics",
};

function EditAeronautics() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAppContext();

  const [values, setValues] = useState({ ...objRecord });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [service, setService] = useState("Service");
  const [serviceName, setServiceName] = useState("_Service");
  const [fetchError, setFetchError] = useState(false);

  const validate = (input = values) => {
    let temp = { ...errors };

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e, switchName = null) => {
    const { name, value } = e.target;
    values[name] = value;
    if (switchName) values[switchName] = true;

    setValues({ ...values });
    validate({ [name]: value });
  };

  const handleServiceChange = (e) => {
    const { value } = e.target;
    const a = value.replace(/\b\w/g, (c) => c.toUpperCase());
    setServiceName("_" + a);
    const b = a.replace(/\s/g, "");
    setService(b);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      const data = {};
      const quoteServices = {};
      const quoteItems = {};
      for (const [key, val] of Object.entries(values)) {
        if (key.startsWith("_") && val) {
          const k = key.slice(1);
          quoteServices[k] = val;
          continue;
        }

        if (/^[A-Z]/.test(key[0]) && val) {
          quoteItems[key] = typeof val === "boolean" ? "Yes" : val;
          continue;
        }

        if (/^[a-z]/.test(key[0])) {
          data[key] = val;
          continue;
        }
      }

      for (const [key] of Object.entries(data)) {
        if (key.endsWith("_") || key.startsWith("_")) {
          delete data[key];
        }
      }

      let record = data;
      record.quoteServices = JSON.stringify(quoteServices);
      record.quoteItems = JSON.stringify(quoteItems);

      // Create formData
      const formData = new FormData();
      // Add quote values to formData
      for (const [key, val] of Object.entries(record)) {
        formData.append(key, val);
      }

      // Add exteriorFile values to formData
      if (values.exteriorDesignWrapFile) {
        for (const val of Object.values(values.exteriorDesignWrapFile)) {
          formData.append("exteriorDesignWrapFile", val);
        }
      }

      // POST request using fetch
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: "Bearer " + user.token },
        body: formData,
      };

      try {
        const response = await fetch(
          `${API_QUOTES}/QuoteItems/${params.quoteId}`,
          requestOptions
        );
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (json && json.message) || response.status;
          throw Error(error);
        }

        setValues({ ...objRecord });
        setErrors({});
        navigate("/job-details/" + json.quoteId, { replace: true });
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Slide direction="right" in={true}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "ExteriorStripingColor",
                    "ExteriorStripingColorImg",
                    "ExteriorStripingParts",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_ExteriorStriping"
                  icon="wi-aeronautics-exterior"
                  title="Exterior Striping"
                >
                  <ColorSelect
                    aria-label="exterior striping Color"
                    label="Striping Color"
                    name="ExteriorStripingColor"
                    values={values}
                    imgSrc="ExteriorStripingColorImg"
                    onChange={(e) => handleInputChange(e, "_ExteriorStriping")}
                  />
                  <InputControl
                    label="Which Part(s)"
                    name="ExteriorStripingParts"
                    onChange={(e) => handleInputChange(e, "_ExteriorStriping")}
                    multiline
                    rows={3}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "ExteriorDesignWrapOption",
                    "exteriorDesignWrapFile",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_ExteriorDesignWrap"
                  icon="wi-aeronautics-exterior"
                  title="Exterior Design Wrap"
                >
                  <RadioGroupControl
                    aria-label="exterior design wrap"
                    name="ExteriorDesignWrapOption"
                    onChange={(e) =>
                      handleInputChange(e, "_ExteriorDesignWrap")
                    }
                    options={[
                      {
                        label: "Design Request",
                        value: "Design Request",
                      },
                      {
                        label: "I Have a Design",
                        value: "I Have a Design",
                      },
                    ]}
                  />
                  <FormControl margin="normal" fullWidth>
                    <InputLabel>Upload Design / Example</InputLabel>
                    <FilesUploader
                      inputName="exteriorDesignWrapFile"
                      accept="image/*"
                      values={values}
                      setValues={setValues}
                    />
                  </FormControl>
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "ExteriorAccentWrapColor",
                    "ExteriorAccentWrapColorImg",
                    "ExteriorAccentWrapParts",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_ExteriorAccentWrap"
                  icon="wi-aeronautics-exterior"
                  title="Exterior Accent Wrap"
                >
                  <ColorSelect
                    aria-label="accent wrap Color"
                    label="Accent Wrap Color"
                    name="ExteriorAccentWrapColor"
                    values={values}
                    imgSrc="ExteriorAccentWrapColorImg"
                    onChange={(e) =>
                      handleInputChange(e, "_ExteriorAccentWrap")
                    }
                  />
                  <InputControl
                    label="Which Part(s)"
                    name="ExteriorAccentWrapParts"
                    onChange={(e) =>
                      handleInputChange(e, "_ExteriorAccentWrap")
                    }
                    multiline
                    rows={3}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "InteriorWrapColor",
                    "InteriorWrapColorImg",
                    "InteriorWrapParts",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_InteriorWrap"
                  icon="wi-aeronautics-interior"
                  title="Interior Wrap"
                >
                  <ColorSelect
                    aria-label="color"
                    label="Color"
                    name="InteriorWrapColor"
                    values={values}
                    imgSrc="InteriorWrapColorImg"
                    onChange={(e) => handleInputChange(e, "_InteriorWrap")}
                  />
                  <InputControl
                    label="Which Part(s)"
                    name="InteriorWrapParts"
                    onChange={(e) => handleInputChange(e, "_InteriorWrap")}
                    multiline
                    rows={3}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["InteriorWindowTintOption"]}
                  values={values}
                  setValues={setValues}
                  switchName="_InteriorWindowTint"
                  icon="wi-aeronautics-interior"
                  title="Interior Window Tint"
                >
                  <CheckboxControl
                    label="Window Tint"
                    name="InteriorWindowTintOption"
                    onChange={(e) =>
                      handleInputChange(e, "_InteriorWindowTint")
                    }
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["OtherDetails"]}
                  values={values}
                  setValues={setValues}
                  switchName="_Other"
                  icon="wi-other"
                  title="Other"
                >
                  <InputControl
                    label="Please Specify"
                    name="OtherDetails"
                    multiline
                    rows={3}
                    onChange={(e) => handleInputChange(e, "_Other")}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[service + "Title", service + "Details"]}
                  values={values}
                  setValues={setValues}
                  switchName={serviceName.replace(/\s/g, "")}
                  icon="wi-other"
                  title={serviceName.substring(1)}
                >
                  <InputControl
                    label="Title"
                    name={service + "Title"}
                    onChange={(e) =>
                      handleServiceChange(e, serviceName.replace(/\s/g, ""))
                    }
                  />
                  <InputControl
                    label="Description"
                    name={service + "Details"}
                    multiline
                    rows={3}
                    onChange={(e) =>
                      handleInputChange(e, serviceName.replace(/\s/g, ""))
                    }
                  />
                </ServiceCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} textAlign="center">
            <Fab
              component={Link}
              to={`/job-details/${params.quoteId}`}
              aria-label="go back"
              sx={{ mb: 3 }}
            >
              <ArrowBackIcon />
            </Fab>
            <Typography color="text.secondary">
              Select services form left and click CONFIRM once done
            </Typography>
            <Divider variant="middle" sx={{ my: 2 }}>
              {fetchError}
            </Divider>
            {Object.keys(values).some(
              (k) => /^[A-Z]/.test(k[0]) && values[k]
            ) ? (
              <ButtonControl type="submit" text="Submit" isSaving={isSaving} />
            ) : (
              <ButtonControl text="Submit" disabled />
            )}
          </Grid>
        </Grid>
      </Slide>
    </form>
  );
}

export default memo(EditAeronautics);
