import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import React, { memo } from "react";

function ButtonControl(props) {
  const { variant, text, size, color, margin, onClick, isSaving, ...other } =
    props;
  return (
    <Button
      variant={variant || "contained"}
      color={color || "primary"}
      size={size || "large"}
      onClick={onClick}
      disabled={isSaving}
      {...other}
    >
      {isSaving && (
        <CircularProgress
          size={20}
          color="inherit"
          sx={{ mr: 1 }}
          disableShrink
        />
      )}
      {text}
    </Button>
  );
}

export default memo(ButtonControl);
