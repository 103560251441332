import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import PersonIcon from "@mui/icons-material/Person";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SendIcon from "@mui/icons-material/Send";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import muiLink from "@mui/material/Link";
import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import ApiFetch from "../../components/ApiFetch";
import PopupDialog from "../../components/PopupDialog";
import ButtonControl from "../../components/controls/ButtonControl";
import { API_MEDIAS, API_QUOTES, BASEURL } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import {
  currencyFormat,
  dateStamp,
  getRandomColor,
} from "../../scripts/Scripts";
import QuoteForm from "./QuoteForm";

function QuoteDetails(props) {
  const { user, setUser } = useAppContext();
  const { setNotify, setConfirmDialog } = useComponentContext();
  const [popupDialog, setPopupDialog] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [isToggleEdit, setIsToggleEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { records, setRecords, isLoading, fetchError } = useFetchList(
    `${API_QUOTES}/${props.quoteId}`
  );
  const quoteInfo = records && JSON.parse(records.quoteInfo);

  const handleEdit = async (values) => {
    setIsSaving(true);

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };
    const results = await ApiFetch(
      `${API_QUOTES}/${records.quoteId}`,
      requestOptions,
      setNotify,
      "Quote Updated Successfully!"
    );

    if (results) {
      setRecords(results);
      setIsToggleEdit(false);
    }

    setIsSaving(false);
  };

  const handleFiles = async (record) => {
    setIsSaving(true);

    //Create formData with file
    const formData = new FormData();
    for (const [k, v] of Object.entries(record)) {
      formData.append(k, v);
    }

    // Add stripingFiles values to formData
    if (record.quoteFiles) {
      for (const val of Object.values(record.quoteFiles)) {
        formData.append("quoteFiles", val);
      }
    }

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + user.token,
      },
      body: formData,
    };
    const results = await ApiFetch(
      `${API_QUOTES}/Files/${record.quoteId}`,
      requestOptions,
      setNotify,
      "Files Uploaded Successfully!"
    );

    if (results) {
      setRecords(results);
      setIsToggleEdit(false);
    }

    setIsSaving(false);
  };

  const handleItemDelete = async (item) => {
    setConfirmDialog({ isOpen: false });
    setIsSaving(true);

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    };
    const results = await ApiFetch(
      `${API_QUOTES}/QuoteItems/${item.quoteId}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results) {
      setRecords(results);
    }
    setIsSaving(false);
  };

  const handleFileDelete = async (e, mediaId) => {
    setConfirmDialog({ isOpen: false });
    setIsSaving(true);
    let current = e.target.closest("div.MuiCard-root");

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_MEDIAS}/${mediaId}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      const index = records.medias.findIndex((el) => el.mediaId === mediaId);
      delete records.medias[index];
      setRecords(records);
      current.parentNode.removeChild(current);
    }
    setIsSaving(false);
  };

  const handleSendEmail = async () => {
    setIsSaving(true);
    setConfirmDialog({ isOpen: false });

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
    };
    const results = await ApiFetch(
      `${API_QUOTES}/Send/${records.quoteId}`,
      requestOptions,
      setNotify,
      "Quote Email Sent Successfully!"
    );

    if (results) {
      setRecords(results);
    }
    setIsSaving(false);
  };

  const handleLost = async () => {
    setIsSaving(true);
    setConfirmDialog({ isOpen: false });

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
    };
    const results = await ApiFetch(
      `${API_QUOTES}/Lost/${records.quoteId}`,
      requestOptions,
      setNotify,
      "Set to Lost Successfully!"
    );

    if (results) {
      setRecords({ ...records, status: results.status });
    }
    setIsSaving(false);
  };

  const alertStatus = (status) => {
    switch (status) {
      case "Request":
      case "Todo":
        return "warning";

      case "Sent":
      case "Waiting":
        return "info";

      case "Approved":
        return "success";

      case "Lost":
        return "error";

      default:
        return "info";
    }
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 3 }} />
        ))}
      {records &&
        (!isToggleEdit ? (
          <Zoom in={true} style={{ transitionDelay: "100ms" }}>
            <Paper>
              <List>
                <ListItem
                  secondaryAction={
                    <Tooltip title="Edit" placement="bottom" arrow>
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        onClick={() => setIsToggleEdit(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <ArticleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Details" secondary="Quote" />
                </ListItem>
              </List>
              <Divider />
              <List
                sx={{ px: 3 }}
                subheader={
                  <ListSubheader
                    component="div"
                    sx={{ color: "text.primary", fontSize: "large" }}
                  >
                    {records.title}
                  </ListSubheader>
                }
              >
                {records.title && (
                  <Divider variant="middle" component="li" light />
                )}
                <ListItem
                  secondaryAction={
                    <ListItemText
                      sx={{ textAlign: "center" }}
                      primary={records.industry}
                      secondary={
                        <Box component="span">
                          {!records.jobCreatedDate && (
                            <Chip
                              component={Link}
                              to={`/approve-quote/${records.quoteId}`}
                              label="Approve"
                              color="success"
                              variant="outlined"
                              icon={<TaskAltIcon />}
                              clickable
                              onClick={() => setUser({ ...user, onHold: true })}
                            />
                          )}
                        </Box>
                      }
                    />
                  }
                >
                  <ListItemText
                    primary={`ID: ${records.quoteId}`}
                    secondary={dateStamp(
                      records.modifiedDate
                        ? records.modifiedDate
                        : records.createdDate
                    )}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Alert
                        severity={
                          !records.jobCreatedDate
                            ? alertStatus(records.status)
                            : "success"
                        }
                        action={
                          <Chip
                            icon={<PersonIcon />}
                            label={records.customer.email}
                            color={
                              !records.jobCreatedDate
                                ? alertStatus(records.status)
                                : "success"
                            }
                            variant="outlined"
                            sx={{ borderWidth: 0 }}
                          />
                        }
                      >
                        {!records.jobCreatedDate ? records.status : "Approved"}
                      </Alert>
                    }
                  />
                </ListItem>
                <ListItem>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Service</TableCell>
                          <TableCell align="right">Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records.quoteItems &&
                          records.quoteItems.map((item, i) =>
                            item.isGroup ? (
                              <TableRow
                                key={i}
                                sx={{ backgroundColor: "background.default" }}
                              >
                                <TableCell variant="head">
                                  {item.serviceName}
                                </TableCell>
                                <TableCell variant="head" align="right">
                                  {currencyFormat(item.price, records.currency)}
                                </TableCell>
                              </TableRow>
                            ) : (
                              !item.serviceValue?.startsWith("https:") && (
                                <TableRow key={i}>
                                  <TableCell colSpan={2} sx={{ px: 4 }}>
                                    <Typography sx={{ whiteSpace: "pre-line" }}>
                                      {item.serviceValue}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {item.serviceName}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )
                            )
                          )}

                        <TableRow>
                          <TableCell align="right" colSpan={2} variant="head">
                            {records.isTotal &&
                              `Total: ${currencyFormat(
                                records.totalPrice,
                                records.currency
                              )}`}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ListItem>
                {records.notes && (
                  <ListItem>
                    <ListItemText
                      primary={records.notes}
                      secondary="Extra Info : (notes)"
                      sx={{ whiteSpace: "pre-line" }}
                    />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText primary={records.user} secondary="User" />
                </ListItem>
                <ListItem>
                  <ListItemText primary={records.shop} secondary="Shop" />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={records.createdDate}
                    secondary="Created On Date"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={records.modifiedDate}
                    secondary="Modified On Date"
                  />
                </ListItem>
                {Object.keys(quoteInfo).length > 0 &&
                  quoteInfo.constructor === Object && (
                    <>
                      <Divider component="li" variant="middle" />
                      {Object.keys(quoteInfo).map((key) => (
                        <ListItem key={key}>
                          <ListItemText
                            sx={{ whiteSpace: "pre-line" }}
                            primary={quoteInfo[key]}
                            secondary={key}
                          />
                        </ListItem>
                      ))}
                    </>
                  )}
                <Divider component="li" variant="middle" />
                <ListItem>
                  <Grid container spacing={1}>
                    {records.signature && (
                      <Grid item>
                        <Card variant="outlined">
                          <CardMedia
                            component="img"
                            height="168"
                            image={records.signature}
                            alt="signature"
                            sx={{ p: 1, objectFit: "contain" }}
                          />
                          <CardContent sx={{ p: 1 }}>
                            <Typography
                              variant="caption"
                              component="p"
                              textAlign="center"
                              noWrap
                            >
                              Signature
                            </Typography>
                            <Divider light />
                          </CardContent>
                          <Divider light />
                        </Card>
                      </Grid>
                    )}
                    {records.quoteItems &&
                      records.quoteItems.map(
                        (item, i) =>
                          item.serviceValue?.startsWith("https:") && (
                            <Grid item key={i}>
                              <Card>
                                <CardActionArea
                                  onClick={() => {
                                    setPopupContent(item.serviceValue);
                                    setPopupDialog(true);
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    height="168"
                                    image={item.serviceValue}
                                    alt={item.serviceName}
                                    loading="lazy"
                                  />
                                  <CardContent sx={{ p: 1 }}>
                                    <Typography
                                      variant="caption"
                                      component="p"
                                      sx={{ textTransform: "capitalize" }}
                                      textAlign="center"
                                      noWrap
                                    >
                                      {item.serviceName}
                                    </Typography>
                                    <Divider light />
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          )
                      )}
                    {records.quoteImages &&
                      records.quoteImages.map((img, i) => (
                        <Grid item key={i}>
                          <Card>
                            <CardActionArea
                              onClick={() => {
                                setPopupContent(
                                  `${BASEURL}/Img/${img.imageName}`
                                );
                                setPopupDialog(true);
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="168"
                                image={`${BASEURL}/Img/Thumb/${img.imageName}`}
                                alt={img.imageName}
                                loading="lazy"
                              />
                              <CardContent
                                sx={{
                                  p: 1,
                                  backgroundColor: getRandomColor(
                                    img.imageCategory
                                  ),
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  component="p"
                                  sx={{ textTransform: "capitalize" }}
                                  textAlign="center"
                                  noWrap
                                >
                                  {img.imageCategory}
                                </Typography>
                                <Divider light />
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container spacing={1}>
                    {records.medias &&
                      records.medias.map((img, i) => (
                        <Grid item key={i}>
                          <Card>
                            <CardHeader
                              action={
                                !img.isRequired ? (
                                  <Tooltip title="Delete" placement="top" arrow>
                                    <IconButton
                                      color="secondary"
                                      onClick={(e) =>
                                        setConfirmDialog({
                                          isOpen: true,
                                          title:
                                            "Are you sure to delete this file?",
                                          subTitle:
                                            "You can't undo this operation",
                                          onConfirm: () => {
                                            handleFileDelete(e, img.mediaId);
                                          },
                                        })
                                      }
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <RequestQuoteIcon />
                                )
                              }
                              title={img.mime}
                              titleTypographyProps={{
                                fontSize: "small",
                              }}
                            />
                            {img.mime.includes("image") ? (
                              <>
                                <CardActionArea
                                  onClick={() => {
                                    setPopupContent(
                                      `${BASEURL}/img/${img.fileName}`
                                    );
                                    setPopupDialog(true);
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    height="168"
                                    image={`${BASEURL}/img/Thumb/${img.fileName}`}
                                    alt={img.fileName}
                                    loading="lazy"
                                  />
                                </CardActionArea>
                                <CardContent sx={{ textAlign: "center" }}>
                                  <Typography variant="subtitle2" p={1}>
                                    ...
                                  </Typography>
                                  <Divider light />
                                </CardContent>
                              </>
                            ) : img.mime === "application/pdf" ? (
                              <>
                                <CardContent sx={{ textAlign: "center" }}>
                                  <embed
                                    src={`${BASEURL}/img/${img.fileName}#view=FitV`}
                                    type="application/pdf"
                                    width="100%"
                                    height="168"
                                    loading="lazy"
                                  />
                                  <ButtonControl
                                    component={muiLink}
                                    href={`${BASEURL}/img/${img.fileName}`}
                                    text="View"
                                    variant="text"
                                    size="medium"
                                    startIcon={<DownloadRoundedIcon />}
                                    target="_blank"
                                    sx={{ textTransform: "lowercase" }}
                                  />
                                  <Divider light />
                                </CardContent>
                              </>
                            ) : (
                              <>
                                <CardMedia
                                  component="img"
                                  height="168"
                                  image="img/file.png"
                                  alt={img.fileName}
                                  loading="lazy"
                                />
                                <CardContent sx={{ textAlign: "center" }}>
                                  <ButtonControl
                                    component={muiLink}
                                    href={`${BASEURL}/img/${img.fileName}`}
                                    text="Download"
                                    variant="text"
                                    size="medium"
                                    startIcon={<DownloadRoundedIcon />}
                                    download={img.fileName}
                                    sx={{ textTransform: "lowercase" }}
                                  />
                                  <Divider light />
                                </CardContent>
                              </>
                            )}
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                </ListItem>
              </List>
              {!records.jobCreatedDate && (
                <>
                  <Divider />
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ p: 3 }}
                  >
                    <ButtonControl
                      aria-label="send email"
                      text="Send Email"
                      isSaving={isSaving}
                      onClick={() =>
                        setConfirmDialog({
                          isOpen: true,
                          title: `QUOTE ID: ${
                            records.quoteId
                          } \n Are you sure to send this quote email to customer? \n Total: ${currencyFormat(
                            records.totalPrice,
                            records.currency
                          )}`,
                          subTitle: "You can't undo this operation",
                          onConfirm: handleSendEmail,
                        })
                      }
                      endIcon={<SendIcon />}
                    />
                    {records.status !== "Lost" && (
                      <ButtonControl
                        aria-label="lost"
                        variant="text"
                        text="Set to lost"
                        color="error"
                        isSaving={isSaving}
                        onClick={() =>
                          setConfirmDialog({
                            isOpen: true,
                            title: `QUOTE ID: ${records.quoteId} \n Are you sure to change status to lost?`,
                            onConfirm: handleLost,
                          })
                        }
                        endIcon={<ErrorIcon />}
                      />
                    )}
                  </Stack>
                </>
              )}
            </Paper>
          </Zoom>
        ) : (
          <QuoteForm
            records={records}
            handleEdit={handleEdit}
            handleFiles={handleFiles}
            handleItemDelete={handleItemDelete}
            setIsToggleEdit={setIsToggleEdit}
            setConfirmDialog={setConfirmDialog}
            isSaving={isSaving}
          />
        ))}
      <PopupDialog popupDialog={popupDialog} setPopupDialog={setPopupDialog}>
        <img src={popupContent} alt={popupContent} />
      </PopupDialog>
    </>
  );
}

export default memo(QuoteDetails);
