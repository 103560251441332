import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { Link, useParams } from "react-router-dom";
import { API_BLOGS } from "../../context/ApiEndPoints";
import useFetchList from "../../hooks/useFetchList";
import BlogsList from "./BlogsList";

function BlogDetails() {
  const params = useParams();

  const { records, isLoading, fetchError } = useFetchList(
    `${API_BLOGS}/${params.blogId}`
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={9}>
        {fetchError ||
          (isLoading && (
            <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
          ))}
        {records && (
          <Paper sx={{ p: 2 }}>
            <List>
              <ListItem
                secondaryAction={
                  <Tooltip title="Go Back" placement="bottom" arrow>
                    <IconButton
                      component={Link}
                      color="primary"
                      aria-label="details"
                      to="/dashboard"
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                }
              >
                <ListItemText
                  primary={records.title}
                  secondary={
                    records.modifiedDate
                      ? records.modifiedDate
                      : records.createdDate
                  }
                />
              </ListItem>
            </List>
            <Divider light />
            <Typography dangerouslySetInnerHTML={{ __html: records.post }} />
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} md={3}>
        <BlogsList actions={false} />
      </Grid>
    </Grid>
  );
}

export default memo(BlogDetails);
