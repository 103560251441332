import TextField from "@mui/material/TextField";
import React, { memo } from "react";

function InputControl(props) {
  const {
    variant,
    label,
    name,
    value,
    onChange,
    helperText,
    error = null,
    margin,
    fullWidth = true,
    ...other
  } = props;
  return (
    <TextField
      variant={variant || "outlined"}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onChange}
      margin={margin || "normal"}
      fullWidth={fullWidth}
      helperText={helperText || ""}
      {...(error && { error: true, helperText: error })}
      {...other}
    />
  );
}

export default memo(InputControl);
