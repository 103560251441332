import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  Slide,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ColorSelect from "../../components/ColorSelect";
import FilesUploader from "../../components/FilesUploader";
import ServiceCard from "../../components/ServiceCard";
import ButtonControl from "../../components/controls/ButtonControl";
import CheckboxControl from "../../components/controls/CheckboxControl";
import InputControl from "../../components/controls/InputControl";
import RadioGroupControl from "../../components/controls/RadioGroupControl";
import { API_QUOTES } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";

const objRecord = {
  industry: "Automotive",
};

function EditAutomotive() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAppContext();
  const [values, setValues] = useState({ ...objRecord });
  const [errors, setErrors] = useState({});
  const [service, setService] = useState("Service");
  const [serviceName, setServiceName] = useState("_Service");
  const [isSaving, setIsSaving] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const validate = (input = values) => {
    let temp = { ...errors };

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e, switchName = null) => {
    const { name, value } = e.target;
    values[name] = value;
    if (switchName) values[switchName] = true;

    setValues({ ...values });
    validate({ [name]: value });
  };

  const handleServiceChange = (e) => {
    const { value } = e.target;
    const a = value.replace(/\b\w/g, (c) => c.toUpperCase());
    setServiceName("_" + a);
    const b = a.replace(/\s/g, "");
    setService(b);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      const data = {};
      const quoteServices = {};
      const quoteItems = {};
      for (const [key, val] of Object.entries(values)) {
        if (key.startsWith("_") && val) {
          const k = key.slice(1);
          quoteServices[k] = val;
          continue;
        }

        if (/^[A-Z]/.test(key[0]) && val) {
          quoteItems[key] = typeof val === "boolean" ? "Yes" : val;
          continue;
        }

        if (/^[a-z]/.test(key[0])) {
          data[key] = val;
          continue;
        }
      }

      for (const [key] of Object.entries(data)) {
        if (key.endsWith("_") || key.startsWith("_")) {
          delete data[key];
        }
      }

      let record = { ...data };
      record.quoteServices = JSON.stringify(quoteServices);
      record.quoteItems = JSON.stringify(quoteItems);

      // Create formData
      const formData = new FormData();
      // Add quote values to formData
      for (const [key, val] of Object.entries(record)) {
        formData.append(key, val);
      }

      // Add stripingFiles values to formData
      if (values.stripingFile) {
        for (const val of Object.values(values.stripingFile)) {
          formData.append("stripingFile", val);
        }
      }

      // Add vehicleFiles values to formData
      if (values.designWrapFile) {
        for (const val of Object.values(values.designWrapFile)) {
          formData.append("designWrapFile", val);
        }
      }

      // console.log(record);
      // setIsSaving(false);
      // return;

      // PUT request using fetch
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: "Bearer " + user.token },
        body: formData,
      };

      try {
        const response = await fetch(
          `${API_QUOTES}/QuoteItems/${params.quoteId}`,
          requestOptions
        );
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (json && json.message) || response.status;
          throw Error(error);
        }

        setValues({ ...objRecord });
        setErrors({});
        navigate("/job-details/" + json.quoteId, { replace: true });
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Slide direction="left" in={true}>
        <Grid container p={1}>
          <Grid item xs={12} md={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "CarwrapOption",
                    "CarwrapColor",
                    "CarwrapColorImg",
                    "CarwrapParts",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_Carwrap"
                  icon="wi-carwrap"
                  title="Carwrap"
                >
                  <RadioGroupControl
                    aria-label="Carwrap Option"
                    name="CarwrapOption"
                    onChange={(e) => handleInputChange(e, "_Carwrap")}
                    options={[
                      {
                        label: "Full Body Color Change",
                        value: "Full Body Color Change",
                      },
                      {
                        label: "Partial Color Change",
                        value: "Partial Color Change",
                      },
                    ]}
                  />
                  {values.CarwrapOption && (
                    <ColorSelect
                      aria-label="Carwrap Color"
                      label="Wrap Color"
                      name="CarwrapColor"
                      values={values}
                      imgSrc="CarwrapColorImg"
                      onChange={(e) => handleInputChange(e, "_Carwrap")}
                    />
                  )}

                  {values.CarwrapOption === "Partial Color Change" && (
                    <InputControl
                      aria-label="Carwrap"
                      label="Which Part(s)"
                      name="CarwrapParts"
                      onChange={(e) => handleInputChange(e, "_Carwrap")}
                      multiline
                      rows={3}
                    />
                  )}
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "PaintProtectionOption",
                    "PaintProtectionMaterial",
                    "PaintProtectionParts",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_PaintProtection"
                  icon="wi-paint-protection"
                  title="Paint Protection"
                >
                  <RadioGroupControl
                    aria-label="paint-protection"
                    name="PaintProtectionOption"
                    onChange={(e) => {
                      handleInputChange(e, "_PaintProtection");
                      if (!values.PaintProtectionMaterial)
                        setValues({
                          ...values,
                          PaintProtectionMaterial: "Glossy",
                        });
                    }}
                    options={[
                      {
                        label: "Basic",
                        value: "Basic",
                      },
                      {
                        label: "Bikini",
                        value: "Bikini",
                      },
                      {
                        label: "Full Front",
                        value: "Full Front",
                      },
                      {
                        label: "Full Body",
                        value: "Full Body",
                      },
                      {
                        label: "Other",
                        value: "Other",
                      },
                    ]}
                  />
                  {values.PaintProtectionOption === "Other" && (
                    <InputControl
                      label="Which Part(s)"
                      name="PaintProtectionParts"
                      onChange={(e) => handleInputChange(e, "_PaintProtection")}
                      multiline
                      rows={3}
                    />
                  )}

                  {values.PaintProtectionOption && (
                    <>
                      <Divider />
                      <RadioGroupControl
                        aria-label="paint-protection-material"
                        label="Material:"
                        name="PaintProtectionMaterial"
                        value={values.PaintProtectionMaterial}
                        onChange={(e) =>
                          handleInputChange(e, "_PaintProtection")
                        }
                        options={[
                          {
                            label: "Glossy",
                            value: "Glossy",
                          },
                          {
                            label: "Matte",
                            value: "Matte",
                          },
                        ]}
                      />
                    </>
                  )}
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "StripingColor",
                    "StripingColorImg",
                    "stripingFile",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_Striping"
                  icon="wi-striping"
                  title="Striping"
                >
                  <ColorSelect
                    label="Striping Color"
                    name="StripingColor"
                    values={values}
                    imgSrc="StripingColorImg"
                    onChange={(e) => handleInputChange(e, "_Striping")}
                  />
                  <FormControl margin="normal" fullWidth>
                    <InputLabel>Upload Design / Example</InputLabel>
                    <FilesUploader
                      inputName="stripingFile"
                      accept="image/*"
                      values={values}
                      setValues={setValues}
                    />
                  </FormControl>
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["DesignWrapOption", "designWrapFile"]}
                  values={values}
                  setValues={setValues}
                  switchName="_DesignWrap"
                  icon="wi-design-wrap"
                  title="Design Wrap"
                >
                  <CheckboxControl
                    label="Design Request"
                    name="DesignWrapOption"
                    onChange={(e) => handleInputChange(e, "_DesignWrap")}
                  />
                  <FormControl margin="normal" fullWidth>
                    <InputLabel>Upload Your Design</InputLabel>
                    <FilesUploader
                      inputName="designWrapFile"
                      accept="image/*"
                      values={values}
                      setValues={setValues}
                    />
                  </FormControl>
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["AccentColor", "AccentColorImg", "AccentParts"]}
                  values={values}
                  setValues={setValues}
                  switchName="_AccentWrap"
                  icon="wi-accent-wrap"
                  title="Accent Wrap"
                >
                  <ColorSelect
                    label="Accent Color"
                    name="AccentColor"
                    values={values}
                    imgSrc="AccentColorImg"
                    onChange={(e) => handleInputChange(e, "_AccentWrap")}
                  />
                  <InputControl
                    label="Which Part(s)"
                    name="AccentParts"
                    onChange={(e) => handleInputChange(e, "_AccentWrap")}
                    multiline
                    rows={3}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["WindowTintOption", "WindowTintColor"]}
                  values={values}
                  setValues={setValues}
                  switchName="_WindowTint"
                  icon="wi-window-tint"
                  title="Window Tint"
                >
                  <RadioGroupControl
                    aria-label="window-tint"
                    name="WindowTintOption"
                    onChange={(e) => {
                      handleInputChange(e, "_WindowTint");
                      if (!values.WindowTintColor)
                        setValues({ ...values, WindowTintColor: "Light" });
                    }}
                    options={[
                      {
                        label: "All",
                        value: "All",
                      },
                      {
                        label: "All Except Windshield",
                        value: "All Except Windshield",
                      },
                      {
                        label: "All Behind Front Door",
                        value: "All Behind Front Door",
                      },
                      {
                        label: "Only Back Window",
                        value: "Only Back Window",
                      },
                      {
                        label: "Other",
                        value: "Other",
                      },
                    ]}
                  />
                  {values.WindowTintOption === "Other" && (
                    <InputControl
                      label="Which Part(s)"
                      name="WindowTintParts"
                      onChange={(e) => handleInputChange(e, "_WindowTint")}
                      multiline
                      rows={3}
                    />
                  )}
                  {values.WindowTintOption && (
                    <>
                      <Divider />
                      <RadioGroupControl
                        aria-label="window-tint-color"
                        label="Tint:"
                        name="WindowTintColor"
                        value={values.WindowTintColor}
                        onChange={(e) => handleInputChange(e, "_WindowTint")}
                        options={[
                          {
                            label: "Light",
                            value: "Light",
                          },
                          {
                            label: "Medium Dark",
                            value: "Medium Dark",
                          },
                          {
                            label: "Limo Black",
                            value: "Limo Black",
                          },
                        ]}
                      />
                    </>
                  )}
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["WindshieldProtectionOption"]}
                  values={values}
                  setValues={setValues}
                  switchName="_WindshieldProtection"
                  icon="wi-windshield-protection"
                  title="Windshield Protection"
                >
                  <CheckboxControl
                    label="Windshield Protection"
                    name="WindshieldProtectionOption"
                    onChange={(e) =>
                      handleInputChange(e, "_WindshieldProtection")
                    }
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["CeramicCoatingOption"]}
                  values={values}
                  setValues={setValues}
                  switchName="_CeramicCoating"
                  icon="wi-ceramic-coating"
                  title="Ceramic Coating"
                >
                  <CheckboxControl
                    label="Ceramic Coating"
                    name="CeramicCoatingOption"
                    onChange={(e) => handleInputChange(e, "_CeramicCoating")}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["SmokedLightsOption"]}
                  values={values}
                  setValues={setValues}
                  switchName="_SmokedLights"
                  icon="wi-smoked-lights"
                  title="Smoked Lights"
                >
                  <RadioGroupControl
                    aria-label="smoked-lights"
                    name="SmokedLightsOption"
                    onChange={(e) => handleInputChange(e, "_SmokedLights")}
                    options={[
                      {
                        label: "Front Lights",
                        value: "Front Lights",
                      },
                      {
                        label: "Tail Lights",
                        value: "Tail Lights",
                      },
                      {
                        label: "Front and Tail Lights",
                        value: "Front and Tail Lights",
                      },
                    ]}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["WheelsOption", "WheelsColor"]}
                  values={values}
                  setValues={setValues}
                  switchName="_Wheels"
                  icon="wi-wheels"
                  title="Wheels"
                >
                  <RadioGroupControl
                    aria-label="wheels"
                    name="WheelsOption"
                    onChange={(e) => handleInputChange(e, "_Wheels")}
                    options={[
                      {
                        label: "Powder Coating",
                        value: "Powder Coating",
                      },
                      {
                        label: "Wrapping",
                        value: "Wrapping",
                      },
                    ]}
                  />
                  <InputControl
                    label="Desired Wheel Color"
                    name="WheelsColor"
                    onChange={(e) => handleInputChange(e, "_Wheels")}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "ChromeDeleteColor",
                    "ChromeDeleteColorImg, ChromeDeleteParts",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_ChromeDelete"
                  icon="wi-chrome-delete"
                  title="Chrome Delete"
                >
                  <ColorSelect
                    label="Color"
                    name="ChromeDeleteColor"
                    values={values}
                    imgSrc="ChromeDeleteColorImg"
                    onChange={(e) => handleInputChange(e, "_ChromeDelete")}
                  />
                  <InputControl
                    label="Which Part(s)"
                    name="ChromeDeleteParts"
                    onChange={(e) => handleInputChange(e, "_ChromeDelete")}
                    multiline
                    rows={3}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["OtherDetails"]}
                  values={values}
                  setValues={setValues}
                  switchName="_Other"
                  icon="wi-other"
                  title="Other"
                >
                  <InputControl
                    label="Please Specify"
                    name="OtherDetails"
                    multiline
                    rows={3}
                    onChange={(e) => handleInputChange(e, "_Other")}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[service + "Title", service + "Details"]}
                  values={values}
                  setValues={setValues}
                  switchName={serviceName.replace(/\s/g, "")}
                  icon="wi-other"
                  title={serviceName.substring(1)}
                >
                  <InputControl
                    label="Title"
                    name={service + "Title"}
                    onChange={(e) =>
                      handleServiceChange(e, serviceName.replace(/\s/g, ""))
                    }
                  />
                  <InputControl
                    label="Description"
                    name={service + "Details"}
                    multiline
                    rows={3}
                    onChange={(e) =>
                      handleInputChange(e, serviceName.replace(/\s/g, ""))
                    }
                  />
                </ServiceCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} textAlign="center">
            <Fab
              component={Link}
              to={`/job-details/${params.quoteId}`}
              aria-label="go back"
              sx={{ mb: 3 }}
            >
              <ArrowBackIcon />
            </Fab>
            <Typography color="text.secondary">
              Select services form left and click CONFIRM once done
            </Typography>
            <Divider variant="middle" sx={{ my: 2 }}>
              {fetchError}
            </Divider>
            {Object.keys(values).some(
              (k) => /^[A-Z]/.test(k[0]) && values[k]
            ) ? (
              <ButtonControl type="submit" text="Submit" isSaving={isSaving} />
            ) : (
              <ButtonControl text="Submit" disabled />
            )}
          </Grid>
        </Grid>
      </Slide>
    </form>
  );
}

export default memo(EditAutomotive);
