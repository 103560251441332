import ArticleIcon from "@mui/icons-material/Article";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Card,
  CardMedia,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import useAppContext from "../../hooks/useAppContext";

function ColorDetails({ selectedRecord, openDrawer }) {
  const { user } = useAppContext();

  return (
    <Paper>
      <List>
        <ListItem
          secondaryAction={
            user?.roles.includes("Admin") && (
              <Tooltip title="Edit" placement="bottom" arrow>
                <IconButton
                  color="primary"
                  aria-label="edit"
                  onClick={() => openDrawer("edit", selectedRecord)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )
          }
        >
          <ListItemAvatar>
            <Avatar>
              <ArticleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Details" secondary="Wrap Color" />
        </ListItem>
      </List>
      <Divider />
      <Paper elevation={0} sx={{ p: 3 }}>
        <Stack direction={{ xs: "column", md: "row" }} alignItems="flex-start">
          <Card sx={{ maxWidth: 200 }}>
            <CardMedia
              height="100%"
              component="img"
              image={selectedRecord.imageName}
              alt={selectedRecord.imageName}
              loading="lazy"
            />
          </Card>
          <Divider orientation="vertical" flexItem />
          <List disablePadding>
            <ListItem>
              <ListItemText primary={`ID: ${selectedRecord.colorId}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={selectedRecord.name} secondary="Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={selectedRecord.price} secondary="Price" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  selectedRecord.available ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
                secondary="Available"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.colorFinish}
                secondary="Finish"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.colorVinyl}
                secondary="Vinyl"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.createdDate}
                secondary="Created On Date"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.modifiedDate}
                secondary="Modified On Date"
              />
            </ListItem>
          </List>
        </Stack>
      </Paper>
    </Paper>
  );
}

export default memo(ColorDetails);
