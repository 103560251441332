import AddIcon from "@mui/icons-material/Add";
import CampaignIcon from "@mui/icons-material/Campaign";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Tooltip,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { memo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ApiFetch from "../../components/ApiFetch";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import { API_BLOGS, API_MEDIAS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import useForm from "../../hooks/useForm";

const objRecord = {
  title: "",
  post: "",
};

function BlogsList({ actions = true }) {
  const { user } = useAppContext();
  const { setNotify } = useComponentContext();
  const [selectedRecord, setSelectedRecord] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const templateEditorRef = useRef(null);

  const { records, setRecords, isLoading, fetchError } =
    useFetchList(API_BLOGS);

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("title" in input)
      temp.title = input.title ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    useForm(selectedRecord, true, validate);

  const addOrEdit = (record) => {
    resetForm();
    setSelectedRecord(record);
    setValues(record);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSaving(true);
      values.post = templateEditorRef.current.getContent();

      if (!selectedRecord.blogId) {
        // POST request using fetch
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        };
        const results = await ApiFetch(
          API_BLOGS,
          requestOptions,
          setNotify,
          "Blog Added Successfully!"
        );

        if (results) {
          setRecords([results, ...records]);
          setSelectedRecord();
        }
      } else {
        // PUT Request using fetch
        const requestOptions = {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        };
        const results = await ApiFetch(
          `${API_BLOGS}/${values.blogId}`,
          requestOptions,
          setNotify,
          "Blog Updated Successfully!"
        );

        if (results) {
          const index = records.findIndex((el) => el.blogId === values.blogId);
          records[index] = results;
          setSelectedRecord();
        }
      }
      setIsSaving(false);
    }
  };

  const handleDelete = async (id) => {
    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_BLOGS}/${id}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      setRecords((records) => records.filter((el) => el.blogId !== id));
    }
  };

  const handleTinymceUpload = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", API_MEDIAS);
      xhr.setRequestHeader("Authorization", "Bearer " + user.token);

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status === 403) {
          reject({ message: "HTTP Error: " + xhr.status, remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);

        if (!json || typeof json.location != "string") {
          reject("Invalid JSON: " + xhr.responseText);
          return;
        }

        resolve(json.location);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const data = new FormData();
      data.append("file", blobInfo.blob(), blobInfo.filename());
      data.append("name", "blog");

      xhr.send(data);
    });

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <Paper square>
          <List
            subheader={
              records?.length === 0 && (
                <ListSubheader>No Record Found!</ListSubheader>
              )
            }
          >
            <ListItem
              secondaryAction={
                actions && user?.roles.includes("Admin") ? (
                  <Tooltip title="Add New" placement="top" arrow>
                    <Fab
                      color="primary"
                      aria-label="add"
                      onClick={() => addOrEdit(objRecord)}
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                ) : (
                  <Avatar>{records.length}</Avatar>
                )
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <CampaignIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Announcements" />
            </ListItem>
          </List>
          <Divider />
          <List disablePadding sx={{ height: "40vh", overflowY: "auto" }}>
            {!selectedRecord &&
              records.map((item, i) => (
                <ListItem
                  key={i}
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                  disablePadding
                  secondaryAction={
                    actions &&
                    user?.roles.includes("Admin") && (
                      <>
                        <Tooltip title="Edit" placement="top" arrow>
                          <IconButton
                            color="primary"
                            aria-label="edit"
                            onClick={() => addOrEdit(item)}
                            disabled={isSaving}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top" arrow>
                          <IconButton
                            color="error"
                            aria-label="delete"
                            onClick={() => handleDelete(item.blogId)}
                            disabled={isSaving}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                  }
                >
                  <ListItemButton
                    component={Link}
                    to={`/blog-details/${item.blogId}`}
                  >
                    <ListItemText
                      primary={item.title}
                      secondary={
                        item.modifiedDate ? item.modifiedDate : item.createdDate
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            {selectedRecord && (
              <ListItem>
                <form onSubmit={handleSubmit}>
                  <InputControl
                    label="Title"
                    name="title"
                    value={values.title}
                    onChange={handleInputChange}
                    helperText="Maximum 50 character allowed"
                    error={errors.title}
                  />
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <Editor
                      apiKey="hcrnh25lrnya5i165p07hodgt9i4hs77d7atdp75o1myodqy"
                      onInit={(evt, editor) =>
                        (templateEditorRef.current = editor)
                      }
                      initialValue={values.post}
                      init={{
                        min_height: 300,
                        branding: false,
                        menubar: true,
                        placeholder: "Post Editor...",
                        image_advtab: true,
                        images_upload_handler: handleTinymceUpload,
                        paste_data_images: true,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "preview",
                          "help",
                          "wordcount",
                        ],
                        toolbar1:
                          "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat image preview",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        convert_urls: false,
                      }}
                    />
                  </FormControl>
                  <ButtonControl
                    type="submit"
                    text="Save"
                    isSaving={isSaving}
                  />
                  <ButtonControl
                    color="error"
                    text="Cancel"
                    onClick={() => {
                      resetForm();
                      setSelectedRecord();
                    }}
                    isSaving={isSaving}
                    sx={{ ml: 1 }}
                  />
                  <Divider sx={{ my: 2 }} />
                </form>
              </ListItem>
            )}
          </List>
        </Paper>
      )}
    </>
  );
}

export default memo(BlogsList);
