export default async function ApiFetch(url, objOptions, setNotify, msg) {
  const abortCtrl = new AbortController();

  // Fetch request with error handling
  try {
    const response = await fetch(url, objOptions, { signal: abortCtrl.signal });
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const json = isJson && (await response.json());

    // check for error response
    if (!response.ok) {
      // get error message from body or default to response statusText
      const error =
        (json && json.message) + ` : ${response.status}` || response.status;
      throw Error(error);
    }

    if (json?.createdDate)
      json.createdDate = new Date(json.createdDate).toLocaleString("lookup", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

    if (json?.modifiedDate)
      json.modifiedDate = new Date(json.modifiedDate).toLocaleString("lookup", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

    if (msg) {
      setNotify({
        isOpen: true,
        message: msg,
        severity: response.status === 204 ? "warning" : "success",
      });
    }

    return response.status === 204 ? "DELETE" : json;
  } catch (error) {
    setNotify({
      isOpen: true,
      message: !error.message ? error : error.message,
      severity: "error",
    });
  } finally {
    abortCtrl.abort();
  }
}
