import ArchitectureIcon from "@mui/icons-material/Architecture";
import ChatIcon from "@mui/icons-material/Chat";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import {
  Avatar,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_ALERTS } from "../context/ApiEndPoints";
import useAppContext from "../hooks/useAppContext";
import useComponentContext from "../hooks/useComponentContext";
import { dateTimeStamp } from "../scripts/Scripts";
import ApiFetch from "./ApiFetch";

function AlertsHub({ header }) {
  const { user, alerts, setAlerts, alertCount, setAlertCount } =
    useAppContext();
  const { setNotify } = useComponentContext();
  const [isSaving, setIsSaving] = useState(false);
  let navigate = useNavigate();

  const handleDismiss = async (id, dismiss, url) => {
    if (!dismiss) {
      setIsSaving(true);

      // POST request using fetch
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
      };
      const results = await ApiFetch(
        `${API_ALERTS}/${id}`,
        requestOptions,
        setNotify
      );

      if (results) {
        const index = alerts.findIndex((e) => e.alertId === id);
        alerts[index] = results;
        setAlerts(alerts);
        setAlertCount(alertCount - 1);
      }
      setIsSaving(false);
    }
    if (!isSaving) navigate(url);
  };

  const handleDelete = async (id) => {
    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_ALERTS}/${id}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      setAlerts((alerts) => alerts.filter((e) => e.alertId !== id));
    }
  };

  return (
    <Paper elevation={header ? 1 : 0} square>
      {header && (
        <>
          <List>
            <ListItem
              secondaryAction={
                <Avatar sx={{ bgcolor: alertCount > 0 ? "error.main" : null }}>
                  {alertCount}
                </Avatar>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <NotificationsIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Notifications" />
            </ListItem>
          </List>
          <Divider />
        </>
      )}
      <List
        dense
        disablePadding
        sx={
          header
            ? { height: "40vh", overflowY: "auto" }
            : { maxHeight: "40vh", overflowY: "auto" }
        }
        subheader={
          alerts?.length === 0 && <ListSubheader>No records.</ListSubheader>
        }
      >
        {!alerts ? (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ) : (
          alerts.map((it, i) =>
            Array(JSON.parse(it.content)).map((iu, i) => (
              <ListItem
                key={i}
                sx={{ borderBottom: 1, borderColor: "divider" }}
                secondaryAction={
                  <Tooltip title="Delete" placement="top" arrow>
                    <IconButton
                      color="inherit"
                      aria-label="delete"
                      onClick={() => handleDelete(it.alertId)}
                      disabled={isSaving}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                }
              >
                <ListItemIcon>
                  {iu.group === 0 ? (
                    <ChatIcon color={it.dismiss ? "default" : "error"} />
                  ) : iu.group === 1 ? (
                    <RequestQuoteIcon
                      color={it.dismiss ? "default" : "error"}
                    />
                  ) : (
                    <ArchitectureIcon
                      color={it.dismiss ? "default" : "error"}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      display="inline-block"
                      color={it.dismiss ? "inherit" : "error"}
                      onClick={() =>
                        handleDismiss(
                          it.alertId,
                          it.dismiss,
                          `/job-details/${iu.refId}`
                        )
                      }
                      sx={{ pr: 3, cursor: "pointer" }}
                      disabled={isSaving}
                    >
                      {iu.message}
                      <br />
                      {dateTimeStamp(it.createdDate)}
                      {it.dismiss && (
                        <CheckIcon
                          fontSize="small"
                          sx={{ ml: 2, verticalAlign: "text-bottom" }}
                        />
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            ))
          )
        )}
      </List>
    </Paper>
  );
}

export default memo(AlertsHub);
