import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";

function GroupDetails(props) {
  const { selectedRecord, openInDrawer } = props;

  return (
    selectedRecord && (
      <>
        <Paper>
          <List>
            <ListItem
              secondaryAction={
                <Tooltip title="Edit" placement="bottom" arrow>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => openInDrawer("edit", selectedRecord)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <ArticleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Details" secondary="Company" />
            </ListItem>
          </List>
          <Divider />
          <List sx={{ p: 3 }}>
            <ListItem>
              <ListItemText primary={selectedRecord.name} secondary="Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={selectedRecord.email} secondary="Email" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.address}
                secondary="Address"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={selectedRecord.city} secondary="City" />
            </ListItem>
            <ListItem>
              <ListItemText primary={selectedRecord.state} secondary="State" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.country}
                secondary="Country"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.zipCode}
                secondary="Zip Code"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.taxNumber}
                secondary="Tax No."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.createdDate}
                secondary="Created On Date"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  selectedRecord.modifiedDate && selectedRecord.modifiedDate
                }
                secondary="Modified On Date"
              />
            </ListItem>
          </List>
        </Paper>
      </>
    )
  );
}

export default memo(GroupDetails);
