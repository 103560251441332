import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import {
  Alert,
  Avatar,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Container,
  Icon,
  Link,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useParams } from "react-router-dom";
import ButtonControl from "../components/controls/ButtonControl";
import InputControl from "../components/controls/InputControl";
import SelectControl from "../components/controls/SelectControl";
import { API_REQUEST } from "../context/ApiEndPoints";
import useFetchList from "../hooks/useFetchList";
import useForm from "../hooks/useForm";

const objRecord = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  company: "",
  taxNumber: "",
  whereFindUs: "Social Media",
  notes: "",
};

function CustomerFormCard() {
  const params = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [jsonResponse, setJsonResponse] = useState("");

  const { records, isLoading, fetchError } = useFetchList(
    `${API_REQUEST}/GetShop/${params.alias}`
  );

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("firstName" in input)
      temp.firstName = input.firstName ? "" : "This field is required.";
    if ("lastName" in input)
      temp.lastName = input.lastName ? "" : "This field is required.";
    if ("city" in input)
      temp.city = input.city ? "" : "This field is required.";
    if ("state" in input)
      temp.state = input.state ? "" : "This field is required.";
    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";
    if ("zipCode" in input)
      temp.zipCode = input.zipCode ? "" : "This field is required.";
    if ("phoneNumber" in input)
      temp.phoneNumber = input.phoneNumber ? "" : "This field is required.";
    if ("userId" in input)
      temp.userId = input.userId ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors, resetForm } = useForm(
    objRecord,
    true,
    validate
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);
      values.userId = records.id;
      values.shopId = records.shopId;

      const abortCtrl = new AbortController();

      // POST request using fetch
      const objOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };

      // Fetch request with error handling
      try {
        const response = await fetch(
          `${API_REQUEST}/HelloCustomer`,
          objOptions,
          {
            signal: abortCtrl.signal,
          }
        );
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        resetForm();
        setJsonResponse(
          <Snackbar
            open={true}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert elevation={6} severity="success">
              Customer added successfully
            </Alert>
          </Snackbar>
        );
      } catch (error) {
        setJsonResponse(
          <Snackbar
            open={true}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert elevation={6} severity="error">
              {!error.message ? error : error.message}
            </Alert>
          </Snackbar>
        );
      } finally {
        abortCtrl.abort();
      }
    }
    setIsSaving(false);
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <>
          <Paper square sx={{ px: 6, py: 1.6, mb: 5, bgcolor: "black" }}>
            <Card
              sx={{
                maxWidth: 200,
                visibility: { xs: "hidden", md: "inherit" },
              }}
            >
              <CardActionArea component={Link} href="https://2wrap.com/">
                <CardMedia
                  component="img"
                  image="/img/2wrap-name-logo.jpg"
                  alt="2wrap.com"
                  loading="lazy"
                />
              </CardActionArea>
            </Card>
            <Paper
              component={Link}
              href="https://2wrap.com/"
              sx={{
                p: 5,
                pb: 3.5,
                borderRadius: "100%",
                position: "absolute",
                left: "50%",
                top: "0",
                transform: "translate(-50%, -32px)",
                bgcolor: "black",
                color: "white",
              }}
            >
              <Icon className="wi-icon-2wrap" sx={{ fontSize: 68 }} />
            </Paper>
          </Paper>
          <Container maxWidth="lg" sx={{ p: 2 }} align="center">
            <Avatar>
              <PermIdentityOutlinedIcon />
            </Avatar>
            <Typography variant="h5" component="h2" noWrap>
              Hi, Welcome to {records.shopTitle}
            </Typography>
            <Typography>
              Please fill in the form below so we can serve you better
            </Typography>
          </Container>
          <form onSubmit={handleSubmit}>
            <Paper>
              <Paper
                elevation={0}
                sx={{ p: 3, margin: "auto", maxWidth: "md" }}
              >
                <InputControl
                  type="email"
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                />
                <InputControl
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleInputChange}
                  error={errors.firstName}
                />
                <InputControl
                  label="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleInputChange}
                  error={errors.lastName}
                />
                <InputControl
                  label="Phone No."
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleInputChange}
                  error={errors.phoneNumber}
                />
                <InputControl
                  label="Address"
                  name="address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                />
                <InputControl
                  label="City"
                  name="city"
                  value={values.city}
                  onChange={handleInputChange}
                  error={errors.city}
                />
                <InputControl
                  label="State"
                  name="state"
                  value={values.state}
                  onChange={handleInputChange}
                  error={errors.state}
                />
                <InputControl
                  label="Country"
                  name="country"
                  value={values.country}
                  onChange={handleInputChange}
                  error={errors.country}
                />
                <InputControl
                  label="Zip Code"
                  name="zipCode"
                  value={values.zipCode}
                  onChange={handleInputChange}
                  error={errors.zipCode}
                />
                <InputControl
                  label="Company"
                  name="company"
                  value={values.company}
                  onChange={handleInputChange}
                  error={errors.company}
                />
                <InputControl
                  label="Tax No."
                  name="taxNumber"
                  value={values.taxNumber}
                  onChange={handleInputChange}
                  error={errors.taxNumber}
                />
                <SelectControl
                  label="Where did you find us"
                  name="whereFindUs"
                  value={values.whereFindUs}
                  onChange={handleInputChange}
                  options={[
                    { value: "Social Media", label: "Social Media" },
                    { value: "TV", label: "TV" },
                    { value: "Friend", label: "Friend" },
                    { value: "Google", label: "Google" },
                    { value: "Other", label: "Other" },
                  ]}
                />
                {jsonResponse}
                <ButtonControl type="submit" text="Save" isSaving={isSaving} />
              </Paper>
            </Paper>
          </form>
        </>
      )}
    </>
  );
}

export default memo(CustomerFormCard);
