import LockResetIcon from "@mui/icons-material/LockReset";
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import ApiFetch from "../components/ApiFetch";
import ButtonControl from "../components/controls/ButtonControl";
import InputControl from "../components/controls/InputControl";
import { API_USERS_ACCOUNT } from "../context/ApiEndPoints";
import useAppContext from "../hooks/useAppContext";
import useComponentContext from "../hooks/useComponentContext";
import useForm from "../hooks/useForm";

const objRecord = {
  password: "",
};

export default function Configs() {
  const { user, setUser } = useAppContext();
  const { setNotify } = useComponentContext();
  const [isSaving, setIsSaving] = useState(false);

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("password" in input) {
      temp.password =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/.test(
          input.password
        )
          ? ""
          : "Minimum 8 characters, at least one upper case letter, one lower case letter, one number and one special character";

      temp.password = input.password ? temp.password : "Password is required.";
    }

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors } = useForm(
    objRecord,
    true,
    validate
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);
      // PUT Request using fetch
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };
      const results = await ApiFetch(
        API_USERS_ACCOUNT,
        requestOptions,
        setNotify
      );

      setIsSaving(false);
      if (results) {
        localStorage.removeItem("X-Access-Token");
        setUser(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item component={Paper} xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <LockResetIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="SMTP" secondary="Confgure Email Server" />
            </ListItem>
          </List>
          <Divider />
          <Paper elevation={0} sx={{ p: 3 }}>
            <InputControl
              label="API Key"
              name="apiKey"
              value={values.apiKey}
              onChange={handleInputChange}
              error={errors.apiKey}
            />
            <InputControl
              label="Host"
              name="host"
              value={values.host}
              onChange={handleInputChange}
              error={errors.host}
            />
            <InputControl
              label="Port"
              name="port"
              value={values.port}
              onChange={handleInputChange}
              error={errors.port}
            />
            <InputControl
              type="email"
              label="Email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email}
            />
            <InputControl
              type="password"
              label="Password"
              name="password"
              value={values.password}
              onChange={handleInputChange}
              error={errors.password}
            />
            {!isSaving && <ButtonControl type="submit" text="Save" />}
            {isSaving && (
              <ButtonControl
                type="submit"
                text="Saving..."
                isSaving={isSaving}
                disabled
              />
            )}
          </Paper>
        </Grid>
        <Grid item component={Paper} xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <LockResetIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Twilio"
                secondary="Confgure Twilio Messages"
              />
            </ListItem>
          </List>
          <Divider />
          <Paper elevation={0} sx={{ p: 3 }}>
            <InputControl
              label="SID"
              name="sid"
              value={values.sid}
              onChange={handleInputChange}
              error={errors.sid}
            />
            <InputControl
              label="Token"
              name="token"
              value={values.token}
              onChange={handleInputChange}
              error={errors.token}
            />
            <InputControl
              label="Phone Number"
              name="phone"
              value={values.phone}
              onChange={handleInputChange}
              error={errors.phone}
            />
            {!isSaving && <ButtonControl type="submit" text="Save" />}
            {isSaving && (
              <ButtonControl
                type="submit"
                text="Saving..."
                isSaving={isSaving}
                disabled
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
