import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TabPanel from "../../components/TabPanel";
import MsgCustomerList from "./MsgCustomerList";
import MsgQueryList from "./MsgQueryList";

export default function Msgs() {
  const params = useParams();
  const navigate = useNavigate();

  const isGUID = (str) => {
    // Regex to check valid GUID
    let regex = new RegExp(
      /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
    );
    // const emptyGUID = "00000000-0000-0000-0000-000000000000";

    // if str is empty return false
    if (str == null) return false;

    // Return true if the str matched the ReGex
    if (regex.test(str) === true) return true;
    else return false;
  };

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    navigate(`/msgs`);
    setTabValue(newValue);
  };

  useEffect(() => {
    if (params.number) setTabValue(isGUID(params.number) ? 0 : 1);

    return () => {};
  }, [params.number]);

  return (
    <>
      <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
        <Tab label="Customers" />
        <Tab label="Queries" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <MsgCustomerList customerId={params.number} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <MsgQueryList customerNumber={params.number} />
      </TabPanel>
    </>
  );
}
