import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Fab,
  Grid,
  Paper,
  Popover,
  TableContainer,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { memo, useEffect, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import ContentDrawer from "../../components/ContentDrawer";
import { API_SCHEDULES } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import useScheduler from "../../hooks/useScheduler";
import InstallForm from "./InstallForm";
dayjs.extend(utc);

function InstallScheduler(props) {
  const { user } = useAppContext();
  const [contentDrawer, setContentDrawer] = useState(false);
  const { setNotify, setConfirmDialog } = useComponentContext();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isSaving, setIsSaving] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const days = 7;
  const interval = 30;
  const tformat = 12;

  //--- Start Calander popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //--- End Calander popover

  const { records, setRecords, isLoading, fetchError } =
    useFetchList(API_SCHEDULES);

  const openInDrawer = (action, record = null) => {
    setSelectedRecord(record ? { ...record } : null);
    setSelectedIndex(action === "add" ? -1 : selectedIndex);
    setContentDrawer(true);
  };

  const addOrEdit = async (record, resetForm) => {
    setIsSaving(true);

    record.startTime = dayjs(record.startTime).format();
    record.endTime = dayjs(record.endTime).format();

    const formData = new FormData();
    for (const [k, v] of Object.entries(record)) {
      formData.append(k, v ?? "");
    }

    // Add beforeFiles values to formData
    if (record.beforeFiles) {
      for (const val of Object.values(record.beforeFiles)) {
        formData.append("beforeFiles", val);
      }
    }

    // Add afterFiles values to formData
    if (record.afterFiles) {
      for (const val of Object.values(record.afterFiles)) {
        formData.append("afterFiles", val);
      }
    }

    if (!record.scheduleId) {
      // POST request using fetch
      const requestOptions = {
        method: "POST",
        headers: { Authorization: "Bearer " + user.token },
        body: formData,
      };
      const results = await ApiFetch(
        API_SCHEDULES,
        requestOptions,
        setNotify,
        "Schedule Added Successfully!"
      );

      if (results) {
        setRecords([...records, results]);
        setSelectedIndex(records.length);
        resetForm();
      }
    } else {
      // PUT Request using fetch
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: "Bearer " + user.token },
        body: formData,
      };
      const results = await ApiFetch(
        `${API_SCHEDULES}/${record.scheduleId}`,
        requestOptions,
        setNotify,
        "Schedule Updated Successfully!"
      );

      if (results) {
        const index = records.findIndex(
          (el) => el.scheduleId === record.scheduleId
        );
        setRecords((records) =>
          records.map((el, i) => (i === index ? results : el))
        );

        setSelectedRecord({ ...results });
      }
    }
    setIsSaving(false);
  };

  const handleDelete = async (id) => {
    setConfirmDialog({ isOpen: false });

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_SCHEDULES}/${id}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      setSelectedIndex(-1);
      setSelectedRecord(null);
      setRecords((records) => records.filter((el) => el.scheduleId !== id));
    }
  };

  const handleFileDelete = async (e, mediaId) => {
    setConfirmDialog({ isOpen: false });
    setIsSaving(true);
    let current = e.target.closest("div.MuiCard-root");

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_SCHEDULES}/Media/${mediaId}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      current.parentNode.removeChild(current);
    }
    setIsSaving(false);
  };

  const handleSms = async (id) => {
    setIsSaving(true);

    // GET Request using fetch
    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + user.token },
    };
    await ApiFetch(
      `${API_SCHEDULES}/Sms/${id}`,
      requestOptions,
      setNotify,
      "SMS Sent Successfully!"
    );

    setIsSaving(false);
  };

  const handleEmail = async (id) => {
    setIsSaving(true);

    // GET Request using fetch
    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + user.token },
    };
    await ApiFetch(
      `${API_SCHEDULES}/Email/${id}`,
      requestOptions,
      setNotify,
      "SMS Sent Successfully!"
    );

    setIsSaving(false);
  };

  const handleComplete = async (id) => {
    setConfirmDialog({ isOpen: false });
    setIsSaving(true);

    // GET Request using fetch
    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_SCHEDULES}/Complete/${id}`,
      requestOptions,
      setNotify,
      "Schedule Updated Successfully!"
    );

    if (results) setSelectedRecord({ ...results });
    setIsSaving(false);
  };

  const { TblDate, TblContainer, TblBody, EventListeners } = useScheduler(
    records,
    startDate,
    days,
    interval,
    tformat,
    openInDrawer,
    addOrEdit,
    handleDelete,
    setConfirmDialog,
    setNotify,
    props.quoteId
  );

  useEffect(() => {
    const qRecord =
      records && records.find((el) => el.quoteId === parseInt(props.quoteId));
    if (qRecord) setStartDate(new Date(qRecord.startTime));

    return () => {};
  }, [records, props.quoteId]);

  useEffect(() => {
    EventListeners();

    return () => {};
  }, [EventListeners]);

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <>
          <Toolbar component={Paper} square disableGutters sx={{ mb: 0.15 }}>
            <Grid container alignItems="center">
              <Grid
                item
                xs
                display="flex"
                justifyContent={{ xs: "center", md: "start" }}
                alignItems="center"
                sx={{ p: 2 }}
              >
                <ButtonGroup variant="text">
                  <Button
                    onClick={() => {
                      startDate.setDate(startDate.getDate() - days);
                      setStartDate(new Date(startDate));
                    }}
                  >
                    <ArrowBackIcon />
                  </Button>
                  <Button onClick={() => setStartDate(new Date())}>
                    today
                  </Button>
                  <Button
                    onClick={() => {
                      startDate.setDate(startDate.getDate() + days);
                      setStartDate(new Date(startDate));
                    }}
                  >
                    <ArrowForwardIcon />
                  </Button>
                  <Button
                    size="large"
                    startIcon={<CalendarMonthIcon />}
                    aria-describedby={id}
                    onClick={handleClick}
                  >
                    <Typography sx={{ textTransform: "capitalize" }} noWrap>
                      {TblDate()}
                    </Typography>
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        id="date-picker"
                        value={dayjs(startDate)}
                        onChange={(newValue) =>
                          setStartDate(new Date(newValue))
                        }
                      />
                    </LocalizationProvider>
                  </Popover>
                </ButtonGroup>
              </Grid>
              <Grid
                item
                xs
                display="flex"
                justifyContent={{ xs: "center", md: "end" }}
                alignItems="center"
                sx={{ p: 2 }}
              >
                {props.quoteId && (
                  <Tooltip title="Add New" placement="top" arrow>
                    <Fab
                      color="primary"
                      aria-label="add"
                      onClick={() => openInDrawer("add")}
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Toolbar>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "77.2vh" }}>
              <TblContainer>
                <TblBody />
              </TblContainer>
            </TableContainer>
          </Paper>
        </>
      )}

      {/* ContentDrawer */}
      <ContentDrawer
        contentDrawer={contentDrawer}
        setContentDrawer={setContentDrawer}
      >
        <InstallForm
          addOrEdit={addOrEdit}
          handleFileDelete={handleFileDelete}
          handleSms={handleSms}
          handleEmail={handleEmail}
          handleComplete={handleComplete}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          openInDrawer={openInDrawer}
          isSaving={isSaving}
          setConfirmDialog={setConfirmDialog}
          quoteId={props.quoteId}
          user={user}
        />
      </ContentDrawer>
    </>
  );
}

export default memo(InstallScheduler);
