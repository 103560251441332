import { Icon, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TabPanel from "../../components/TabPanel";
import FormAdvertising from "./FormAdvertising";
import FormAeronautics from "./FormAeronautics";
import FormArchitectural from "./FormArchitectural";
import FormAutomotive from "./FormAutomotive";
import FormNautical from "./FormNautical";
import FormSpecialties from "./FormSpecialties";

export default function QuoteAdd() {
  const params = useParams();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
        <Tab
          icon={<Icon className="wi-automotive" fontSize="large" />}
          iconPosition="start"
          label="Automotive"
        />
        <Tab
          icon={<Icon className="wi-advertising" />}
          iconPosition="start"
          label="Advertising"
        />
        <Tab
          icon={<Icon className="wi-architectural" />}
          iconPosition="start"
          label="Architectural"
        />
        <Tab
          icon={<Icon className="wi-nautical" fontSize="large" />}
          iconPosition="start"
          label="Nautical"
        />
        <Tab
          icon={<Icon className="wi-aeronautics" fontSize="large" />}
          iconPosition="start"
          label="Aeronautics"
        />
        <Tab
          icon={<Icon className="wi-specialties" />}
          iconPosition="start"
          label="Specialties"
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <FormAutomotive quoteId={params.quoteId} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <FormAdvertising quoteId={params.quoteId} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <FormArchitectural quoteId={params.quoteId} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <FormNautical quoteId={params.quoteId} />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <FormAeronautics quoteId={params.quoteId} />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <FormSpecialties quoteId={params.quoteId} />
      </TabPanel>
    </>
  );
}
