import { Alert, Snackbar } from "@mui/material";
import React, { memo } from "react";
import useComponentContext from "../hooks/useComponentContext";

function Notification() {
  const { notify, setNotify } = useComponentContext();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotify({ ...notify, isOpen: false });
  };

  return (
    <Snackbar
      open={notify.isOpen || false}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={notify.severity || "info"}
        sx={{ width: "100%" }}
      >
        {notify.message}
      </Alert>
    </Snackbar>
  );
}

export default memo(Notification);
