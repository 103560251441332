import ArchitectureIcon from "@mui/icons-material/Architecture";
import RecentActorsIcon from "@mui/icons-material/RecentActors";

import ChatIcon from "@mui/icons-material/Chat";
import PersonIcon from "@mui/icons-material/Person";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { Grid, Step, StepButton, Stepper, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomerCard from "../../components/CustomerCard";
import CustomerQuotesCard from "../../components/CustomerQuotesCard";
import DesignCard from "../../components/DesignCard";
import QuoteCard from "../../components/QuoteCard";
import TabPanel from "../../components/TabPanel";
import useAppContext from "../../hooks/useAppContext";
import DesignDetails from "../Designs/DesignDetails";
import InstallScheduler from "../Install/InstallScheduler";
import InvoiceDetails from "../Invoice/InvoiceDetails";
import QuoteComments from "../Quotes/QuoteComments";
import QuoteDetails from "../Quotes/QuoteDetails";

export default function JobDetails() {
  const params = useParams();
  const { user } = useAppContext();
  const steps = [];
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const [tabValue, setTabValue] = useState(0);
  user?.claims.includes("job_quote") && steps.push("Quote");
  user?.claims.includes("job_design") && steps.push("Design");
  user?.claims.includes("job_install") && steps.push("Installation");
  user?.claims.includes("job_invoice") && steps.push("Invoice");

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (activeStep === 2) setTabValue(1);

    return () => {};
    // eslint-disable-next-line
  }, [params.quoteId]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={8}>
        <Stepper nonLinear activeStep={activeStep} sx={{ mt: 2, mb: 1 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {user?.claims.includes("job_quote") && activeStep === 0 && (
          <QuoteDetails quoteId={params.quoteId} />
        )}
        {user?.claims.includes("job_design") && activeStep === 1 && (
          <DesignDetails quoteId={params.quoteId} />
        )}
        {user?.claims.includes("job_install") && activeStep === 2 && (
          <InstallScheduler quoteId={params.quoteId} />
        )}
        {user?.claims.includes("job_invoice") && activeStep === 3 && (
          <InvoiceDetails quoteId={params.quoteId} />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="info cards">
          <Tab icon={<PersonIcon />} />
          <Tab icon={<RecentActorsIcon />} />
          <Tab icon={<RequestQuoteIcon />} />
          <Tab icon={<ArchitectureIcon />} />
          <Tab icon={<ChatIcon />} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <CustomerCard quoteId={params.quoteId} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CustomerQuotesCard quoteId={params.quoteId} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <QuoteCard quoteId={params.quoteId} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <DesignCard quoteId={params.quoteId} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <QuoteComments quoteId={params.quoteId} />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
