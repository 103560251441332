import ChatIcon from "@mui/icons-material/Chat";
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Chip,
  CircularProgress,
  Divider,
  ListSubheader,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import React, { memo, useEffect, useRef, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import { API_QUOTECOMMENTS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import { stringAvatar, timeStamp } from "../../scripts/Scripts";
dayjs.extend(calendar);

function QuoteComments(props) {
  const propsQuoteId = parseInt(props.quoteId);
  const { user, hubCon } = useAppContext();
  const { setNotify } = useComponentContext();

  const [commentObj, setCommentObj] = useState({
    quoteId: propsQuoteId,
    comment: "",
  });
  const [usersGroup, setUsersGroup] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const containerRef = useRef();

  const { records, setRecords, isLoading, fetchError } = useFetchList(
    `${API_QUOTECOMMENTS}/Quote/${propsQuoteId}`
  );

  const JoinChat = async (shopTitle, imageName, quoteId) => {
    try {
      hubCon.on("RecieveComment", (shopTitle, imageName, comment) => {
        setRecords((prev) => [
          ...prev,
          {
            quoteId: propsQuoteId,
            user: shopTitle,
            imageName: imageName,
            comment: comment,
            createdDate: new Date(),
          },
        ]);
      });

      hubCon.on("ConnectedUsers", (users) => {
        setUsersGroup(users);
      });

      await hubCon.invoke("JoinChat", { shopTitle, imageName, quoteId });
    } catch (e) {
      console.error(e);
    }
  };

  const sendComment = async (comment) => {
    try {
      await hubCon.invoke("SendComment", comment);
    } catch (e) {
      console.error(e);
    }
  };

  // start / close websocket hubCon
  useEffect(() => {
    if (hubCon?.state === "Connected") {
      try {
        (async () =>
          await JoinChat(user.shopTitle, user.imageName, propsQuoteId))();
      } catch (e) {
        console.error(e);
      }
    }

    return () => {
      if (hubCon?.state === "Connected") {
        try {
          (async () => await hubCon.invoke("LeaveChat"))();
        } catch (e) {
          console.error(e);
        }
      }
    };
    // eslint-disable-next-line
  }, [hubCon]);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const { scrollHeight, clientHeight } = containerRef.current;
      containerRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }

    return () => {};
  }, [records]);

  let val;
  const dateDivider = (nv) => {
    if (nv !== val) {
      val = nv;
      return (
        <Divider light sx={{ my: 1 }}>
          <Chip variant="outlined" label={val} />
        </Divider>
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    // POST request using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(commentObj),
    };
    const results = await ApiFetch(
      API_QUOTECOMMENTS,
      requestOptions,
      setNotify
    );

    if (results) {
      setCommentObj({ ...commentObj, comment: "" });
      sendComment(commentObj.comment);
    }
    setIsSaving(false);
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 3 }} />
        ))}
      {records && (
        <>
          <Box
            ref={containerRef}
            boxShadow={1}
            sx={{
              p: 2,
              border: 1,
              borderColor: "divider",
              borderRadius: 1,
              height: "72.5vh",
              overflow: "auto",
            }}
          >
            {records.length === 0 && (
              <ListSubheader>
                Only records were kept in several cases of success.
              </ListSubheader>
            )}
            {records.map((e, i) => (
              <Box key={i}>
                {dateDivider(
                  dayjs(e.createdDate).calendar(null, {
                    sameDay: "[Today]",
                    lastDay: "[Yesterday]",
                    lastWeek: "dddd",
                    sameElse: "DD/MM/YYYY",
                  })
                )}
                <Stack
                  alignItems="flex-start"
                  direction={e.user === user.shopTitle ? "row-reverse" : "row"}
                  sx={{ pb: 1 }}
                >
                  <Box
                    component={Paper}
                    sx={{
                      p: 1,
                      bgcolor: e.user === user.shopTitle && "divider",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {e.imageName ? (
                        <Avatar src={e.imageName} alt={e.user} />
                      ) : (
                        <Avatar {...stringAvatar(e.user)} />
                      )}
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        align={e.user === user.shopTitle ? "right" : "left"}
                      >
                        {e.user}
                        <br />
                        {timeStamp(e.createdDate)}
                      </Typography>
                    </Stack>
                    <Divider light sx={{ my: 0.5 }} />
                    <Typography
                      variant="body2"
                      sx={{ whiteSpace: "pre-line", textOverflow: "ellipsis" }}
                    >
                      {e.comment}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            ))}
          </Box>
          <Paper sx={{ p: 2 }}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Send message to <b>Admin</b> or <b>{records[0]?.user} Shop</b>{" "}
                about this job
              </Typography>
              <AvatarGroup max={5}>
                {usersGroup &&
                  usersGroup.map(
                    (it, i) =>
                      it.shopTitle !== user.shopTitle &&
                      (it.imageName ? (
                        <Avatar key={i} src={it.imageName} alt={it.shopTitle} />
                      ) : (
                        <Avatar key={i} {...stringAvatar(it.shopTitle)} />
                      ))
                  )}
              </AvatarGroup>
            </Stack>
            <form onSubmit={handleSubmit}>
              {fetchError}
              <Stack direction="row">
                <InputControl
                  label="Type a message"
                  name="comment"
                  value={commentObj.comment}
                  onChange={(e) =>
                    setCommentObj({ ...commentObj, comment: e.target.value })
                  }
                  multiline
                  required
                  sx={{ m: 0 }}
                />
                <Badge
                  badgeContent={hubCon ? "" : "!"}
                  color={hubCon ? "success" : "error"}
                >
                  <ButtonControl
                    type="submit"
                    endIcon={<ChatIcon />}
                    isSaving={isSaving}
                    disabled={hubCon ? false : true}
                  />
                </Badge>
              </Stack>
            </form>
          </Paper>
        </>
      )}
    </>
  );
}

export default memo(QuoteComments);
