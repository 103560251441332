import ArticleIcon from "@mui/icons-material/Article";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";

function UserDetails({ selectedRecord, openInDrawer }) {
  return (
    <Paper>
      <List>
        <ListItem
          secondaryAction={
            <Tooltip title="Edit" placement="bottom" arrow>
              <IconButton
                color="primary"
                aria-label="edit"
                onClick={() => openInDrawer("edit", selectedRecord)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <ArticleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Details" secondary="User" />
        </ListItem>
      </List>
      <Divider />
      <List sx={{ p: 3 }}>
        <ListItem>
          <ListItemText
            primary={
              selectedRecord.block ? (
                <BlockIcon color="error" />
              ) : (
                <BlockIcon color="inherit" />
              )
            }
            secondary="Blocked"
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={selectedRecord.email} secondary="Email" />
        </ListItem>
        <ListItem>
          <ListItemText primary={selectedRecord.roles} secondary="Role" />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={selectedRecord.firstName}
            secondary="First Name"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={selectedRecord.lastName}
            secondary="Last Name"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={selectedRecord.phoneNumber}
            secondary="Phone Number"
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={selectedRecord.country} secondary="Country" />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={selectedRecord.shopId && selectedRecord.shopId}
            secondary="ShopId"
          />
        </ListItem>
      </List>
    </Paper>
  );
}

export default memo(UserDetails);
