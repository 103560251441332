import PersonIcon from "@mui/icons-material/Person";
import {
  Alert,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import PopupDialog from "../components/PopupDialog";
import { API_QUOTES, BASEURL } from "../context/ApiEndPoints";
import useFetchList from "../hooks/useFetchList";
import { currencyFormat, dateStamp, getRandomColor } from "../scripts/Scripts";

function QuoteCard({ quoteId }) {
  const [popupDialog, setPopupDialog] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const { records, isLoading, fetchError } = useFetchList(
    `${API_QUOTES}/${quoteId}`
  );
  const quoteInfo = records && JSON.parse(records.quoteInfo);

  const alertStatus = (status) => {
    switch (status) {
      case "Request":
      case "Todo":
        return "warning";

      case "Sent":
      case "Waiting":
        return "info";

      case "Approved":
        return "success";

      case "Lost":
        return "error";

      default:
        return "info";
    }
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 3 }} />
        ))}
      {records && (
        <Paper sx={{ p: 2 }}>
          <List
            subheader={
              <ListSubheader
                component="div"
                sx={{ color: "text.primary", fontSize: "large" }}
              >
                Quote: {records.title}
              </ListSubheader>
            }
          >
            {records.title && <Divider variant="middle" component="li" light />}
            <ListItem
              secondaryAction={
                <ListItemText
                  sx={{ textAlign: "center" }}
                  primary={records.industry}
                />
              }
            >
              <ListItemText
                primary={`ID: ${records.quoteId}`}
                secondary={dateStamp(
                  records.modifiedDate
                    ? records.modifiedDate
                    : records.createdDate
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Alert
                    severity={
                      !records.jobCreatedDate
                        ? alertStatus(records.status)
                        : "success"
                    }
                    action={
                      <Chip
                        icon={<PersonIcon />}
                        label={records.customer.email}
                        color={
                          !records.jobCreatedDate
                            ? alertStatus(records.status)
                            : "success"
                        }
                        variant="outlined"
                        sx={{ borderWidth: 0 }}
                      />
                    }
                  >
                    {!records.jobCreatedDate ? records.status : "Approved"}
                  </Alert>
                }
              />
            </ListItem>
            <ListItem>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Service</TableCell>
                      <TableCell align="right">Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {records.quoteItems &&
                      records.quoteItems.map((item, i) =>
                        item.isGroup ? (
                          <TableRow
                            key={i}
                            sx={{ backgroundColor: "background.default" }}
                          >
                            <TableCell variant="head">
                              {item.serviceName}
                            </TableCell>
                            <TableCell variant="head" align="right">
                              {currencyFormat(item.price, records.currency)}
                            </TableCell>
                          </TableRow>
                        ) : (
                          !item.serviceValue?.startsWith("https:") && (
                            <TableRow key={i}>
                              <TableCell colSpan={2} sx={{ px: 4 }}>
                                <Typography sx={{ whiteSpace: "pre-line" }}>
                                  {item.serviceValue}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {item.serviceName}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                        )
                      )}

                    <TableRow>
                      <TableCell align="right" colSpan={2} variant="head">
                        {records.isTotal &&
                          `Total: ${currencyFormat(
                            records.totalPrice,
                            records.currency
                          )}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </ListItem>
            {records.notes && (
              <ListItem>
                <ListItemText
                  primary={records.notes}
                  secondary="Extra Info : (notes)"
                  sx={{ whiteSpace: "pre-line" }}
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText primary={records.user} secondary="Shop" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={records.createdDate}
                secondary="Created On Date"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={records.modifiedDate && records.modifiedDate}
                secondary="Modified On Date"
              />
            </ListItem>
            {Object.keys(quoteInfo).length > 0 &&
              quoteInfo.constructor === Object && (
                <>
                  <Divider component="li" variant="middle" />
                  {Object.keys(quoteInfo).map((key) => (
                    <ListItem key={key}>
                      <ListItemText
                        sx={{ whiteSpace: "pre-line" }}
                        primary={quoteInfo[key]}
                        secondary={key}
                      />
                    </ListItem>
                  ))}
                </>
              )}
            <Divider component="li" variant="middle" />
            <ListItem>
              <Grid container spacing={1}>
                {records.signature && (
                  <Grid item>
                    <Card variant="outlined">
                      <CardMedia
                        component="img"
                        height="168"
                        image={records.signature}
                        alt="signature"
                        sx={{ p: 1, objectFit: "contain" }}
                      />
                      <CardContent sx={{ p: 1 }}>
                        <Typography
                          variant="caption"
                          component="p"
                          textAlign="center"
                          noWrap
                        >
                          Signature
                        </Typography>
                        <Divider light />
                      </CardContent>
                      <Divider light />
                    </Card>
                  </Grid>
                )}
                {records.quoteItems &&
                  records.quoteItems.map(
                    (item, i) =>
                      item.serviceValue?.startsWith("https:") && (
                        <Grid item key={i}>
                          <Card>
                            <CardActionArea
                              onClick={() => {
                                setPopupContent(item.serviceValue);
                                setPopupDialog(true);
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="168"
                                image={item.serviceValue}
                                alt={item.serviceName}
                                loading="lazy"
                              />
                              <CardContent sx={{ p: 1 }}>
                                <Typography
                                  variant="caption"
                                  component="p"
                                  sx={{ textTransform: "capitalize" }}
                                  textAlign="center"
                                  noWrap
                                >
                                  {item.serviceName}
                                </Typography>
                                <Divider light />
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      )
                  )}
                {records.quoteImages &&
                  records.quoteImages.map((img, i) => (
                    <Grid item key={i}>
                      <Card>
                        <CardActionArea
                          onClick={() => {
                            setPopupContent(`${BASEURL}/Img/${img.imageName}`);
                            setPopupDialog(true);
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="168"
                            image={`${BASEURL}/Img/${img.imageName}`}
                            alt={img.imageName}
                            loading="lazy"
                          />
                          <CardContent
                            sx={{
                              p: 1,
                              backgroundColor: getRandomColor(
                                img.imageCategory
                              ),
                            }}
                          >
                            <Typography
                              variant="caption"
                              component="p"
                              sx={{ textTransform: "capitalize" }}
                              textAlign="center"
                              noWrap
                            >
                              {img.imageCategory}
                            </Typography>
                            <Divider light />
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </ListItem>
          </List>
        </Paper>
      )}
      <PopupDialog popupDialog={popupDialog} setPopupDialog={setPopupDialog}>
        <img src={popupContent} alt={popupContent} />
      </PopupDialog>
    </>
  );
}

export default memo(QuoteCard);
