import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import useAppContext from "../../hooks/useAppContext";

function VinylDetails({ selectedRecord, openInDrawer }) {
  const { user } = useAppContext();

  return (
    <Paper>
      <List>
        <ListItem
          secondaryAction={
            user?.roles.includes("Admin") && (
              <Tooltip title="Edit" placement="bottom" arrow>
                <IconButton
                  color="primary"
                  aria-label="edit"
                  onClick={() => openInDrawer("edit", selectedRecord)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )
          }
        >
          <ListItemAvatar>
            <Avatar>
              <ArticleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Details" secondary="Vinyl Brand" />
        </ListItem>
      </List>
      <Divider />
      <List sx={{ px: 3 }}>
        <ListItem>
          <ListItemText primary={`ID: ${selectedRecord.colorVinylId}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={selectedRecord.name} secondary="Name" />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={selectedRecord.createdDate}
            secondary="Created On"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={selectedRecord.modifiedDate}
            secondary="Modified On"
          />
        </ListItem>
      </List>
    </Paper>
  );
}

export default memo(VinylDetails);
