import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FilesUploader from "../../components/FilesUploader";
import ServiceCard from "../../components/ServiceCard";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import RadioGroupControl from "../../components/controls/RadioGroupControl";
import SelectControl from "../../components/controls/SelectControl";
import { API_QUOTES } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";

const objRecord = {
  industry: "Architectural",
};

function EditArchitectural() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAppContext();
  const [values, setValues] = useState({ ...objRecord });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [service, setService] = useState("Service");
  const [serviceName, setServiceName] = useState("_Service");
  const [fetchError, setFetchError] = useState(false);

  const validate = (input = values) => {
    let temp = { ...errors };

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e, switchName = null) => {
    const { name, value } = e.target;
    values[name] = value;
    if (switchName) values[switchName] = true;

    setValues({ ...values });
    validate({ [name]: value });
  };

  const handleServiceChange = (e) => {
    const { value } = e.target;
    const a = value.replace(/\b\w/g, (c) => c.toUpperCase());
    setServiceName("_" + a);
    const b = a.replace(/\s/g, "");
    setService(b);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      const data = {};
      const quoteServices = {};
      const quoteItems = {};
      for (const [key, val] of Object.entries(values)) {
        if (key.startsWith("_") && val) {
          const k = key.slice(1);
          quoteServices[k] = val;
          continue;
        }

        if (/^[A-Z]/.test(key[0]) && val) {
          quoteItems[key] = typeof val === "boolean" ? "Yes" : val;
          continue;
        }

        if (/^[a-z]/.test(key[0])) {
          data[key] = val;
          continue;
        }
      }

      for (const [key] of Object.entries(data)) {
        if (key.endsWith("_") || key.startsWith("_")) {
          delete data[key];
        }
      }

      let record = { ...data };
      record.quoteServices = JSON.stringify(quoteServices);
      record.quoteItems = JSON.stringify(quoteItems);

      // Create formData
      const formData = new FormData();
      // Add quote values to formData
      for (const [key, val] of Object.entries(record)) {
        formData.append(key, val);
      }

      // Add buildingWrapFile values to formData
      if (values.buildingWrapFile) {
        for (const val of Object.values(values.buildingWrapFile)) {
          formData.append("buildingWrapFile", val);
        }
      }

      // Add canvasFramesFile values to formData
      if (values.canvasFramesFile) {
        for (const val of Object.values(values.canvasFramesFile)) {
          formData.append("canvasFramesFile", val);
        }
      }

      // Add furnitureFile values to formData
      if (values.furnitureFile) {
        for (const val of Object.values(values.furnitureFile)) {
          formData.append("furnitureFile", val);
        }
      }

      // Add wallWrapFile values to formData
      if (values.wallWrapFile) {
        for (const val of Object.values(values.wallWrapFile)) {
          formData.append("wallWrapFile", val);
        }
      }

      // Add windowFilmFile values to formData
      if (values.windowFilmFile) {
        for (const val of Object.values(values.windowFilmFile)) {
          formData.append("windowFilmFile", val);
        }
      }

      // POST request using fetch
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: "Bearer " + user.token },
        body: formData,
      };

      try {
        const response = await fetch(
          `${API_QUOTES}/QuoteItems/${params.quoteId}`,
          requestOptions
        );
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (json && json.message) || response.status;
          throw Error(error);
        }

        setValues({ ...objRecord });
        setErrors({});
        navigate("/job-details/" + json.quoteId, { replace: true });
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Slide direction="right" in={true}>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "BuildingWrapOption",
                    "buildingWrapFile",
                    "BuildingWrapQuantity",
                    "BuildingWrapHeight",
                    "BuildingWrapWidth",
                    "BuildingWrapUnits",
                    "BuildingWrapMoreInfo",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_BuildingWrap"
                  icon="wi-building-wrap"
                  title="Building Wrap"
                >
                  <RadioGroupControl
                    aria-label="building wrap"
                    name="BuildingWrapOption"
                    onChange={(e) => handleInputChange(e, "_BuildingWrap")}
                    options={[
                      {
                        label: "Design Request",
                        value: "Design Request",
                      },
                      {
                        label: "I Have a Design",
                        value: "I Have a Design",
                      },
                    ]}
                  />
                  <FormControl margin="normal" fullWidth>
                    <InputLabel>Upload Design / Example</InputLabel>
                    <FilesUploader
                      inputName="buildingWrapFile"
                      accept="image/*"
                      values={values}
                      setValues={setValues}
                    />
                  </FormControl>
                  <InputControl
                    type="number"
                    label="Quantity"
                    name="BuildingWrapQuantity"
                    onChange={(e) => handleInputChange(e, "_BuildingWrap")}
                  />
                  <Stack direction="row">
                    <InputControl
                      type="number"
                      label="Dimension Height"
                      name="BuildingWrapHeight"
                      onChange={(e) => handleInputChange(e, "_BuildingWrap")}
                    />
                    <InputControl
                      type="number"
                      label="Dimension Width"
                      name="BuildingWrapWidth"
                      onChange={(e) => {
                        handleInputChange(e, "_BuildingWrap");
                        if (!values.BuildingWrapWidth)
                          setValues({ ...values, BuildingWrapUnits: "" });
                      }}
                    />
                    {values.BuildingWrapWidth && (
                      <SelectControl
                        label="Units"
                        name="BuildingWrapUnits"
                        value={
                          values.BuildingWrapUnits
                            ? values.BuildingWrapUnits
                            : ""
                        }
                        onChange={(e) => handleInputChange(e, "_BuildingWrap")}
                        options={[
                          { label: "cm", value: "cm" },
                          { label: "Inch", value: "Inch" },
                        ]}
                      />
                    )}
                  </Stack>
                  <InputControl
                    label="Tell Me More"
                    name="BuildingWrapMoreInfo"
                    multiline
                    rows={3}
                    onChange={(e) => handleInputChange(e, "_BuildingWrap")}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "CanvasFramesOption",
                    "canvasFramesFile",
                    "CanvasFramesQuantity",
                    "CanvasFramesHeight",
                    "CanvasFramesWidth",
                    "CanvasFramesUnits",
                    "CanvasFramesMoreInfo",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_CanvasFrames"
                  icon="wi-canvas-frames"
                  title="Canvas &amp; Frames"
                >
                  <RadioGroupControl
                    aria-label="canvas frames"
                    name="CanvasFramesOption"
                    onChange={(e) => handleInputChange(e, "_CanvasFrames")}
                    options={[
                      {
                        label: "Design Request",
                        value: "Design Request",
                      },
                      {
                        label: "I Have a Design",
                        value: "I Have a Design",
                      },
                    ]}
                  />
                  <FormControl margin="normal" fullWidth>
                    <InputLabel>Upload Design / Example</InputLabel>
                    <FilesUploader
                      inputName="canvasFramesFile"
                      accept="image/*"
                      values={values}
                      setValues={setValues}
                    />
                  </FormControl>
                  <InputControl
                    type="number"
                    label="Quantity"
                    name="CanvasFramesQuantity"
                    onChange={(e) => handleInputChange(e, "_CanvasFrames")}
                  />
                  <Stack direction="row">
                    <InputControl
                      type="number"
                      label="Dimension Height"
                      name="CanvasFramesHeight"
                      onChange={(e) => handleInputChange(e, "_CanvasFrames")}
                    />
                    <InputControl
                      type="number"
                      label="Dimension Width"
                      name="CanvasFramesWidth"
                      onChange={(e) => {
                        handleInputChange(e, "_CanvasFrames");
                        if (!values.CanvasFramesWidth)
                          setValues({ ...values, CanvasFramesUnits: "" });
                      }}
                    />
                    {values.CanvasFramesWidth && (
                      <SelectControl
                        label="Units"
                        name="CanvasFramesUnits"
                        value={
                          values.CanvasFramesUnits
                            ? values.CanvasFramesUnits
                            : ""
                        }
                        onChange={(e) => handleInputChange(e, "_CanvasFrames")}
                        options={[
                          { label: "cm", value: "cm" },
                          { label: "Inch", value: "Inch" },
                        ]}
                      />
                    )}
                  </Stack>
                  <InputControl
                    label="Tell Me More"
                    name="CanvasFramesMoreInfo"
                    multiline
                    rows={3}
                    onChange={(e) => handleInputChange(e, "_CanvasFrames")}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "FurnitureOption",
                    "furnitureFile",
                    "FurnitureQuantity",
                    "FurnitureHeight",
                    "FurnitureWidth",
                    "FurnitureUnits",
                    "FurnitureMoreInfo",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_Furniture"
                  icon="wi-furniture"
                  title="Furniture"
                >
                  <RadioGroupControl
                    aria-label="furniture"
                    name="FurnitureOption"
                    onChange={(e) => handleInputChange(e, "_Furniture")}
                    options={[
                      {
                        label: "Design Request",
                        value: "Design Request",
                      },
                      {
                        label: "I Have a Design",
                        value: "I Have a Design",
                      },
                    ]}
                  />
                  <FormControl margin="normal" fullWidth>
                    <InputLabel>Upload Design / Example</InputLabel>
                    <FilesUploader
                      inputName="furnitureFile"
                      accept="image/*"
                      values={values}
                      setValues={setValues}
                    />
                  </FormControl>
                  <InputControl
                    type="number"
                    label="Quantity"
                    name="FurnitureQuantity"
                    onChange={(e) => handleInputChange(e, "_Furniture")}
                  />
                  <Stack direction="row">
                    <InputControl
                      type="number"
                      label="Dimension Height"
                      name="FurnitureHeight"
                      onChange={(e) => handleInputChange(e, "_Furniture")}
                    />
                    <InputControl
                      type="number"
                      label="Dimension Width"
                      name="FurnitureWidth"
                      onChange={(e) => {
                        handleInputChange(e, "_Furniture");
                        if (!values.FurnitureWidth)
                          setValues({ ...values, FurnitureUnits: "" });
                      }}
                    />
                    {values.FurnitureWidth && (
                      <SelectControl
                        label="Units"
                        name="FurnitureUnits"
                        value={
                          values.FurnitureUnits ? values.FurnitureUnits : ""
                        }
                        onChange={(e) => handleInputChange(e, "_Furniture")}
                        options={[
                          { label: "cm", value: "cm" },
                          { label: "Inch", value: "Inch" },
                        ]}
                      />
                    )}
                  </Stack>
                  <InputControl
                    label="Tell Me More"
                    name="FurnitureMoreInfo"
                    multiline
                    rows={3}
                    onChange={(e) => handleInputChange(e, "_Furniture")}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "WallWrapOption",
                    "wallWrapFile",
                    "WallWrapQuantity",
                    "WallWrapHeight",
                    "WallWrapWidth",
                    "WallWrapUnits",
                    "WallWrapMoreInfo",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_WallWrap"
                  icon="wi-wall-wrap"
                  title="Wall Wrap"
                >
                  <RadioGroupControl
                    aria-label="wall wrap"
                    name="WallWrapOption"
                    onChange={(e) => handleInputChange(e, "_WallWrap")}
                    options={[
                      {
                        label: "Design Request",
                        value: "Design Request",
                      },
                      {
                        label: "I Have a Design",
                        value: "I Have a Design",
                      },
                    ]}
                  />
                  <FormControl margin="normal" fullWidth>
                    <InputLabel>Upload Design / Example</InputLabel>
                    <FilesUploader
                      inputName="wallWrapFile"
                      accept="image/*"
                      values={values}
                      setValues={setValues}
                    />
                  </FormControl>
                  <InputControl
                    type="number"
                    label="Quantity"
                    name="WallWrapQuantity"
                    onChange={(e) => handleInputChange(e, "_WallWrap")}
                  />
                  <Stack direction="row">
                    <InputControl
                      type="number"
                      label="Dimension Height"
                      name="WallWrapHeight"
                      onChange={(e) => handleInputChange(e, "_WallWrap")}
                    />
                    <InputControl
                      type="number"
                      label="Dimension Width"
                      name="WallWrapWidth"
                      onChange={(e) => {
                        handleInputChange(e, "_WallWrap");
                        if (!values.WallWrapWidth)
                          setValues({ ...values, WallWrapUnits: "" });
                      }}
                    />
                    {values.WallWrapWidth && (
                      <SelectControl
                        label="Units"
                        name="WallWrapUnits"
                        value={values.WallWrapUnits ? values.WallWrapUnits : ""}
                        onChange={(e) => handleInputChange(e, "_WallWrap")}
                        options={[
                          { label: "cm", value: "cm" },
                          { label: "Inch", value: "Inch" },
                        ]}
                      />
                    )}
                  </Stack>
                  <InputControl
                    label="Tell Me More"
                    name="WallWrapMoreInfo"
                    multiline
                    rows={3}
                    onChange={(e) => handleInputChange(e, "_WallWrap")}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[
                    "WindowFilmOption",
                    "windowFilmFile",
                    "WindowFilmQuantity",
                    "WindowFilmHeight",
                    "WindowFilmWidth",
                    "WindowFilmUnits",
                    "WindowFilmMoreInfo",
                  ]}
                  values={values}
                  setValues={setValues}
                  switchName="_WindowFilm"
                  icon="wi-window-film"
                  title="Window Film"
                >
                  <RadioGroupControl
                    aria-label="window film"
                    name="WindowFilmOption"
                    onChange={(e) => handleInputChange(e, "_WindowFilm")}
                    options={[
                      {
                        label: "Design Request",
                        value: "Design Request",
                      },
                      {
                        label: "I Have a Design",
                        value: "I Have a Design",
                      },
                    ]}
                  />
                  <FormControl margin="normal" fullWidth>
                    <InputLabel>Upload Design / Example</InputLabel>
                    <FilesUploader
                      inputName="windowFilmFile"
                      accept="image/*"
                      values={values}
                      setValues={setValues}
                    />
                  </FormControl>
                  <InputControl
                    type="number"
                    label="Quantity"
                    name="WindowFilmQuantity"
                    onChange={(e) => handleInputChange(e, "_WindowFilm")}
                  />
                  <Stack direction="row">
                    <InputControl
                      type="number"
                      label="Dimension Height"
                      name="WindowFilmHeight"
                      onChange={(e) => handleInputChange(e, "_WindowFilm")}
                    />
                    <InputControl
                      type="number"
                      label="Dimension Width"
                      name="WindowFilmWidth"
                      onChange={(e) => {
                        handleInputChange(e, "_WindowFilm");
                        if (!values.WindowFilmWidth)
                          setValues({ ...values, WindowFilmUnits: "" });
                      }}
                    />
                    {values.WindowFilmWidth && (
                      <SelectControl
                        label="Units"
                        name="WindowFilmUnits"
                        value={
                          values.WindowFilmUnits ? values.WindowFilmUnits : ""
                        }
                        onChange={(e) => handleInputChange(e, "_WindowFilm")}
                        options={[
                          { label: "cm", value: "cm" },
                          { label: "Inch", value: "Inch" },
                        ]}
                      />
                    )}
                  </Stack>
                  <InputControl
                    label="Tell Me More"
                    name="WindowFilmMoreInfo"
                    multiline
                    rows={3}
                    onChange={(e) => handleInputChange(e, "_WindowFilm")}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["AddressDirections"]}
                  values={values}
                  setValues={setValues}
                  switchName="_OnSiteVisitRequest"
                  icon="wi-other"
                  title="On-Site Visit Request"
                >
                  <InputControl
                    label="Address / Directions"
                    name="OnSiteAddressDirections"
                    multiline
                    rows={3}
                    onChange={(e) =>
                      handleInputChange(e, "_OnSiteVisitRequest")
                    }
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={["OtherDetails"]}
                  values={values}
                  setValues={setValues}
                  switchName="_Other"
                  icon="wi-other"
                  title="Other"
                >
                  <InputControl
                    label="Please Specify"
                    name="OtherDetails"
                    multiline
                    rows={3}
                    onChange={(e) => handleInputChange(e, "_Other")}
                  />
                </ServiceCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceCard
                  services={[service + "Title", service + "Details"]}
                  values={values}
                  setValues={setValues}
                  switchName={serviceName.replace(/\s/g, "")}
                  icon="wi-other"
                  title={serviceName.substring(1)}
                >
                  <InputControl
                    label="Title"
                    name={service + "Title"}
                    onChange={(e) =>
                      handleServiceChange(e, serviceName.replace(/\s/g, ""))
                    }
                  />
                  <InputControl
                    label="Description"
                    name={service + "Details"}
                    multiline
                    rows={3}
                    onChange={(e) =>
                      handleInputChange(e, serviceName.replace(/\s/g, ""))
                    }
                  />
                </ServiceCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} textAlign="center">
            <Fab
              component={Link}
              to={`/job-details/${params.quoteId}`}
              aria-label="go back"
              sx={{ mb: 3 }}
            >
              <ArrowBackIcon />
            </Fab>
            <Typography color="text.secondary">
              Select services form left and click CONFIRM once done
            </Typography>
            <Divider variant="middle" sx={{ my: 2 }}>
              {fetchError}
            </Divider>
            {Object.keys(values).some(
              (k) => /^[A-Z]/.test(k[0]) && values[k]
            ) ? (
              <ButtonControl type="submit" text="Submit" isSaving={isSaving} />
            ) : (
              <ButtonControl text="Submit" disabled />
            )}
          </Grid>
        </Grid>
      </Slide>
    </form>
  );
}

export default memo(EditArchitectural);
