import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  IconButton,
  Link,
  Paper,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import ButtonControl from "../../components/controls/ButtonControl";
import FilesUploader from "../../components/FilesUploader";
import PopupDialog from "../../components/PopupDialog";
import { API_DESIGNS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import { dateStamp } from "../../scripts/Scripts";
import DesignComments from "./DesignComments";

function DesignDetails(props) {
  const { user } = useAppContext();
  const { setNotify, setConfirmDialog } = useComponentContext();

  const [selectedRecord, setSelectedRecord] = useState();
  const [popupDialog, setPopupDialog] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [values, setValues] = useState({ quoteId: props.quoteId });
  const [isSaving, setIsSaving] = useState(false);
  const [toggleUpload, setToggleUpload] = useState(false);

  const { records, setRecords, isLoading, fetchError } = useFetchList(
    `${API_DESIGNS}/Job/${props.quoteId}`
  );

  useEffect(() => {
    setSelectedRecord(records && { ...records[0] });

    return () => {};
  }, [records]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    //Create formData
    const formData = new FormData();
    for (const [k, v] of Object.entries(values)) {
      formData.append(k, v);
    }

    // Add stripingFiles values to formData
    if (values.DesignFiles) {
      for (const val of Object.values(values.DesignFiles)) {
        formData.append("DesignFiles", val);
      }
    }

    // POST request using fetch
    const requestOptions = {
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
      body: formData,
    };
    const results = await ApiFetch(
      API_DESIGNS,
      requestOptions,
      setNotify,
      "Job File Added Successfully!"
    );

    if (results) {
      setRecords([...results]);
      setSelectedRecord({ ...results[0] });
      setValues({ ...values, DesignFiles: null });
    }
    setIsSaving(false);
  };

  const handleDelete = async (designId) => {
    setConfirmDialog({ isOpen: false });
    setIsSaving(true);

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_DESIGNS}/${designId}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      setRecords((records) => records.filter((e) => e.designId !== designId));
      const isDeleted = records.find(
        (e) => e.designId === selectedRecord?.designId
      );
      !isDeleted && setSelectedRecord(records.find((e) => e));
    }
    setIsSaving(false);
  };

  // POST Request using fetch
  const handleSendEmail = async () => {
    setIsSaving(true);
    setConfirmDialog({ isOpen: false });

    // POST Request using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
    };

    await ApiFetch(
      `${API_DESIGNS}/Send/${props.quoteId}`,
      requestOptions,
      setNotify,
      "Design Email Sent Successfully!"
    );

    setIsSaving(false);
  };

  const handleSelect = (designId) => {
    const index = records.findIndex((e) => e.designId === designId);
    setSelectedRecord({ ...records[index] });
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 3 }} />
        ))}
      <Zoom in={true} style={{ transitionDelay: "100ms" }}>
        <Box>
          {records && (
            <Paper square sx={{ mb: 0.1 }}>
              <Grid container>
                <Grid item xs={12} p={2} sx={{ textAlign: "right" }}>
                  <Tooltip
                    title={toggleUpload ? "Cancel" : "Add New"}
                    placement="top"
                    arrow
                  >
                    <Fab
                      color={toggleUpload ? "error" : "primary"}
                      aria-label="add"
                      onClick={() => setToggleUpload(!toggleUpload)}
                    >
                      {toggleUpload ? <CloseIcon /> : <AddIcon />}
                    </Fab>
                  </Tooltip>
                </Grid>
                {toggleUpload && (
                  <Grid item xs={12} p={1} pt={0}>
                    <form onSubmit={handleSubmit} align="center">
                      <FilesUploader
                        inputName="DesignFiles"
                        accept="image/*,application/pdf"
                        values={values}
                        setValues={setValues}
                      />
                      <ButtonControl
                        type="submit"
                        text="Submit"
                        isSaving={isSaving}
                        disabled={
                          values["DesignFiles"] &&
                          Object.keys(values["DesignFiles"]).length > 0
                            ? false
                            : true
                        }
                        sx={{ mt: 1 }}
                      />
                    </form>
                  </Grid>
                )}
              </Grid>
            </Paper>
          )}
          <Grid container spacing={1}>
            {selectedRecord && Object.keys(selectedRecord).length > 0 && (
              <>
                <Grid item xs={12} md={3}>
                  <Grid container spacing={1}>
                    {records.map((it, i) => (
                      <Grid key={i} item xs={4} md={12}>
                        <Card
                          variant={
                            it.designId === selectedRecord.designId
                              ? "elevation"
                              : "outlined"
                          }
                          sx={{
                            height: { xs: "100%", md: "auto" },
                            bgcolor:
                              it.designId === selectedRecord.designId
                                ? "paper"
                                : "divider",
                            filter:
                              it.designId === selectedRecord.designId
                                ? "saturate(100%)"
                                : "saturate(0%)",
                          }}
                        >
                          <CardHeader
                            action={
                              <Box sx={{ textAlign: "right" }}>
                                {it.isApproved ? (
                                  <>
                                    <Chip
                                      label="Approved"
                                      color="success"
                                      icon={<TaskAltIcon />}
                                      sx={{ mb: 0.5, borderRadius: 1 }}
                                    />
                                    <br />
                                  </>
                                ) : (
                                  <>
                                    <Tooltip
                                      title="Delete"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        color="error"
                                        onClick={() =>
                                          setConfirmDialog({
                                            isOpen: true,
                                            title:
                                              "Are you sure to delete this record?",
                                            subTitle:
                                              "You can't undo this operation",
                                            onConfirm: () => {
                                              handleDelete(it.designId);
                                            },
                                          })
                                        }
                                        aria-label="delete"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <br />
                                  </>
                                )}
                                <ButtonControl
                                  text={it.mime}
                                  variant="text"
                                  size="small"
                                  startIcon={<DownloadRoundedIcon />}
                                  sx={{ textTransform: "lowercase" }}
                                />
                              </Box>
                            }
                            title={`DESIGN ${it.designId}`}
                            titleTypographyProps={{
                              fontSize: "large",
                            }}
                            subheader={dateStamp(it.createdDate)}
                            subheaderTypographyProps={{
                              fontSize: "small",
                            }}
                          />
                          <Divider />
                          {it.mime === "application/pdf" ? (
                            <>
                              <CardContent align="center" sx={{ p: 0 }}>
                                <embed
                                  src={it.fileName}
                                  type="application/pdf"
                                  width="100%"
                                />
                              </CardContent>
                              <CardActionArea
                                onClick={() => handleSelect(it.designId)}
                              >
                                <Typography
                                  variant="subtitle2"
                                  align="center"
                                  p={2}
                                >
                                  CLICK TO VIEW
                                </Typography>
                              </CardActionArea>
                            </>
                          ) : (
                            <CardActionArea
                              onClick={() => handleSelect(it.designId)}
                            >
                              <CardMedia
                                component="img"
                                height="100%"
                                image={it.fileName}
                                alt={it.fileName}
                                loading="lazy"
                              />
                            </CardActionArea>
                          )}
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Card>
                    <CardHeader
                      action={
                        <Box sx={{ textAlign: "right" }}>
                          {selectedRecord.isApproved ? (
                            <>
                              <Chip
                                label="Approved"
                                color="success"
                                icon={<TaskAltIcon />}
                                sx={{ mb: 0.5, borderRadius: 1 }}
                              />
                              <br />
                            </>
                          ) : (
                            <>
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton
                                  color="error"
                                  onClick={() =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure to delete this record?",
                                      subTitle: "You can't undo this operation",
                                      onConfirm: () => {
                                        handleDelete(selectedRecord.designId);
                                      },
                                    })
                                  }
                                  aria-label="delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              <br />
                            </>
                          )}
                          <ButtonControl
                            component={Link}
                            href={selectedRecord.fileName}
                            text={selectedRecord.mime}
                            variant="text"
                            size="medium"
                            startIcon={<DownloadRoundedIcon />}
                            download={selectedRecord.designId}
                            sx={{ textTransform: "lowercase" }}
                          />
                        </Box>
                      }
                      title={`DESIGN ${selectedRecord.designId}`}
                      subheader={dateStamp(selectedRecord.createdDate)}
                    />
                    <Divider />
                    {selectedRecord.mime === "application/pdf" ? (
                      <CardContent align="center" sx={{ p: 0 }}>
                        <embed
                          src={selectedRecord.fileName}
                          type="application/pdf"
                          width="100%"
                          height="550px"
                        />
                      </CardContent>
                    ) : (
                      <CardActionArea
                        onClick={() => {
                          setPopupContent(selectedRecord.fileName);
                          setPopupDialog(true);
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="100%"
                          image={selectedRecord.fileName}
                          alt={selectedRecord.fileName}
                          loading="lazy"
                        />
                      </CardActionArea>
                    )}
                    <CardContent>
                      <DesignComments
                        records={records}
                        setRecords={setRecords}
                        selectedRecord={selectedRecord}
                      />
                      <Box sx={{ pt: 1, textAlign: "right" }}>
                        <ButtonControl
                          text="Send Email"
                          variant="outlined"
                          size="large"
                          isSaving={isSaving}
                          onClick={() =>
                            setConfirmDialog({
                              isOpen: true,
                              title:
                                "Are you sure? \n  Send this email to the customer",
                              subTitle: "You can't undo this operation",
                              onConfirm: handleSendEmail,
                            })
                          }
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Zoom>
      <PopupDialog
        popupDialog={popupDialog}
        setPopupDialog={setPopupDialog}
        maxWidth={false}
      >
        <img src={popupContent} alt={popupContent} />
      </PopupDialog>
    </>
  );
}

export default memo(DesignDetails);
