import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { memo } from "react";

function SelectControl(props) {
  const {
    variant,
    label,
    name,
    value,
    onChange,
    margin,
    fullWidth = true,
    options,
    none,
    error = null,
    valueIndex,
    textIndex,
    ...other
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP },
    },
  };

  return (
    <FormControl
      variant={variant || "outlined"}
      margin={margin || "normal"}
      fullWidth={fullWidth}
      {...(error && { error: true })}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...other}
        MenuProps={MenuProps}
      >
        {none && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={Object.values(option)[0]}
            value={Object.values(option)[valueIndex ?? 0]}
          >
            {Object.values(option)[textIndex ?? 1]}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default memo(SelectControl);
