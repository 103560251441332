import SearchIcon from "@mui/icons-material/Search";
import ThreePIcon from "@mui/icons-material/ThreeP";
import {
  Avatar,
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { memo, Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FixedSizeList as FixedList } from "react-window";
import ApiFetch from "../../components/ApiFetch";
import InputControl from "../../components/controls/InputControl";
import { API_CUSTOMERS, API_MEDIAS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import { stringAvatar } from "../../scripts/Scripts";

const MsgCustomerDetails = React.lazy(() => import("./MsgCustomerDetails"));
const MsgCustomerEdit = React.lazy(() => import("./MsgCustomerEdit"));
const MsgDetails = React.lazy(() => import("./MsgDetails"));

function MsgCustomerList(props) {
  const { user } = useAppContext();
  const { setNotify, setConfirmDialog } = useComponentContext();
  const navigate = useNavigate();
  const [selectedRecord, setSelectedRecord] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [isSaving, setIsSaving] = useState(false);
  const [isToggleEdit, setIsToggleEdit] = useState(false);

  const { records, isLoading, fetchError } = useFetchList(
    `${API_CUSTOMERS}/List`
  );

  const isGUID = (str) => {
    // Regex to check valid GUID
    let regex = new RegExp(
      /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
    );

    // if str is empty return false
    if (str == null) return false;

    // Return true if the str matched the ReGex
    if (regex.test(str) === true) return true;
    else return false;
  };

  useEffect(() => {
    if (isGUID(props.customerId) && records) {
      const fetchRequest = async () => {
        setSelectedRecord();

        // GET request using fetch
        const requestOptions = {
          headers: { Authorization: "Bearer " + user.token },
        };

        const results = await ApiFetch(
          `${API_CUSTOMERS}/${props.customerId}`,
          requestOptions,
          setNotify
        );

        const index = records.findIndex(
          (el) => el.customerId === props.customerId
        );

        setSelectedIndex(index);
        setSelectedRecord(results);
      };
      (async () => await fetchRequest())();
    }

    return () => {};
    // eslint-disable-next-line
  }, [props.customerId, records]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((item) =>
            ["email", "phoneNumber"].some(
              (newitem) =>
                item[newitem]
                  .toString()
                  .toLowerCase()
                  .indexOf(target.value.toLowerCase()) > -1
            )
          );
        }
      },
    });
  };

  const handleListClick = (id, index) => {
    setSelectedIndex(index);
    navigate(`/msgs/${id}`);
  };

  const handleEdit = async (record) => {
    setIsSaving(true);
    //Create formData with file
    const formData = new FormData();
    for (const [k, v] of Object.entries(record)) {
      formData.append(k, v ?? "");
    }

    // Add customerFiles values to formData
    if (record.customerFiles) {
      for (const val of Object.values(record.customerFiles)) {
        formData.append("customerFiles", val);
      }
    }

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: { Authorization: "Bearer " + user.token },
      body: formData,
    };
    const results = await ApiFetch(
      `${API_CUSTOMERS}/${record.customerId}`,
      requestOptions,
      setNotify,
      "Customer Updated Successfully!"
    );

    if (results) {
      const index = records.findIndex(
        (el) => el.customerId === record.customerId
      );
      records[index] = results;
      setSelectedRecord(results);
    }
    setIsSaving(false);
  };

  const handleFileDelete = async (e, mediaId) => {
    setConfirmDialog({ isOpen: false });
    setIsSaving(true);
    let current = e.target.closest("div.MuiCard-root");

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_MEDIAS}/${mediaId}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      current.parentNode.removeChild(current);
    }
    setIsSaving(false);
  };

  const Row = ({ data, index, style }) => (
    <ListItem
      sx={{ borderBottom: 1, borderColor: "divider" }}
      disablePadding
      style={style}
    >
      <ListItemButton
        selected={selectedIndex === index}
        onClick={() => handleListClick(data[index].customerId, index)}
      >
        <ListItemAvatar>
          <Avatar>
            <Avatar {...stringAvatar(data[index].firstName)} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={data[index].firstName + " " + data[index].lastName}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  color: "text.primary",
                  display: "inline",
                }}
              >
                {data[index].email}
              </Typography>{" "}
              | {data[index].phoneNumber}
            </React.Fragment>
          }
        />
      </ListItemButton>
    </ListItem>
  );

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <Paper square>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ThreePIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Conversations" />
                </ListItem>
              </List>
              <form noValidate autoComplete="off">
                <InputControl
                  label="Search Customer"
                  name="name"
                  type="search"
                  placeholder="Search Customer By email or Phone Number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                  margin="none"
                />
              </form>
              <FixedList
                height={700}
                itemCount={filterFn.fn(records).length}
                itemSize={70}
                itemData={filterFn.fn(records)}
              >
                {Row}
              </FixedList>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            {selectedRecord && (
              <Suspense fallback={"Loading..."}>
                <MsgDetails selectedCustomer={selectedRecord} />
              </Suspense>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <>
              {selectedRecord && !isToggleEdit && (
                <Suspense fallback={"Loading..."}>
                  <MsgCustomerDetails
                    selectedRecord={selectedRecord}
                    handleFileDelete={handleFileDelete}
                    setIsToggleEdit={setIsToggleEdit}
                  />
                </Suspense>
              )}
              {selectedRecord && isToggleEdit && (
                <Suspense fallback={"Loading..."}>
                  <MsgCustomerEdit
                    selectedRecord={selectedRecord}
                    handleEdit={handleEdit}
                    isSaving={isSaving}
                    setIsToggleEdit={setIsToggleEdit}
                  />
                </Suspense>
              )}
            </>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default memo(MsgCustomerList);
