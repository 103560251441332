import DeleteIcon from "@mui/icons-material/Delete";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
  Alert,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Zoom,
} from "@mui/material";
import { memo, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import ButtonControl from "../../components/controls/ButtonControl";
import FilesUploader from "../../components/FilesUploader";
import { API_INVOICES, API_QUOTES } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";

function InvoiceDetails(props) {
  const { user } = useAppContext();
  const { setNotify, setConfirmDialog } = useComponentContext();

  const [active, setActive] = useState(0);
  const [values, setValues] = useState({ quoteId: props.quoteId });
  const [isSaving, setIsSaving] = useState(false);
  const { records, setRecords, isLoading, fetchError } = useFetchList(
    `${API_INVOICES}/Job/${props.quoteId}`
  );
  const { records: quote, setRecords: setQuote } = useFetchList(
    `${API_QUOTES}/${props.quoteId}`
  );

  const handleToggle = (event, val) => {
    if (val !== null) {
      setActive(val);
    }
    console.log(quote);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    //Create formData
    const formData = new FormData();
    for (const [k, v] of Object.entries(values)) {
      formData.append(k, v);
    }

    // Add files values to formData
    if (values.InvoiceFile) {
      for (const val of Object.values(values.InvoiceFile)) {
        formData.append("InvoiceFile", val);
      }
    }

    // POST request using fetch
    const requestOptions = {
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
      body: formData,
    };
    const results = await ApiFetch(
      API_INVOICES,
      requestOptions,
      setNotify,
      "Invoice File Added Successfully!"
    );

    if (results) {
      setRecords([results, ...records]);
      setValues({ ...values, InvoiceFile: null });
      setActive(0);
    }
    setIsSaving(false);
  };

  const handelClose = async () => {
    setIsSaving(true);
    setConfirmDialog({ isOpen: false });

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_INVOICES}/${props.quoteId}`,
      requestOptions,
      setNotify,
      "Invoice Updated Successfully!"
    );

    if (results) {
      setQuote(results);
    }
    setIsSaving(false);
  };

  const handleDelete = async () => {
    setConfirmDialog({ isOpen: false });

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_INVOICES}/${records[active].invoiceId}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      setRecords((records) =>
        records.filter((el) => el.invoiceId !== records[active].invoiceId)
      );
      setActive(0);
    }
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 3 }} />
        ))}
      <Zoom in={true} style={{ transitionDelay: "100ms" }}>
        <Paper sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs md={4}>
              {quote && !quote.jobCreatedDate && (
                <Alert severity="warning">Quote not approved!</Alert>
              )}

              {quote && quote.jobCreatedDate && quote.stage < 4 && (
                <form onSubmit={handleSubmit} align="center">
                  <FilesUploader
                    inputName="InvoiceFile"
                    accept="application/pdf"
                    values={values}
                    setValues={setValues}
                    multiple={false}
                  />
                  <ButtonControl
                    type="submit"
                    text="Submit"
                    isSaving={isSaving}
                    disabled={
                      values["InvoiceFile"] &&
                      Object.keys(values["InvoiceFile"]).length > 0
                        ? false
                        : true
                    }
                    sx={{ m: 1 }}
                  />
                </form>
              )}

              {quote && quote.jobCreatedDate && quote.stage === 4 && (
                <Alert severity="success">Job Completed!</Alert>
              )}
            </Grid>
            <Grid item xs md={8}>
              {records && records.length === 0 && (
                <Alert severity="info">No records found!</Alert>
              )}

              {records && records.length > 0 && (
                <>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <ToggleButtonGroup
                      value={active}
                      size="large"
                      exclusive
                      onChange={handleToggle}
                      aria-label="text alignment"
                    >
                      {records.map((rec, i) => (
                        <ToggleButton key={i} value={i} aria-label="details">
                          <ReceiptIcon />
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                    {quote && quote.stage !== 4 && (
                      <>
                        <IconButton
                          color="error"
                          size="large"
                          aria-label="delete"
                          onClick={() =>
                            setConfirmDialog({
                              isOpen: true,
                              title: "Are you sure to delete this invoice?",
                              subTitle: "You can't undo this operation",
                              onConfirm: () => {
                                handleDelete();
                              },
                            })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                        <ButtonControl
                          text="Close Job"
                          color="error"
                          isSaving={isSaving}
                          onClick={() =>
                            setConfirmDialog({
                              isOpen: true,
                              title: "Are you sure to close this job?",
                              subTitle: "You can't undo this operation",
                              onConfirm: () => {
                                handelClose();
                              },
                            })
                          }
                        />
                      </>
                    )}
                  </Stack>
                  <embed
                    src={`${records[active].fileName}#view=Fit`}
                    type="application/pdf"
                    width="100%"
                    height="777px"
                    loading="lazy"
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Zoom>
    </>
  );
}

export default memo(InvoiceDetails);
