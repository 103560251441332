import { Grid, Icon, Typography } from "@mui/material";
import React, { memo } from "react";

function NotFound() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ margin: "auto", maxWidth: 500, height: "75vh" }}
    >
      <Grid item textAlign="center">
        <Typography
          textAlign="center"
          variant="h4"
          component="h4"
          sx={{
            p: 3,
            borderBottom: 1,
            borderColor: "divider",
            color: "text.disabled",
          }}
        >
          <Icon className="wi-icon-2wrap" sx={{ fontSize: 90 }} />
          <br />
          Page Not Found
        </Typography>
      </Grid>
    </Grid>
  );
}

export default memo(NotFound);
