import { CircularProgress, Grid } from "@mui/material";
import React from "react";
import AlertsHub from "../components/AlertsHub";
import GraphChart from "../components/GraphChart";
import { API_QUOTES } from "../context/ApiEndPoints";
import useFetchList from "../hooks/useFetchList";
import BlogsList from "./Blogs/BlogsList";

export default function Dashboard() {
  const { records, isLoading, fetchError } = useFetchList(API_QUOTES);

  const graphIndustry = () => {
    const autoT = records.filter((e) => e.industry.includes("Automotive"));
    const autoA = autoT.reduce(
      (a, { jobCreatedDate }) => (jobCreatedDate ? (a += 1) : a),
      0
    );
    const advT = records.filter((e) => e.industry.includes("Advertising"));
    const advA = advT.reduce(
      (a, { jobCreatedDate }) => (jobCreatedDate ? (a += 1) : a),
      0
    );
    const archT = records.filter((e) => e.industry.includes("Architectural"));
    const archA = archT.reduce(
      (a, { jobCreatedDate }) => (jobCreatedDate ? (a += 1) : a),
      0
    );
    const nautT = records.filter((e) => e.industry.includes("Nautical"));
    const nautA = nautT.reduce(
      (a, { jobCreatedDate }) => (jobCreatedDate ? (a += 1) : a),
      0
    );
    const aeroT = records.filter((e) => e.industry.includes("Aeronautics"));
    const aeroA = aeroT.reduce(
      (a, { jobCreatedDate }) => (jobCreatedDate ? (a += 1) : a),
      0
    );
    const spclT = records.filter((e) => e.industry.includes("Specialties"));
    const spclA = spclT.reduce(
      (a, { jobCreatedDate }) => (jobCreatedDate ? (a += 1) : a),
      0
    );

    const dataBars = {
      count: records.length,
      labels: [
        "Automotive",
        "Advertising",
        "Architectural",
        "Nautical",
        "Aeronautics",
        "Specialties",
      ],
      datasets: [
        {
          label: "Total",
          data: [
            autoT.length,
            advT.length,
            archT.length,
            nautT.length,
            aeroT.length,
            spclT.length,
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(255, 205, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(153, 102, 255, 1)",
          ],
          hoverOffset: 4,
        },
        {
          label: "Approved",
          data: [autoA, advA, archA, nautA, aeroA, spclA],
          backgroundColor: ["rgba(76, 175, 80, 1)"],
          hoverOffset: 4,
        },
      ],
    };

    return dataBars;
  };

  const graphStatus = () => {
    const exRecords = records.filter((e) => e.industry.includes("Automotive"));
    const nwRecords = exRecords.filter(
      (e) =>
        e.user === "WestlakeVillage@2wrap.com" || e.user === "suzie@2wrap.com"
    );
    const request = nwRecords.reduce(
      (a, { status }) => (status === "Request" ? (a += 1) : a),
      0
    );
    const lost = nwRecords.reduce(
      (a, { status }) => (status === "Lost" ? (a += 1) : a),
      0
    );
    const approved = nwRecords.reduce(
      (a, { jobCreatedDate }) => (jobCreatedDate ? (a += 1) : a),
      0
    );
    const sent = nwRecords.length - (request + approved + lost);

    const dataPie = {
      count: nwRecords.length,
      labels: ["Request", "Sent", "Approved", "Lost"],
      datasets: [
        {
          label: "Quotes",
          data: [request, sent, approved, lost],
          backgroundColor: [
            "rgb(255, 152, 0)",
            "rgb(3, 169, 244)",
            "rgb(76, 175, 80)",
            "rgb(239, 83, 80)",
          ],
          hoverOffset: 4,
        },
      ],
    };

    return dataPie;
  };

  const graphFinancial = () => {
    const exRecords = records.filter((e) => e.industry.includes("Automotive"));
    const nwRecords = exRecords.filter(
      (e) =>
        e.user === "WestlakeVillage@2wrap.com" || e.user === "suzie@2wrap.com"
    );
    const sent = nwRecords.reduce(
      (a, e) =>
        e.status !== "Lost" && !e.jobCreatedDate ? e.totalPrice + a : a,
      0
    );
    const approved = nwRecords.reduce(
      (a, e) => (e.jobCreatedDate ? e.totalPrice + a : a),
      0
    );
    const lost = nwRecords.reduce(
      (a, e) => (e.status === "Lost" ? e.totalPrice + a : a),
      0
    );
    const total = (sent + approved + lost).toLocaleString("lookup");

    const dataDoughnut = {
      count: total,
      labels: ["Quoted", "Approved", "Lost"],
      datasets: [
        {
          label: "Quotes",
          data: [`${sent}`, `${approved}`, `${lost}`],
          backgroundColor: [
            "rgb(3, 169, 244)",
            "rgb(76, 175, 80)",
            "rgb(239, 83, 80)",
          ],
          hoverOffset: 4,
        },
      ],
    };

    return dataDoughnut;
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <AlertsHub header={true} />
        </Grid>
        <Grid item xs={12} md={6}>
          <BlogsList />
        </Grid>
        {fetchError ||
          (isLoading && (
            <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
          ))}
        {records && (
          <>
            <Grid item xs={12} md={4}>
              <GraphChart
                id="Chart02"
                data={graphIndustry()}
                type="bar"
                title="INDUSTRIES"
                subtitle="Total"
                legend={false}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GraphChart
                id="Chart03"
                data={graphStatus()}
                type="pie"
                title="QUOTES"
                subtitle="Total"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GraphChart
                id="Chart04"
                data={graphFinancial()}
                type="doughnut"
                title="FINANCIALS"
                subtitle="Total"
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
